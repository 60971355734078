import * as UI from "@goodgym/components";
import { styled as Styled } from "@mui/system";
import styled from "styled-components";

const CardContainer = Styled(UI.Container)`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
height:150px;
margin-top:30px;
margin-bottom: 40px;
`;
const CardImage = styled.img`
height:120px;
  padding-top: 25px;
`;

const Subtitle = Styled(UI.Typography)`
font-weight:normal;
text-align: center;
`;

const Title = Styled(UI.Typography)`
font-weight:bold;
text-align: center;
`;
export type IconCardsProps = { title?: string; image: string; tagline: string };

const IconCards: React.FC<IconCardsProps> = ({ title, image, tagline }) => {
  return (
    <UI.Grid item lg={4} sm={12}>
      <CardContainer>
        <CardImage src={image} />
        {title && <Title variant="h4">{title}</Title>}
        <Subtitle variant="h5">{tagline}</Subtitle>
      </CardContainer>
    </UI.Grid>
  );
};

export default IconCards;
