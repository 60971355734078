import React, { useState } from "react";
import * as UI from "@goodgym/components";
import theme from "@goodgym/theme";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Banner from "./components/Banner";
import Card from "./components/Cards";
import { imageIconData, CardData } from "./data";
import * as Images from "./data/images";
import ImageIcon from "./components/ImageIcon";
import aboutPage from "../AboutPage/data/images/aboutPage.jpeg";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";

const AboutPage: React.FC = () => {
  const [postcode, setPostcode] = useState("");
  const [alert, setAlert] = useState(false);

  return (
    <>
      <Banner image={aboutPage} />
      <UI.Container>
        <UI.Typography
          variant="h2"
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            padding: 3,
            textTransform: "uppercase",
          }}
        >
          What exactly does goodgym do?
        </UI.Typography>
      </UI.Container>
      <UI.Container>
        <UI.Typography
          variant="h5"
          sx={{
            fontWeight: "normal",
            display: "flex",
            justifyContent: "center",
            paddingBottom: 3,
          }}
        >
          We get fit by doing good in the following four ways
        </UI.Typography>
      </UI.Container>
      <UI.Container>
        <UI.Grid container spacing={1}>
          {CardData.map((element, index) => (
            <Card
              title={element.title}
              image={element.image}
              strapline={element.strapline}
              key={index}
              linkForButton={element.link}
            />
          ))}
        </UI.Grid>
      </UI.Container>
      <UI.Container>
        <UI.Typography
          variant="h2"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 3,
            minHeight: "150px",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          Is there a goodgym near me?
        </UI.Typography>
      </UI.Container>
      <UI.Container>
        <UI.Typography
          variant="h5"
          sx={{
            fontWeight: "normal",
            textAlign: "center",
          }}
        >
          We're open in 59 areas all across the UK, from Barnet to York.
          <UI.Typography
            variant="h5"
            sx={{
              fontWeight: "normal",
              textAlign: "center",
              my: 1,
            }}
          >
            {" "}
            Find your nearest GoodGym by searching by your postcode or see the{" "}
            <a
              style={{ color: theme.palette.primary.main }}
              href="https://www.goodgym.org/areas"
            >
              full list of areas
            </a>
          </UI.Typography>
          .
        </UI.Typography>
        <UI.Container
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <UI.Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Your postcode"
              inputProps={{ "aria-label": "your postcode" }}
              onChange={(event) => {
                setPostcode(event.target.value);
              }}
            />

            <UI.Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <UI.IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={() => {
                if (postcode.length < 2) {
                  setAlert(!alert);
                } else {
                  setAlert(false);
                  window.location.href = `/areas/search?postcode=${postcode}`;
                }
              }}
            >
              <SearchIcon />
            </UI.IconButton>
          </UI.Paper>
        </UI.Container>
        {alert && (
          <UI.Container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: { xs: "100%", sm: "90%", md: "50%" },
            }}
          >
            <UI.Alert
              severity="warning"
              onClose={() => {
                setAlert(false);
              }}
              sx={{ m: 2 }}
            >
              Please enter a valid postcode
            </UI.Alert>
          </UI.Container>
        )}
      </UI.Container>
      <UI.Typography
        variant="h2"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "150px",
          padding: 3,
          textAlign: "center",
        }}
      >
        THE STORY SO FAR
      </UI.Typography>{" "}
      <UI.Container>
        <UI.Typography
          sx={{
            fontSize: "18px",
            mb: 1,
          }}
        >
          GoodGym arose out of a frustration with normal gyms being a waste of
          energy and human potential - and responding to growing loneliness in
          cities. It started when founder Ivo started running to deliver the
          newspaper to Terry, an older Hackney resident in 2007. Terry was
          housebound and didn’t see any friends on a regular basis. Ivo wasn’t
          exercising much and needed a reason to run.
        </UI.Typography>
        <UI.Grid container sx={{ textAlign: "center", my: 2 }}>
          <UI.Grid item xs={12} sm={6}>
            {" "}
            <UI.Box
              component="img"
              sx={{ height: "auto", width: { xs: "340px", md: "400px" } }}
              src={Images.aboutpage1}
              alt="goodgym"
            />{" "}
          </UI.Grid>
          <UI.Grid item xs={12} sm={6}>
            {" "}
            <UI.Box
              component="img"
              sx={{ height: "auto", width: { xs: "340px", md: "400px" } }}
              src={Images.aboutpage2}
              alt="goodgym"
            />{" "}
          </UI.Grid>
        </UI.Grid>
        <UI.Typography
          sx={{
            fontSize: "18px",
            mb: 1,
          }}
        >
          Over the course of a few years they became friends and Ivo learned to
          love running. He started getting other people to do the same thing;
          combining their run with helping an older neighbour.
        </UI.Typography>
        <UI.Typography
          sx={{
            fontSize: "18px",
            mb: 1,
          }}
        >
          In September 2009 we formed as a non profit company and in 2011 we
          started meeting up in groups to run to help community projects. People
          in Bristol and Liverpool picked up on the idea and started running
          sessions too. A sense of community started to emerge and we started
          running sessions all over East London around the time of the 2012
          Olympics. In 2015 we registered as a charity.
        </UI.Typography>
        <UI.Grid container sx={{ textAlign: "center", my: 3 }}>
          <UI.Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {" "}
            <UI.Typography
              sx={{
                fontSize: "18px",
                mb: 1,
                px: 7,
              }}
            >
              <ImQuotesLeft style={{ marginRight: "10px" }} />
              I love going round the city and being like- I did that…I dug
              that…I painted that. It’s just that lovely sense of feeling you’ve
              done something good, you’ve done some exercise- it literally just
              covers everything.
              <ImQuotesRight style={{ marginLeft: "10px" }} />
            </UI.Typography>
            <UI.Typography
              sx={{
                mb: 1,
                fontSize: "18px",
              }}
            >
              Jenny, Bristol
            </UI.Typography>
          </UI.Grid>
          <UI.Grid item xs={12} sm={6}>
            {" "}
            <UI.Box
              component="img"
              sx={{ height: "auto", width: { xs: "340px", md: "400px" } }}
              src={Images.aboutpage_mission}
              alt="goodgym"
            />{" "}
          </UI.Grid>
        </UI.Grid>
        <UI.Typography
          sx={{
            fontSize: "18px",
            mb: 1,
          }}
        >
          Over the following years, we expanded rapidly, launching in new areas
          and reaching 59 cities and boroughs just before Covid-19 brought our
          group sessions to a halt. During the pandemic we received 10x the
          usual amount of requests for support for older people. We started
          doing deliveries of prescriptions and food too. We welcomed increasing
          numbers of walkers and cyclists, and have now adapted our work to
          enable more of this to happen.
        </UI.Typography>
        <UI.Grid container sx={{ textAlign: "center", mt: 2 }}>
          <UI.Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {" "}
            <UI.Typography
              sx={{
                fontSize: "18px",
                mb: 1,
                px: 7,
              }}
            >
              <ImQuotesLeft style={{ marginRight: "10px" }} />I don't know what
              I'd do without GoodGym.
              <ImQuotesRight style={{ marginLeft: "10px" }} />
            </UI.Typography>
            <UI.Typography
              sx={{
                mb: 1,
                fontSize: "18px",
              }}
            >
              Mrs J, Croydon
            </UI.Typography>
          </UI.Grid>
          <UI.Grid item xs={12} sm={6}>
            {" "}
            <UI.Box
              component="img"
              sx={{ height: "auto", width: { xs: "340px", md: "400px" } }}
              src={Images.aboutpage_delivery}
              alt="goodgym"
            />{" "}
          </UI.Grid>
        </UI.Grid>
        <UI.Typography
          sx={{
            fontSize: "18px",
            my: 1,
          }}
        >
          Having emerged from the pandemic, and with group sessions back, we’re
          starting to expand again and working on how we continue to make what
          we do more fun and easier to get involved in. We want as many people
          as possible to benefit from what we do.
        </UI.Typography>
      </UI.Container>
      <UI.Typography
        variant="h2"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "150px",
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        Why are we doing it?
      </UI.Typography>{" "}
      <UI.Container>
        <UI.Typography
          sx={{
            fontSize: "18px",
            mb: 1,
          }}
        >
          GoodGym is one of the most fun things you can do. We know that if lots
          of us make this a regular part of our lives we can make a massive
          difference to the cities we live in. We can contribute to making them
          greener, less lonely, and better connected. Loneliness and isolation
          are growing. Our lives are increasingly lived through screens and
          we’re becoming more isolated. Not only can participation in GoodGym
          improve someone’s wellbeing, it can help someone else too. By stopping
          off on our runs, walks and cycles we can provide connection, hope and
          sustenance to those who are housebound.
        </UI.Typography>
        <UI.Container>
          <UI.Box
            component="img"
            sx={{ height: "auto", width: "100%", marginTop: 2 }}
            src={Images.aboutpage_quote}
            alt="goodgym"
          />{" "}
        </UI.Container>
        <UI.Typography
          sx={{
            fontSize: "18px",
            my: 1,
          }}
        >
          There are also thousands of organisations, spaces and groups that need
          more work and that can benefit from volunteers. By steering the energy
          from our exercise into these projects we can make a huge difference
          and increase the sustainability of vital foodbanks, community groups
          and green spaces.
        </UI.Typography>
      </UI.Container>
      <UI.Typography
        variant="h2"
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: 3,
          alignItems: "center",
          minHeight: "150px",
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        What's the plan?
      </UI.Typography>{" "}
      <UI.Container>
        <UI.Typography
          sx={{
            fontSize: "18px",
            my: 1,
          }}
        >
          GoodGym is now open to everyone in 62 areas across the UK and there
          are hundreds of people interested in setting up GoodGym in their area.
          <UI.Typography
            sx={{
              fontSize: "18px",
              my: 1,
            }}
          >
            We want to rival the success of gyms, getting people all over the
            world off treadmills and into their communities. We are always
            looking for brilliant people to work with; if you want to get in
            touch about setting up GoodGym where you are go to
            goodgym.org/proposals/[insertyourareaname] - if the page doesn’t
            exist, email us at:
            <a
              href="mailto:getinvolved@goodgym.org"
              style={{ color: "#E11018", textDecoration: "none" }}
            >
              getinvolved@goodgym.org
            </a>
            .
          </UI.Typography>
        </UI.Typography>
      </UI.Container>
      <UI.Typography
        variant="h2"
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: 3,
          alignItems: "center",
          minHeight: "150px",
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        Supporters and partners
      </UI.Typography>{" "}
      <UI.Typography
        variant="h4"
        sx={{ display: "flex", justifyContent: "center", fontWeight: "normal" }}
      >
        <p> GoodGym is supported by:</p>
      </UI.Typography>
      <UI.Container
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          [theme.breakpoints.down("md")]: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          },
        }}
      >
        <a href="https://uk.athleticbrewing.com/" style={{ cursor: "pointer" }}>
          {" "}
          <UI.Box
            component="img"
            alt="logo"
            src={Images.brewingLogo}
            sx={{ height: "auto", width: "150px" }}
          />
        </a>
        <a
          href="https://www.tnlcommunityfund.org.uk/"
          style={{ cursor: "pointer" }}
        >
          {" "}
        </a>
        <a href="https://www.runthings.co.uk/" style={{ cursor: "pointer" }}>
          {" "}
          <UI.Box
            component="img"
            alt="logo"
            src={Images.runLogo}
            sx={{ height: "auto", width: "150px", paddingTop: "30px" }}
          />
        </a>
      </UI.Container>
      <UI.Typography
        variant="h4"
        sx={{
          display: "flex",
          justifyContent: "center",
          fontWeight: "normal",
          textAlign: "center",
          paddingTop: 6,
          paddingLeft: 2,
          paddingRight: 2,
        }}
      >
        <UI.Typography
          sx={{
            fontSize: "18px",
            my: 1,
          }}
        >
          We'd also like to thank the following organisations for their support:
        </UI.Typography>
      </UI.Typography>
      <UI.Container sx={{}}>
        <UI.Grid
          container
          sx={{ gap: "40px", display: "flex", justifyContent: "center" }}
        >
          {imageIconData.map((element, index) => {
            return (
              <UI.Grid item lg={2} md={3} sm={6} xs={12} key={index}>
                <UI.Container
                  sx={{
                    backgroundColor: "",
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <ImageIcon
                    key={index}
                    link={element.link}
                    tagline={element.tagline}
                    icon={element.icon}
                  ></ImageIcon>
                </UI.Container>
              </UI.Grid>
            );
          })}
        </UI.Grid>
      </UI.Container>
      <UI.Typography
        variant="h2"
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: 3,
          alignItems: "center",
          minHeight: "150px",
          textAlign: "center",
        }}
      >
        Our Policies
      </UI.Typography>{" "}
      <UI.Container sx={{ marginBottom: { md: "0%", sm: "15%", xs: "25%" } }}>
        <UI.Typography
          sx={{
            fontSize: "18px",
            my: 1,
          }}
        >
          <strong>Diversity statement from GoodGym's board of trustees</strong>
          <i>
            <UI.Typography
              sx={{
                fontSize: "18px",
                my: 1,
              }}
            >
              GoodGym is committed to equality of opportunity both in the
              provision of services to the public and as an employer.
            </UI.Typography>
          </i>
          <i>
            {" "}
            The Board of Trustees recognises the huge benefits that diversity in
            its broadest sense can have on the organisation. We understand a
            combination of skills and experiences, age, professional background,
            gender, ethnicity, disability and sexual orientation make for a
            range of perspectives and insights needed for sound decision making.
          </i>
          <UI.Typography
            sx={{
              fontSize: "18px",
              my: 1,
            }}
          >
            <i>
              We understand that the Board sets the tone for diversity and
              equality throughout the organisation and believe that having a
              diverse leadership team leads to an open and inclusive culture
              elsewhere in GoodGym.
            </i>
          </UI.Typography>
          <UI.Typography
            sx={{
              fontSize: "18px",
              my: 1,
              mb: 2,
            }}
          >
            To view GoodGym's operational policies please click{" "}
            <a
              href="https://drive.google.com/drive/folders/1fNy42lralcuYdhqRl97OCOhfLLQNLje3"
              style={{ color: "#E11018", textDecoration: "none" }}
            >
              {" "}
              here
            </a>
          </UI.Typography>
        </UI.Typography>
      </UI.Container>
    </>
  );
};
export default AboutPage;
