import React from "react";
import * as UI from "@goodgym/components";
import { styled as Styled } from "@mui/system";

export type BannerProps = {
  title: string;
  tagline?: string;
  buttonText?: string;
  image?: string;
  linkForButton?: string;
};

const BannerContainer = Styled(UI.Box)`
    display:flex;
    justify-content:center;
    align-items:center;
    height:100%;  
    width:100%;
    background: rgb(51, 51, 51);  
      @media(max-width:767px){
        background:none;
        display:flex;
        flex-direction:column-reverse;
      }
`;

const LandingHeader = Styled(UI.Container)`
    height:100%;
    position:relative;
    background: rgb(51, 51, 51);     
    @media(max-width:767px){
    margin-bottom:40px;
    display: block;
}`;

interface LandingHeaderProp {
  imageurl: string | undefined;
}

const LandingHeaderImage = Styled(UI.Container)`
      height:auto;
      width:100%;
      background-image:url(${({ imageurl }: LandingHeaderProp) => imageurl});
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      aspect-ratio: 5/3;
      @media (min-width: 768px) {
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          height: 100%;
          width: 3rem;
          height: 100%;
          background-image: linear-gradient(
            to left bottom,
            transparent,
            transparent 50%,
            rgb(51, 51, 51) 51%,
            rgb(51, 51, 51)
          );
        }
      }
      @media (max-width: 767px) {
        &:after {
          display: block;
          z-index: 1;
          position: absolute;
          bottom: -0.9px;
          left:0px;
          content: "";
          width: 100%;
          height: 3rem;
          margin-top: -3rem;
          background-image: linear-gradient(
            to bottom left,
            transparent,
            transparent 50%,
            #333 51%,
            #333
          );
        }
      }
      @media (max-width: 767px) {
        width: 100%;
        height:230px;
      }
      
    `;
const Banner: React.FC<BannerProps> = ({
  title,
  tagline,
  buttonText,
  linkForButton,
  image,
}) => {
  return (
    <BannerContainer>
      <LandingHeader>
        <UI.Container
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { md: "100%", lg: "90%", xl: "70%" },
            mr: 2,
          }}
        >
          <UI.Typography
            variant="h2"
            color="#FFF"
            sx={{
              typography: { lg: "h1", md: "h2" },
              textTransform: "uppercase",
              textAlign: "center",
            }}
          >
            {title}
          </UI.Typography>

          <UI.Typography
            variant="h5"
            color="#FFF"
            sx={{
              lineHeight: "1.5",
              my: 3,
              fontWeight: "normal",
              textAlign: "center",
              width: "100%",
            }}
          >
            {tagline}
          </UI.Typography>

          {buttonText && (
            <UI.Button
              variant="contained"
              color="primary"
              href={linkForButton}
              sx={{
                height: "50px",
                padding: 2,
                mb: 3,
              }}
            >
              {buttonText}
            </UI.Button>
          )}
        </UI.Container>
      </LandingHeader>
      <LandingHeaderImage imageurl={image}></LandingHeaderImage>
    </BannerContainer>
  );
};

export default Banner;
