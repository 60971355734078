import {
  charlieLogo,
  coachVisit,
  kayakoLogo,
  missionRun,
  nounLogo,
  run,
  scoutLogo,
  task,
  travisLogo,
  vanguardLogo,
  zendeskLogo,
  freepik
} from "./images";

export const CardData = [
  {
    id: 1,
    title: "Community mission",
    image: task,
    strapline:
      "Run, walk or cycle to help community projects with practical tasks",
    link: "https://www.goodgym.org/community-missions",
  },
  {
    id: 2,
    title: "Group runs",
    image: run,
    strapline:
      "We run in groups to do physical tasks for community organisations ",
    link: "https://www.goodgym.org/group-runs",
  },
  {
    id: 3,
    title: "Mission runs",
    image: missionRun,
    strapline:
      "We run, walk, or cycle to help older people with one-off tasks they can no longer do alone, such as clearing gardens",
    link: "https://www.goodgym.org/missions",
  },
  {
    id: 4,
    title: "Social Visits",
    image: coachVisit,
    strapline:
      "We run, walk or cycle to make social visits to isolated older people",
    link: "https://www.goodgym.org/stories/social-visits-how-to-get-involved",
  },
];

export const imageIconData = [
  {
    link: "https://kayako.com/",
    icon: kayakoLogo,
    tagline: "Kayako provides our support desk software",
  },
  {
    link: "https://thenounproject.com/",
    icon: nounLogo,
    tagline: "We use lots of icons from the Noun Project on the website",
  },
  {
    link: "https://scoutapm.com/",
    icon: scoutLogo,
    tagline: "Scout helps us keep our website nice and fast",
  },
  {
    link: "https://www.travis-ci.com/",
    icon: travisLogo,
    tagline: "Travis CI tells us when a new feature has broken the website",
  },
  {
    link: "https://goodgym.charliehr.com/login",
    icon: charlieLogo,
    tagline:
      "Charlie HR provides us with easy-to-use human resources and team management software",
  },
  {
    link: "https://www.zendesk.co.uk/",
    icon: zendeskLogo,
    tagline: "Zendesk provides us with the tools to manage our calls remotely",
  },
  {
    link: "https://www.vanguardstorage.co.uk/",
    icon: vanguardLogo,
    tagline:
      "Vanguard Storage keep our t-shirts secure, ready to be sent out to our members.",
  },
  {
    link: "https://www.freepik.com//",
    icon: freepik,
    tagline: "Freepik provide us with icons.",
  },
];
