import React from "react";
import * as UI from "@goodgym/components";
import AvailabilityForm from "./AvailabilityForm";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import * as T from "@goodgym/graphql/types";
import Button from "./AvailabilityForm/Button";

export type RequestPairingProps = {
  coachReferral: T.CoachReferralFragment;
  testPairingEnabled: boolean;
  runnerVerified: boolean;
  runner: T.CurrentRunnerFragment;
  previousHadTestPairing: boolean;
};
const RequestPairing: React.FC<RequestPairingProps> = ({
  runner,
  coachReferral,
  testPairingEnabled,
  runnerVerified,
  previousHadTestPairing
}) => {

  //Set the initial availability to empty string
  const [availability, setAvailability] = React.useState([
    { day: "", from: "", to: "" },
  ]);

  //This state responsible for adding additional rows of the component 'AvailabilityForm'
  const [components, setComponents] = React.useState(["Availability Form"]);


  const addAvailabilityRow = () => {
    if (components.length >= 3) {
      return;
    }
    const insertAt = availability.length;
    const nextRowAvailability = [
      ...availability.slice(0, insertAt),
      { day: "", from: "", to: "" },
      ...availability.slice(insertAt),
    ];
    setComponents([...components, "Availability Form"]);
    setAvailability(nextRowAvailability);
  };

  const removeAvailabilityRow = () => {
    if (components.length <= 1) {
      setComponents([...components]);
    } else {
      const componentsList = [...components];
      setComponents(componentsList.slice(0, -1));
      const deleteRow = [...availability];
      setAvailability(deleteRow.slice(0, -1));
    }
  };

  return (
    <>
      {runnerVerified && (
        <UI.Box>
          <UI.Grid columnSpacing={5} container sx={{ pt: 1, mb: 2 }}>
            <UI.Grid xs={12} item>
              <UI.Typography
                variant="h4"
                sx={{ mt: 2, mb: 1, scrollMarginTop: "70px" }}
              >
                Request trial visit
              </UI.Typography>
            </UI.Grid>
          </UI.Grid>
          <UI.Typography variant="subtitle1" sx={{ mt: 2, mb: 2 }}>
            Tell us which days and times you are available:
          </UI.Typography>
          <UI.Typography>
            Please use this form to indicate some time ranges that you are available for 
            a trial visit with an older person. Use multiple lines if you are available 
            on more than one day, or if you have more than one time range in a day.  
          </UI.Typography>
          {components.map((form, index) => (
            <AvailabilityForm
              key={index}
              index={index}
              setAvailability={setAvailability}
              availability={availability}
            />
          ))}{" "}
          <UI.Box sx={{ display: "flex", justifyContent: "right" }}>
            <UI.Button onClick={addAvailabilityRow} sx={{ my: 1, mx: 2 }}>
              <AddCircleOutlineIcon fontSize="medium" />
            </UI.Button>
            <UI.Button onClick={removeAvailabilityRow} sx={{ my: 1 }}>
              <RemoveCircleOutlineIcon fontSize="medium" />
            </UI.Button>
          </UI.Box>{" "}
          <UI.Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              coachReferral={coachReferral}
              runner={runner}
              runnerSuggestedTimes={availability}
              testPairingEnabled={testPairingEnabled}
              previousHadTestPairing={previousHadTestPairing}
            />
          </UI.Box>
        </UI.Box>
      )}

      { !runnerVerified && (
        <UI.Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: { xs: 2, sm: 2, md: 5 },
          }}
        >
          <UI.Typography variant="subtitle1" sx={{ mt: "4px" }}>
            <UI.Link
              sx={{ mt: 1, color: "primary.dark" }}
              href="https://www.goodgym.org/my/coach/verification"
            >
              Get verified to request pairing
            </UI.Link>
          </UI.Typography>
        </UI.Box>
      )}
    </>
  );
};

export default RequestPairing;
