import { ApolloError } from "@apollo/client";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as Icons from "@goodgym/icons";
import * as u from "@goodgym/util";
import React from "react";
import InfiniteScroll from "react-infinite-scroller";


export type StoriesFeedProp = {
  stories: T.StoryFragment[];
  fetchMore: () => void;
  more: boolean;
  error?: ApolloError;
  drafted?: boolean
  deleted?:boolean
};

const StoriesFeed: React.FC<StoriesFeedProp> = ({
  stories,
  fetchMore,
  error,
  more,
  drafted,
  deleted
}) => {
  //Removed the drawer component for now, potentially could be added on later 
  // const { showStory } = useDrawers();


  const linkForStory = (story:T.StoryFragment) => {
    if (drafted) {
      return u.links.draftedStory(story);
    }
    if (deleted) {
      return u.links.deletedStory(story);
    }
    else 
      return u.links.story(story);
  }


  if (error) return <UI.Error />;
  if (!stories) return <UI.Loading />;
  
  return (
    <UI.Box>
      <InfiniteScroll
        hasMore={more}
        loadMore={fetchMore}
        initialLoad={false}
        loader={<UI.InfiniteScrollLoader key="loader" more={more} />}
      >
        {!stories ? (
          <UI.Loading key="loading" />
        ) : (
          <>
            {stories.length === 0 && (
              <UI.Box key="empty" sx={{ mb: 2 }}>
                <UI.Typography variant="h5" color="neutral.main">
                  Nothing to show here yet...
                </UI.Typography>
              </UI.Box>
            )}
            <UI.Grid
              container
              spacing={4}
              sx={{ alignItems: "stretch", mb: 2 }}
            >
              {stories.map((story) => (
                <UI.Grid key={story.id} item xs={12} md={6} xl={4}>
                  <UI.Card
                    key={story.id}
                    sx={{ height: "100%", alignItems: "center" }}
                  >
                    <UI.CardMedia image={story.imageUrl} title={story.title} />
                    <UI.CardContent>
                      <UI.AreaChip area={story.area} sx={{ mr: 1, mb: 1 }} />
                      <UI.Typography gutterBottom variant="h5" component="h2">
                        <UI.Link
                          href={
                            linkForStory(story)
                          }
                          color="black.main"
                        >
                          {story.title}
                        </UI.Link>
                      </UI.Typography>
                      <UI.Typography
                        color="textSecondary"
                        variant="subtitle2"
                        sx={{ my: 1 }}
                      >
                        {story.discardedAt ? (
                          <>
                            <Icons.Delete sx={{ fontSize: "20px", mr: 1 }} />
                            {u.time.formatDate(story.discardedAt)}
                          </>
                        ) : (
                          story.publishedAt && (
                            <>
                              <Icons.EventRounded
                                sx={{ fontSize: "20px", mr: 1 }}
                              />
                              {u.time.formatDate(story.publishedAt)}
                            </>
                          )
                        )}
                      </UI.Typography>
                      {story.author && (
                        <UI.Typography
                          color="textSecondary"
                          variant="subtitle2"
                          sx={{ my: 1 }}
                        >
                          <Icons.ArticleRounded
                            sx={{ fontSize: "20px", mr: 1 }}
                          />
                          Story written by{" "}
                          <UI.RunnerLink runner={story.author} />
                        </UI.Typography>
                      )}

                      <UI.Typography sx={{ mt: 2 }}>
                        {story.strapline}
                      </UI.Typography>
                      <UI.Button
                        href={
                         linkForStory(story)
                        }
                        variant="text"
                        sx={{ ml: -1, color: "secondary.dark" }}
                      >
                        Read more
                      </UI.Button>
                    </UI.CardContent>
                  </UI.Card>
                </UI.Grid>
              ))}
            </UI.Grid>
          </>
        )}
      </InfiniteScroll>
    </UI.Box>
  );
};
export default StoriesFeed;
