/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import * as h from "./helpers";
import InfiniteScroll from "react-infinite-scroller";
import { Calendar, Map, Filters, ResultCount } from "./components";
import { State } from "./types";

export type SessionsFeedProps = UI.BoxProps & {
  state: State;
};

const SessionsFeed: React.FC<SessionsFeedProps> = ({ state, ...props }) => {
  const { loading, more, fetchMore, items } = state;

  return (
    <InfiniteScroll
      hasMore={more}
      loadMore={fetchMore}
      initialLoad={false}
      loader={<UI.InfiniteScrollLoader key="loader" more={more} />}
    >
      {loading ? (
        <UI.Loading key="loading" />
      ) : (
        <>
          {items.length === 0 && (
            <UI.Box key="empty">
              <UI.Typography variant="h5" color="neutral.main">
                No sessions found 🤔
              </UI.Typography>
              <UI.Typography variant="body1" color="neutral.main">
                Try removing or changing some of your filters
              </UI.Typography>
            </UI.Box>
          )}
          {_.map(h.groupByDay(items), ([date, items]) => (
            <UI.Box key={date.getTime()} sx={{ pt: 1, pb: 3 }}>
              <UI.Typography variant="h5" color="neutral.main">
                {u.time.formatDay(date)}
              </UI.Typography>
              {items.map((item) => (
                <UI.Box key={item.id} sx={{ mb: 2 }}>
                  <UI.ListingCard
                    key={h.identifier(item)}
                    listing={item}
                    sx={{ my: 2 }}
                  />
                </UI.Box>
              ))}
            </UI.Box>
          ))}
        </>
      )}
    </InfiniteScroll>
  );
};

//@ts-ignore
SessionsFeed.Calendar = Calendar;
//@ts-ignore
SessionsFeed.Map = Map;
//@ts-ignore
SessionsFeed.ResultCount = ResultCount;
//@ts-ignore
SessionsFeed.Filters = Filters;

export default SessionsFeed as typeof SessionsFeed & {
  Calendar: typeof Calendar;
  Map: typeof Map;
  Filters: typeof Filters;
  ResultCount: typeof ResultCount;
};
