/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import { useAreaSearchQuery } from "@goodgym/graphql/types";
import React, { useState } from "react";
import { State } from "../../../types";

export type AreasFilterProps = UI.BoxProps & {
  state: Pick<State, "areas" | "sections" | "filters" | "runner">;
};

const AreasFilter: React.FC<AreasFilterProps> = ({
  state,
  ...props
}) => {
  const { filters, runner } = state;

  const { data } = useAreaSearchQuery({
    variables: {},
  });

  const runnerAreas = runner?.areas?.map((a) => a.id) || [];
  
  const initialSelectedValue = () => {
    switch(state.filters.values.areaIds.length) {
      case 0:
        return "all";
      case 1:
        return state.filters.values.areaIds[0];
      default:
        return "your"
    }
  }
  const [areaSelected, setAreaSelected] = useState(initialSelectedValue());

  return (
    <UI.Box {...props}>
      <UI.Typography variant="subtitle1">
        <UI.Grid container>
          <UI.Grid item sx={{ alignSelf: "center", flexGrow: 1, mb: 1 }}>
            Areas
          </UI.Grid>
        </UI.Grid>
      </UI.Typography>

      <UI.Box sx={{ minWidth: 120 }}>
        <UI.FormControl fullWidth>
          <UI.InputLabel id="area-select-label">Areas</UI.InputLabel>

          <UI.Select
            id="area-select-filter"
            label="Area"
            onChange={(event) => {
              const value = (): Array<string> => {
                switch (event.target.value) {
                  case "all":
                    return [];
                  case "your":
                    return runnerAreas;
                  default:
                    return [event.target.value];
                }
              };
              filters.update({ areaIds: value() });
              setAreaSelected(event.target.value);
            }}
            value={areaSelected}
          >
            <UI.MenuItem value="all">All Areas</UI.MenuItem>
            <UI.MenuItem value="your">Your Areas</UI.MenuItem>
            {data?.areas.map((area) => (
              <UI.MenuItem key={area.id} value={area.id} aria-label={area.name}>
                {area.name}
              </UI.MenuItem>
            ))}
          </UI.Select>
        </UI.FormControl>
      </UI.Box>
    </UI.Box>
  );
};

export default AreasFilter;
