import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
};

export type Address = {
  __typename?: "Address";
  accessNotes?: Maybe<Scalars["String"]>;
  city: Scalars["String"];
  id: Scalars["ID"];
  lat?: Maybe<Scalars["Float"]>;
  line1: Scalars["String"];
  line2?: Maybe<Scalars["String"]>;
  lng?: Maybe<Scalars["Float"]>;
  otherInformation?: Maybe<Scalars["String"]>;
  postcode: Scalars["String"];
};

export type AddressSearchResult = {
  __typename?: "AddressSearchResult";
  area?: Maybe<Area>;
  city: Scalars["String"];
  country: Scalars["String"];
  county: Scalars["String"];
  id: Scalars["ID"];
  line1: Scalars["String"];
  line2?: Maybe<Scalars["String"]>;
  postcode: Scalars["String"];
  udprn: Scalars["String"];
};

export type Area = {
  __typename?: "Area";
  active: Scalars["Boolean"];
  areaActivator?: Maybe<Runner>;
  description: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  facebook?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  imageUrl: Scalars["String"];
  instagram?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  pairingVerifiedRunnersCount: Scalars["Int"];
  partners: Array<AreaPartner>;
  runLeaders: Array<Runner>;
  slug: Scalars["String"];
  socialVisits: Scalars["Boolean"];
  stats: AreaStats;
  taskforce: Array<Runner>;
  twitter?: Maybe<Scalars["String"]>;
  upcomingSessions: Array<Session>;
};

export type AreaUpcomingSessionsArgs = {
  limit: Scalars["Int"];
};

export type AreaPartner = {
  __typename?: "AreaPartner";
  description: Scalars["String"];
  id: Scalars["ID"];
  imageUrl: Scalars["String"];
  name: Scalars["String"];
  url: Scalars["String"];
};

export type AreaStats = {
  __typename?: "AreaStats";
  id: Scalars["ID"];
  missionVerifiedRunners: Scalars["Int"];
};

/** A result of a search for coaches */
export type AvailableCoachesResult = {
  __typename?: "AvailableCoachesResult";
  more: Scalars["Boolean"];
  results: Array<Coach>;
};

export type Award = {
  __typename?: "Award";
  badge: Badge;
  id: Scalars["ID"];
};

export type Badge = {
  __typename?: "Badge";
  description: Scalars["String"];
  id: Scalars["ID"];
  imageUrl: Scalars["String"];
  name: Scalars["String"];
};

export type Beneficiary = {
  __typename?: "Beneficiary";
  id: Scalars["ID"];
  name: Scalars["String"];
};

/** Autogenerated return type of BlockMutation. */
export type BlockMutationPayload = {
  __typename?: "BlockMutationPayload";
  runners: Array<Runner>;
};

/** A CTA we want the runner to perform */
export type Cta = {
  __typename?: "CTA";
  actionText: Scalars["String"];
  description: Scalars["String"];
  dismissText?: Maybe<Scalars["String"]>;
  icon: Scalars["String"];
  id: Scalars["ID"];
  title: Scalars["String"];
  url: Scalars["String"];
};

/** Autogenerated return type of CancelTestPairingMutation. */
export type CancelTestPairingMutationPayload = {
  __typename?: "CancelTestPairingMutationPayload";
  errors?: Maybe<Array<Scalars["String"]>>;
  testPairing?: Maybe<TestPairing>;
};

export type Cheer = {
  __typename?: "Cheer";
  id: Scalars["ID"];
  recipient: Runner;
  runner: Runner;
};

/** Autogenerated return type of CheerMutation. */
export type CheerMutationPayload = {
  __typename?: "CheerMutationPayload";
  cheerable: Cheerable;
  cheers: Array<Cheer>;
};

/** An interface for anything that can be cheered */
export type Cheerable = {
  cheers: Array<Cheer>;
  id: Scalars["ID"];
};

/** Types of Cheerable activity */
export type CheerableType =
  /** Attending a GoodGym session */
  | "Attendance"
  /** Earning a badge */
  | "Award"
  /** Visiting a coach */
  | "CoachVisit"
  /** Registering for a GoodGym session */
  | "Registration"
  /** Completing a workout */
  | "Workout";

export type Coach = {
  __typename?: "Coach";
  additionalInfo: Scalars["String"];
  area: Area;
  availableForVisit: Scalars["String"];
  distanceFromRunner?: Maybe<Scalars["String"]>;
  homeRiskAssesed: Scalars["Boolean"];
  id: Scalars["ID"];
  interests: Scalars["String"];
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
  name: Scalars["String"];
  publicName: Scalars["String"];
  reasonForReferral: Scalars["String"];
};

export type Comment = {
  __typename?: "Comment";
  content: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  runner: Runner;
};

/** An interface for anything that can be commented on */
export type Commentable = {
  comments: Array<Comment>;
  id: Scalars["ID"];
};

/** Things you can comment on */
export type CommentableType =
  /** A badge award */
  | "Award"
  /** A Coach Visit */
  | "CoachVisit"
  /** A GoodGym run report */
  | "Report"
  /** A GoodGym session */
  | "Session"
  /** A workout */
  | "Workout";

/** Autogenerated return type of CompleteTestPairingMutation. */
export type CompleteTestPairingMutationPayload = {
  __typename?: "CompleteTestPairingMutationPayload";
  errors?: Maybe<Array<Scalars["String"]>>;
  testPairing?: Maybe<TestPairing>;
};

/** Autogenerated return type of CompleteTrainingMutation. */
export type CompleteTrainingMutationPayload = {
  __typename?: "CompleteTrainingMutationPayload";
  runner: CurrentRunner;
  success: Scalars["Boolean"];
};

/** Autogenerated return type of CreateCommentMutation. */
export type CreateCommentMutationPayload = {
  __typename?: "CreateCommentMutationPayload";
  /** @deprecated replaced by commentable */
  comment: Comment;
  commentable: Commentable;
};

export type CurrentRunner = {
  __typename?: "CurrentRunner";
  /** @deprecated Replaced by home_area */
  area?: Maybe<Area>;
  areas: Array<Area>;
  avatarUrl: Scalars["String"];
  awards: Array<Award>;
  bio?: Maybe<Scalars["String"]>;
  /** The current user's CTAs */
  ctas: Array<Cta>;
  donation?: Maybe<Donation>;
  employment?: Maybe<Employment>;
  featureFlags: Array<Scalars["String"]>;
  homeArea?: Maybe<Area>;
  id: Scalars["ID"];
  isMember: Scalars["Boolean"];
  name: Scalars["String"];
  needsToGetCoachVerified: Scalars["Boolean"];
  needsToGetMissionVerified: Scalars["Boolean"];
  neverRun: Scalars["Boolean"];
  /** Get last 10 notifications for the current runner */
  notifications: Array<Notification>;
  pairing?: Maybe<Pairing>;
  postcode?: Maybe<Scalars["String"]>;
  pronouns?: Maybe<Scalars["String"]>;
  roles: Array<Scalars["String"]>;
  slug: Scalars["String"];
  stats: RunnerStats;
  streaks: Array<RunnerStreak>;
  /** @deprecated Replaced by areas */
  subscribedAreas: Array<Area>;
  suggestedSessions: Array<Session>;
  trainingStatuses: Array<RunnerTrainingStatus>;
  upcomingSessions: Array<Session>;
  user: User;
  verified: Scalars["Boolean"];
};

export type CurrentRunnerSuggestedSessionsArgs = {
  limit?: Maybe<Scalars["Int"]>;
};

/** Autogenerated return type of DeclineTaskRequestMutation. */
export type DeclineTaskRequestMutationPayload = {
  __typename?: "DeclineTaskRequestMutationPayload";
  taskRequest: TaskRequest;
};

/** Autogenerated return type of DeleteCommentMutation. */
export type DeleteCommentMutationPayload = {
  __typename?: "DeleteCommentMutationPayload";
  /** @deprecated replaced by commentable */
  comment: Comment;
  commentable: Commentable;
};

/** Autogenerated return type of DeleteGroupFitnessActivityMutation. */
export type DeleteGroupFitnessActivityMutationPayload = {
  __typename?: "DeleteGroupFitnessActivityMutationPayload";
  session: Session;
};

/** Autogenerated return type of DeleteStory. */
export type DeleteStoryPayload = {
  __typename?: "DeleteStoryPayload";
  errors: Array<Scalars["String"]>;
  story: Story;
};

/** Autogenerated return type of DismissCTAMutation. */
export type DismissCtaMutationPayload = {
  __typename?: "DismissCTAMutationPayload";
  currentRunner: CurrentRunner;
  id: Scalars["ID"];
};

export type Donation = {
  __typename?: "Donation";
  giftaid: Scalars["Boolean"];
  id: Scalars["ID"];
  value: Scalars["Float"];
};

export type Employer = {
  __typename?: "Employer";
  description: Scalars["String"];
  id: Scalars["ID"];
  imageUrl: Scalars["String"];
  name: Scalars["String"];
  slug: Scalars["String"];
};

export type Employment = {
  __typename?: "Employment";
  employer: Employer;
  id: Scalars["ID"];
  startedAt: Scalars["ISO8601DateTime"];
};

/** Autogenerated return type of FollowMutation. */
export type FollowMutationPayload = {
  __typename?: "FollowMutationPayload";
  runners: Array<Runner>;
};

/** A group fitness activity */
export type GroupFitnessActivity = {
  __typename?: "GroupFitnessActivity";
  address: Address;
  createdAt: Scalars["ISO8601DateTime"];
  description: Scalars["String"];
  distance: Scalars["Int"];
  id: Scalars["ID"];
  ledBy: Runner;
  session: Session;
  signups: Array<Runner>;
  startedAt: Scalars["ISO8601DateTime"];
  type: Scalars["String"];
};

/** What HTTP method should be used with an action */
export type HttpMethod = "DELETE" | "GET" | "POST" | "PUT";

/** Requested image size */
export type ImageSize =
  | "fixed_medium"
  | "fixed_small"
  | "large"
  | "medium"
  | "small"
  | "twitter_card"
  | "xlarge";

/** Autogenerated return type of JoinWaitingListMutation. */
export type JoinWaitingListMutationPayload = {
  __typename?: "JoinWaitingListMutationPayload";
  session: Session;
};

/** Autogenerated return type of LeaveWaitingListMutation. */
export type LeaveWaitingListMutationPayload = {
  __typename?: "LeaveWaitingListMutationPayload";
  session: Session;
};

/** Autogenerated return type of MarkAllNotificationsAsReadMutation. */
export type MarkAllNotificationsAsReadMutationPayload = {
  __typename?: "MarkAllNotificationsAsReadMutationPayload";
  notifications: Array<Notification>;
};

/** A menu option */
export type MenuOption = {
  __typename?: "MenuOption";
  completed: Scalars["Boolean"];
  disabled: Scalars["Boolean"];
  icon: Scalars["String"];
  id: Scalars["ID"];
  label: Scalars["String"];
  method: HttpMethod;
  url: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  /** Block a GoodGymer or GoodGymers */
  block: BlockMutationPayload;
  /** Cancel a trial visit */
  cancelTestPairing: CancelTestPairingMutationPayload;
  /** Cheers the runners you support who took part in an activity */
  cheer: CheerMutationPayload;
  /** Complete a trial visit */
  completeTestPairing: CompleteTestPairingMutationPayload;
  /** Submit answers to online training */
  completeTraining: CompleteTrainingMutationPayload;
  /** Creates a comment on the specified entity. */
  createComment: CreateCommentMutationPayload;
  /** Decline a task request */
  declineTaskRequest: DeclineTaskRequestMutationPayload;
  /** Marks a comment as deleted */
  deleteComment: DeleteCommentMutationPayload;
  /** Deletes a group fitness activity */
  deleteGroupFitnessActivity: DeleteGroupFitnessActivityMutationPayload;
  /** Deletes a story */
  deleteStory: DeleteStoryPayload;
  /** Dismisses a CTA without actioning it */
  dismissCta: DismissCtaMutationPayload;
  /** Follow a GoodGymer or GoodGymers */
  follow: FollowMutationPayload;
  /** Adds the currently logged in GoodGymer to the waiting list for the specified session */
  joinWaitingList: JoinWaitingListMutationPayload;
  /** Removes the currently logged in GoodGymer from the waiting list for the specified session */
  leaveWaitingList: LeaveWaitingListMutationPayload;
  /** Mark all your notifications as read */
  markAllNotificationsAsRead: MarkAllNotificationsAsReadMutationPayload;
  /** Publish/Unpublish a story */
  publishStory: PublishStoryPayload;
  /** Request a trial visit */
  requestTestPairing: RequestTestPairingMutationPayload;
  /** Signs the currently logged in GoodGymer up to the specified session */
  signUpToSession: SignUpToSessionMutationPayload;
  /** Unsigns up the currently logged in GoodGymer from the specified session */
  unSignUpFromSession: UnSignUpFromSessionMutationPayload;
  /** Unblock a GoodGymer or GoodGymers */
  unblock: UnblockMutationPayload;
  /** Uncheers the runners you support who took part in an activity */
  uncheer: UncheerMutationPayload;
  /** Undecline a task request */
  undeclineTaskRequest: UndeclineTaskRequestMutationPayload;
  /** Unfollow a GoodGymer or GoodGymers */
  unfollow: UnfollowMutationPayload;
  /** Unpublish a report */
  unpublishReport: UnpublishReportMutationPayload;
  /** Updates an area's basic details */
  updateArea: UpdateAreaMutationPayload;
  /** Updates an organisation's basic details */
  updateOrganisation: UpdateOrganisationMutationPayload;
};

export type MutationBlockArgs = {
  runnerIds: Array<Scalars["ID"]>;
};

export type MutationCancelTestPairingArgs = {
  cancellationNotes: Scalars["String"];
  id: Scalars["ID"];
  reschedule?: Maybe<Scalars["Boolean"]>;
};

export type MutationCheerArgs = {
  cheerableActivityId: Scalars["ID"];
  cheerableType: CheerableType;
  runnerIds?: Maybe<Array<Scalars["ID"]>>;
};

export type MutationCompleteTestPairingArgs = {
  id: Scalars["ID"];
  runnerVisitFeedback: Scalars["String"];
  runnerVisitNotes: Scalars["String"];
  runnerWantsPairing: Scalars["Boolean"];
};

export type MutationCompleteTrainingArgs = {
  answers: Array<TrainingAnswerInput>;
  trainingId: Scalars["ID"];
};

export type MutationCreateCommentArgs = {
  commentableId: Scalars["ID"];
  commentableType: CommentableType;
  content: Scalars["String"];
};

export type MutationDeclineTaskRequestArgs = {
  reason?: Maybe<Scalars["String"]>;
  taskRequestId: Scalars["ID"];
};

export type MutationDeleteCommentArgs = {
  commentId: Scalars["ID"];
};

export type MutationDeleteGroupFitnessActivityArgs = {
  groupFitnessActivityId: Scalars["ID"];
};

export type MutationDeleteStoryArgs = {
  storyId: Scalars["ID"];
};

export type MutationDismissCtaArgs = {
  id: Scalars["ID"];
};

export type MutationFollowArgs = {
  message?: Maybe<Scalars["String"]>;
  runnerIds: Array<Scalars["ID"]>;
};

export type MutationJoinWaitingListArgs = {
  sessionId: Scalars["ID"];
};

export type MutationLeaveWaitingListArgs = {
  sessionId: Scalars["ID"];
};

export type MutationPublishStoryArgs = {
  storyId: Scalars["ID"];
};

export type MutationRequestTestPairingArgs = {
  coachId: Scalars["ID"];
  runnerSuggestedTimes: Array<Scalars["JSON"]>;
};

export type MutationSignUpToSessionArgs = {
  groupFitnessActivityId?: Maybe<Scalars["ID"]>;
  sessionId: Scalars["ID"];
  taskforceRoleIds: Array<Scalars["ID"]>;
};

export type MutationUnSignUpFromSessionArgs = {
  sessionId: Scalars["ID"];
};

export type MutationUnblockArgs = {
  runnerIds: Array<Scalars["ID"]>;
};

export type MutationUncheerArgs = {
  cheerableActivityId: Scalars["ID"];
  cheerableType: CheerableType;
  runnerIds?: Maybe<Array<Scalars["ID"]>>;
};

export type MutationUndeclineTaskRequestArgs = {
  taskRequestId: Scalars["ID"];
};

export type MutationUnfollowArgs = {
  runnerIds: Array<Scalars["ID"]>;
};

export type MutationUnpublishReportArgs = {
  reportId: Scalars["ID"];
};

export type MutationUpdateAreaArgs = {
  areaId: Scalars["ID"];
  description?: Maybe<Scalars["String"]>;
};

export type MutationUpdateOrganisationArgs = {
  description?: Maybe<Scalars["String"]>;
  organisationId: Scalars["ID"];
};

/** A runner's newsfeed */
export type Newsfeed = {
  __typename?: "Newsfeed";
  area?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  items: Array<NewsfeedItem>;
  limit: Scalars["Int"];
  more: Scalars["Boolean"];
  page: Scalars["Int"];
  runner: Runner;
  type: NewsfeedType;
};

export type NewsfeedAttendance = Cheerable &
  Commentable & {
    __typename?: "NewsfeedAttendance";
    cheers: Array<Cheer>;
    comments: Array<Comment>;
    id: Scalars["ID"];
    runners: Array<Runner>;
    session: Session;
    time: Scalars["ISO8601DateTime"];
  };

export type NewsfeedAward = Cheerable &
  Commentable & {
    __typename?: "NewsfeedAward";
    badge: Badge;
    cheers: Array<Cheer>;
    comments: Array<Comment>;
    description: Scalars["String"];
    id: Scalars["ID"];
    runner: Runner;
    time: Scalars["ISO8601DateTime"];
  };

export type NewsfeedCoachVisit = Cheerable &
  Commentable & {
    __typename?: "NewsfeedCoachVisit";
    cheers: Array<Cheer>;
    comments: Array<Comment>;
    id: Scalars["ID"];
    runner: Runner;
    time: Scalars["ISO8601DateTime"];
  };

/** An item in a user's newsfeed */
export type NewsfeedItem =
  | NewsfeedAttendance
  | NewsfeedAward
  | NewsfeedCoachVisit
  | NewsfeedRegistration
  | NewsfeedWorkout;

export type NewsfeedRegistration = Cheerable &
  Commentable & {
    __typename?: "NewsfeedRegistration";
    cheers: Array<Cheer>;
    comments: Array<Comment>;
    id: Scalars["ID"];
    runners: Array<Runner>;
    session: Session;
    time: Scalars["ISO8601DateTime"];
  };

/** Types of newsfeed */
export type NewsfeedType =
  /** A feed of the runner's activities */
  | "Runner"
  /** A feed of activity by the runners the runner supports */
  | "Team";

export type NewsfeedWorkout = Cheerable &
  Commentable & {
    __typename?: "NewsfeedWorkout";
    averagePace: Scalars["String"];
    cheers: Array<Cheer>;
    comments: Array<Comment>;
    /** Distance in km */
    distance: Scalars["Int"];
    /** Duration in seconds */
    duration: Scalars["Int"];
    id: Scalars["ID"];
    name: Scalars["String"];
    provider?: Maybe<Scalars["String"]>;
    runner: Runner;
    time: Scalars["ISO8601DateTime"];
    type: Scalars["String"];
    uri?: Maybe<Scalars["String"]>;
  };

export type Notification = {
  __typename?: "Notification";
  content: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  imageUrl: Scalars["String"];
  read: Scalars["Boolean"];
  url: Scalars["String"];
};

/** An organisation */
export type Organisation = {
  __typename?: "Organisation";
  area?: Maybe<Area>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  imageUrl: Scalars["String"];
  logoUrl?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  slug: Scalars["String"];
  strapline: Scalars["String"];
  topSupporters: Array<Runner>;
  totalRunners: Scalars["Int"];
  totalSessions: Scalars["Int"];
  upcomingSessions: Array<Session>;
  url?: Maybe<Scalars["String"]>;
};

export type Pairing = {
  __typename?: "Pairing";
  active: Scalars["Boolean"];
  coach: Coach;
  id: Scalars["ID"];
};

/** Autogenerated return type of PublishStory. */
export type PublishStoryPayload = {
  __typename?: "PublishStoryPayload";
  errors: Array<Scalars["String"]>;
  story: Story;
};

export type Query = {
  __typename?: "Query";
  /** Find a GoodGym area by its slug */
  area?: Maybe<Area>;
  /** List all active GoodGym areas */
  areas: Array<Area>;
  /** Return addresses matching query */
  autocompleteAddress: Array<AddressSearchResult>;
  /** Return beneficiaries matching query */
  autocompleteBeneficiary: Array<RestrictedBeneficiary>;
  /** Return runners matching query */
  autocompleteRunner: Array<Runner>;
  /** Return sessions matching query */
  autocompleteSession: Array<Session>;
  /** Return task requests matching query */
  autocompleteTaskRequest: Array<TaskRequest>;
  /** Coach by id */
  availableCoach: Coach;
  /** Coaches in range */
  availableCoaches: AvailableCoachesResult;
  currentReferrer?: Maybe<Referrer>;
  currentRunner?: Maybe<CurrentRunner>;
  currentUser?: Maybe<User>;
  /** Deleted Stories */
  deletedStories: StoriesResult;
  /** Drafted Stories */
  draftedStories: StoriesResult;
  explode: Scalars["String"];
  /** An activity feed for either a single GoodGymer or the people they follow */
  newsfeed: Newsfeed;
  /** Find an organisation by its id/slug/urn */
  organisation: Organisation;
  ping: Scalars["String"];
  /** Find a Report by its slug */
  report: Report;
  /** Run reports */
  reports: ReportsResult;
  /** Find a runner by their ID or slug */
  runner: Runner;
  /** Find a session by its id/slug */
  session: Session;
  /** Find the available session admin menu options for the current user */
  sessionAdminMenu: Array<MenuOption>;
  /** The sessions for a given time range */
  sessions: SessionsResult;
  /** Sessions matching the provided filters */
  sessionsV2: SessionsResultV2;
  stats: Stats;
  /** Stories */
  stories: StoriesResult;
  /** Find a Story by its slug */
  story: Story;
  /** Task requests matching the provided filters */
  taskRequests: TaskRequestsResult;
  /** Return the current runner's team */
  team: Team;
  /** Trial visit by id */
  testPairing: TestPairing;
  /** Test pairings for current runner */
  testPairings: TestPairingsResult;
  /** Find a training by its slug */
  training: Training;
};

export type QueryAreaArgs = {
  slug: Scalars["String"];
};

export type QueryAutocompleteAddressArgs = {
  query: Scalars["String"];
};

export type QueryAutocompleteBeneficiaryArgs = {
  query: Scalars["String"];
};

export type QueryAutocompleteRunnerArgs = {
  query: Scalars["String"];
};

export type QueryAutocompleteSessionArgs = {
  query: Scalars["String"];
};

export type QueryAutocompleteTaskRequestArgs = {
  query: Scalars["String"];
};

export type QueryAvailableCoachArgs = {
  id: Scalars["ID"];
};

export type QueryAvailableCoachesArgs = {
  areaIds?: Maybe<Array<Scalars["ID"]>>;
  limit?: Maybe<Scalars["Int"]>;
  maxDistance?: Maybe<Scalars["Int"]>;
  page?: Scalars["Int"];
  postcode?: Maybe<Scalars["String"]>;
};

export type QueryDeletedStoriesArgs = {
  before?: Maybe<Scalars["ISO8601DateTime"]>;
};

export type QueryDraftedStoriesArgs = {
  before?: Maybe<Scalars["ISO8601DateTime"]>;
};

export type QueryNewsfeedArgs = {
  area?: Maybe<Scalars["String"]>;
  page?: Maybe<Scalars["Int"]>;
  runnerId?: Maybe<Scalars["ID"]>;
  type?: Maybe<NewsfeedType>;
};

export type QueryOrganisationArgs = {
  slug: Scalars["String"];
};

export type QueryReportArgs = {
  slug: Scalars["String"];
};

export type QueryReportsArgs = {
  areaId?: Maybe<Scalars["ID"]>;
  before?: Maybe<Scalars["ISO8601DateTime"]>;
  organisationId?: Maybe<Scalars["ID"]>;
  runnerId?: Maybe<Scalars["ID"]>;
  sessionType?: Maybe<Scalars["String"]>;
};

export type QueryRunnerArgs = {
  id: Scalars["ID"];
};

export type QuerySessionArgs = {
  slug: Scalars["String"];
};

export type QuerySessionAdminMenuArgs = {
  sessionId: Scalars["ID"];
};

export type QuerySessionsArgs = {
  areaId?: Maybe<Scalars["ID"]>;
  from: Scalars["ISO8601DateTime"];
  page?: Maybe<Scalars["Int"]>;
  postcode?: Maybe<Scalars["String"]>;
  runnerId?: Maybe<Scalars["ID"]>;
  to: Scalars["ISO8601DateTime"];
};

export type QuerySessionsV2Args = {
  areaIds?: Maybe<Array<Scalars["ID"]>>;
  cursorDate?: Maybe<Scalars["ISO8601DateTime"]>;
  cursorId?: Maybe<Scalars["ID"]>;
  from: Scalars["ISO8601DateTime"];
  limit?: Maybe<Scalars["Int"]>;
  maxDistance?: Maybe<Scalars["Int"]>;
  postcode?: Maybe<Scalars["String"]>;
  types?: Maybe<Array<Scalars["String"]>>;
};

export type QueryStoriesArgs = {
  before?: Maybe<Scalars["ISO8601DateTime"]>;
};

export type QueryStoryArgs = {
  slug: Scalars["String"];
};

export type QueryTaskRequestsArgs = {
  areaIds?: Maybe<Array<Scalars["ID"]>>;
  cursorDate?: Maybe<Scalars["ISO8601DateTime"]>;
  cursorId?: Maybe<Scalars["ID"]>;
  from: Scalars["ISO8601DateTime"];
  limit?: Maybe<Scalars["Int"]>;
  maxDistance?: Maybe<Scalars["Int"]>;
  postcode?: Maybe<Scalars["String"]>;
  types?: Maybe<Array<Scalars["String"]>>;
};

export type QueryTestPairingArgs = {
  id: Scalars["ID"];
};

export type QueryTestPairingsArgs = {
  limit?: Maybe<Scalars["Int"]>;
  page?: Scalars["Int"];
};

export type QueryTrainingArgs = {
  slug: Scalars["String"];
};

export type Referrer = {
  __typename?: "Referrer";
  id: Scalars["ID"];
  name: Scalars["String"];
  user: User;
};

/** A published session report */
export type Report = Commentable & {
  __typename?: "Report";
  comments: Array<Comment>;
  coverImageUrl: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  description: Scalars["String"];
  id: Scalars["ID"];
  imageUrl: Scalars["String"];
  pictures: Array<Scalars["String"]>;
  publishedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  session: Session;
  slug: Scalars["String"];
  title: Scalars["String"];
  writtenBy?: Maybe<Runner>;
};

/** A result of a query for reports */
export type ReportsResult = {
  __typename?: "ReportsResult";
  areaId?: Maybe<Scalars["ID"]>;
  before?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  more: Scalars["Boolean"];
  reports: Array<Report>;
};

/** Autogenerated return type of RequestTestPairingMutation. */
export type RequestTestPairingMutationPayload = {
  __typename?: "RequestTestPairingMutationPayload";
  errors?: Maybe<Array<Scalars["String"]>>;
  testPairing?: Maybe<TestPairing>;
};

export type RestrictedBeneficiary = {
  __typename?: "RestrictedBeneficiary";
  address?: Maybe<Address>;
  area?: Maybe<Area>;
  dateOfBirth?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
  slug: Scalars["String"];
};

/** Is there a known risk associated with the task */
export type Risky = "no" | "unknown" | "yes";

export type Runner = {
  __typename?: "Runner";
  /** @deprecated Replaced by home_area */
  area: Area;
  avatarUrl: Scalars["String"];
  awards: Array<Award>;
  bio: Scalars["String"];
  blockedByYou: Scalars["Boolean"];
  employment?: Maybe<Employment>;
  followedByYou: Scalars["Boolean"];
  followsYou: Scalars["Boolean"];
  homeArea?: Maybe<Area>;
  id: Scalars["ID"];
  instagram?: Maybe<Scalars["String"]>;
  isMember: Scalars["Boolean"];
  joinedAt: Scalars["ISO8601DateTime"];
  name: Scalars["String"];
  neverRun: Scalars["Boolean"];
  pronouns?: Maybe<Scalars["String"]>;
  roles: Array<Scalars["String"]>;
  slug: Scalars["String"];
  stats: RunnerStats;
  streaks: Array<RunnerStreak>;
  twitter?: Maybe<Scalars["String"]>;
  upcomingSessions: Array<Session>;
  user: User;
  verified: Scalars["Boolean"];
};

export type RunnerUpcomingSessionsArgs = {
  limit?: Maybe<Scalars["Int"]>;
};

export type RunnerStats = {
  __typename?: "RunnerStats";
  cheersGiven: Scalars["Int"];
  cheersReceived: Scalars["Int"];
  goodDeeds: Scalars["Int"];
  id: Scalars["ID"];
  reportsWritten: Scalars["Int"];
  runs: Scalars["Int"];
  sessionsBackmarked: Scalars["Int"];
  sessionsLed: Scalars["Int"];
  sessionsListed: Scalars["Int"];
  sessionsPhotographed: Scalars["Int"];
  walksLed: Scalars["Int"];
};

/** Runner's streaks. */
export type RunnerStreak = {
  __typename?: "RunnerStreak";
  count: Scalars["Int"];
  current: Scalars["Boolean"];
  description: Scalars["String"];
  id: Scalars["ID"];
  imageUrl: Scalars["String"];
  status: Scalars["String"];
  title: Scalars["String"];
  verified: Scalars["Boolean"];
};

export type RunnerTrainingStatus = {
  __typename?: "RunnerTrainingStatus";
  id: Scalars["ID"];
  slug: Scalars["String"];
  status: TrainingStatus;
};

/** A session */
export type Session = Commentable & {
  __typename?: "Session";
  address?: Maybe<Address>;
  area?: Maybe<Area>;
  attendees: Array<Runner>;
  attributes: Array<SessionAttribute>;
  cancelledAt?: Maybe<Scalars["ISO8601DateTime"]>;
  comments: Array<Comment>;
  coverImageUrl: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  description: Scalars["String"];
  /** @deprecated No longer used */
  distanceAway?: Maybe<Scalars["Int"]>;
  duration?: Maybe<Scalars["Int"]>;
  groupFitnessActivities: Array<GroupFitnessActivity>;
  id: Scalars["ID"];
  imageUrl: Scalars["String"];
  imageUrls: Array<Scalars["String"]>;
  ledBy?: Maybe<Runner>;
  maxDistance?: Maybe<Scalars["Int"]>;
  minDistance?: Maybe<Scalars["Int"]>;
  onWaitingList: Scalars["Boolean"];
  organisation?: Maybe<Organisation>;
  registerDeadline?: Maybe<Scalars["ISO8601DateTime"]>;
  registerMax?: Maybe<Scalars["Int"]>;
  report?: Maybe<Report>;
  risks: Array<SessionRisk>;
  signedUp: Scalars["Boolean"];
  signups: Array<Runner>;
  slug: Scalars["String"];
  startedAt: Scalars["ISO8601DateTime"];
  strapline: Scalars["String"];
  taskRequest?: Maybe<TaskRequest>;
  taskforceRoles: Array<TaskforceRole>;
  title: Scalars["String"];
  type: Scalars["String"];
  walkable: Scalars["Boolean"];
};

/** A session */
export type SessionImageUrlsArgs = {
  size?: Maybe<ImageSize>;
};

/** Attributes of a GoodGym session */
export type SessionAttribute =
  /** This session includes a task to support someone self-isolating due to COVID-19 */
  | "COVID"
  /** This session requires a DBS check */
  | "DBS"
  /** This session includes led fitness training */
  | "FitnessSession"
  /** This session includes a Good Deed which counts towards your streak */
  | "GoodDeed"
  /** This session includes a task to support an isolated older person */
  | "OlderPerson"
  /** This is a social session */
  | "Social"
  /** This is a virtual session */
  | "Virtual";

/** A potential risk of taking part in a session */
export type SessionRisk = {
  __typename?: "SessionRisk";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  imageUrl: Scalars["String"];
  name: Scalars["String"];
};

/** A result of a search for sessions */
export type SessionsResult = {
  __typename?: "SessionsResult";
  areaId?: Maybe<Scalars["ID"]>;
  from: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  maxDistance: Scalars["Int"];
  more: Scalars["Boolean"];
  postcode?: Maybe<Scalars["String"]>;
  runnerId?: Maybe<Scalars["ID"]>;
  sessions: Array<Session>;
  taskRequests: Array<TaskRequest>;
  to: Scalars["ISO8601DateTime"];
};

/** A result of a search for sessions */
export type SessionsResultV2 = {
  __typename?: "SessionsResultV2";
  more: Scalars["Boolean"];
  results: Array<Session>;
};

/** Autogenerated return type of SignUpToSessionMutation. */
export type SignUpToSessionMutationPayload = {
  __typename?: "SignUpToSessionMutationPayload";
  session: Session;
};

export type Stats = {
  __typename?: "Stats";
  areas: Scalars["Int"];
  beneficiaries: Scalars["Int"];
  goodgymers: Scalars["Int"];
  id: Scalars["ID"];
  tasks: Scalars["Int"];
};

/** Result for a query of stories */
export type StoriesResult = {
  __typename?: "StoriesResult";
  before?: Maybe<Scalars["ISO8601DateTime"]>;
  more: Scalars["Boolean"];
  stories: Array<Story>;
};

/** A story */
export type Story = {
  __typename?: "Story";
  area?: Maybe<Area>;
  author: Runner;
  body: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  imageUrl: Scalars["String"];
  publishedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  slug: Scalars["String"];
  strapline: Scalars["String"];
  title: Scalars["String"];
};

export type TaskRequest = {
  __typename?: "TaskRequest";
  area: Area;
  beneficiary: Beneficiary;
  createdAt: Scalars["ISO8601DateTime"];
  deadline: Scalars["ISO8601DateTime"];
  declined: Scalars["Boolean"];
  description: Scalars["String"];
  id: Scalars["ID"];
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
  outcome: Scalars["String"];
  potentialTimes: Array<Scalars["ISO8601DateTime"]>;
  registerMax: Scalars["Int"];
  registerMin: Scalars["Int"];
  reservations: Array<TaskRequestReservation>;
  risky: Risky;
  signedUp: Scalars["Boolean"];
  slug: Scalars["String"];
  title: Scalars["String"];
};

export type TaskRequestReservation = {
  __typename?: "TaskRequestReservation";
  id: Scalars["ID"];
  runners: Array<Runner>;
  startedAt: Scalars["ISO8601DateTime"];
};

/** A result of a search for task requests */
export type TaskRequestsResult = {
  __typename?: "TaskRequestsResult";
  more: Scalars["Boolean"];
  results: Array<TaskRequest>;
};

/** A role a TaskForce member can take at a session */
export type TaskforceRole = {
  __typename?: "TaskforceRole";
  description: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  runner?: Maybe<Runner>;
  slug: Scalars["String"];
};

export type Team = {
  __typename?: "Team";
  suggestions: Array<Runner>;
  supporters: Array<Runner>;
  supportings: Array<Runner>;
};

export type TestPairing = {
  __typename?: "TestPairing";
  acceptedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  cancellationNotes?: Maybe<Scalars["String"]>;
  cancelledAt?: Maybe<Scalars["ISO8601DateTime"]>;
  coach: Coach;
  completedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  notesForRunner?: Maybe<Scalars["String"]>;
  rejectedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  rejectionNotes?: Maybe<Scalars["String"]>;
  runnerVisitFeedback?: Maybe<Scalars["String"]>;
  runnerVisitNotes?: Maybe<Scalars["String"]>;
  runnerWantsPairing?: Maybe<Scalars["Boolean"]>;
  scheduledAt?: Maybe<Scalars["ISO8601DateTime"]>;
};

/** A result of a test pairings for runner query */
export type TestPairingsResult = {
  __typename?: "TestPairingsResult";
  more: Scalars["Boolean"];
  results: Array<TestPairing>;
};

export type Training = {
  __typename?: "Training";
  description: Scalars["String"];
  id: Scalars["ID"];
  modules: Array<TrainingModule>;
  name: Scalars["String"];
  slug: Scalars["String"];
};

/** Attributes for submitting an answer to the online training */
export type TrainingAnswerInput = {
  answer: Scalars["String"];
  moduleId: Scalars["ID"];
  questionId: Scalars["ID"];
};

export type TrainingModule = {
  __typename?: "TrainingModule";
  description: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  order: Scalars["Int"];
  questions: Array<TrainingModuleQuestion>;
  sections: Array<TrainingModuleSection>;
};

export type TrainingModuleQuestion = {
  __typename?: "TrainingModuleQuestion";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  order: Scalars["Int"];
  question: Scalars["String"];
  type: Scalars["String"];
};

export type TrainingModuleSection = {
  __typename?: "TrainingModuleSection";
  description: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  order: Scalars["Int"];
};

/** What stage is a runner at in their training */
export type TrainingStatus =
  /** The training has been completed, but not reviewed by the central team. NB. Only older people related training requires such a review. */
  | "COMPLETED"
  /** The training has been completed and reviewed by the centrail team, who have marked it a 'fail'. */
  | "FAILED"
  /** The training is in progress. */
  | "INCOMPLETE"
  /** The training has been completed and reviewed by the centrail team, who have marked it a 'pass'. */
  | "PASSED";

/** Autogenerated return type of UnSignUpFromSessionMutation. */
export type UnSignUpFromSessionMutationPayload = {
  __typename?: "UnSignUpFromSessionMutationPayload";
  session: Session;
};

/** Autogenerated return type of UnblockMutation. */
export type UnblockMutationPayload = {
  __typename?: "UnblockMutationPayload";
  runners: Array<Runner>;
};

/** Autogenerated return type of UncheerMutation. */
export type UncheerMutationPayload = {
  __typename?: "UncheerMutationPayload";
  cheerable: Cheerable;
  cheers: Array<Cheer>;
};

/** Autogenerated return type of UndeclineTaskRequestMutation. */
export type UndeclineTaskRequestMutationPayload = {
  __typename?: "UndeclineTaskRequestMutationPayload";
  taskRequest: TaskRequest;
};

/** Autogenerated return type of UnfollowMutation. */
export type UnfollowMutationPayload = {
  __typename?: "UnfollowMutationPayload";
  runners: Array<Runner>;
};

/** Autogenerated return type of UnpublishReportMutation. */
export type UnpublishReportMutationPayload = {
  __typename?: "UnpublishReportMutationPayload";
  errors: Array<Scalars["String"]>;
  report: Report;
};

/** Autogenerated return type of UpdateAreaMutation. */
export type UpdateAreaMutationPayload = {
  __typename?: "UpdateAreaMutationPayload";
  area: Area;
};

/** Autogenerated return type of UpdateOrganisationMutation. */
export type UpdateOrganisationMutationPayload = {
  __typename?: "UpdateOrganisationMutationPayload";
  organisation: Organisation;
};

export type User = {
  __typename?: "User";
  email: Scalars["String"];
  id: Scalars["ID"];
  referrer?: Maybe<Referrer>;
  runner?: Maybe<Runner>;
};

export type AuthQueryVariables = Exact<{ [key: string]: never }>;

export type AuthQuery = {
  __typename?: "Query";
  user?: { __typename?: "User"; id: string; email: string } | null | undefined;
  runner?:
    | {
        __typename?: "CurrentRunner";
        needsToGetMissionVerified: boolean;
        needsToGetCoachVerified: boolean;
        id: string;
        name: string;
        bio?: string | null | undefined;
        postcode?: string | null | undefined;
        slug: string;
        pronouns?: string | null | undefined;
        avatarUrl: string;
        roles: Array<string>;
        isMember: boolean;
        neverRun: boolean;
        verified: boolean;
        featureFlags: Array<string>;
        donation?:
          | { __typename?: "Donation"; id: string; giftaid: boolean }
          | null
          | undefined;
        pairing?:
          | {
              __typename?: "Pairing";
              id: string;
              coach: { __typename?: "Coach"; id: string; name: string };
            }
          | null
          | undefined;
        employment?:
          | {
              __typename?: "Employment";
              id: string;
              startedAt: any;
              employer: {
                __typename?: "Employer";
                id: string;
                name: string;
                imageUrl: string;
                slug: string;
              };
            }
          | null
          | undefined;
        homeArea?:
          | {
              __typename?: "Area";
              socialVisits: boolean;
              pairingVerifiedRunnersCount: number;
              id: string;
              name: string;
              slug: string;
            }
          | null
          | undefined;
        areas: Array<{
          __typename?: "Area";
          id: string;
          name: string;
          slug: string;
          socialVisits: boolean;
        }>;
      }
    | null
    | undefined;
  referrer?:
    | { __typename?: "Referrer"; id: string; name: string }
    | null
    | undefined;
};

export type AddressSearchQueryVariables = Exact<{
  query: Scalars["String"];
}>;

export type AddressSearchQuery = {
  __typename?: "Query";
  results: Array<{
    __typename?: "AddressSearchResult";
    id: string;
    postcode: string;
    line1: string;
    line2?: string | null | undefined;
    city: string;
    county: string;
    country: string;
    area?:
      | {
          __typename?: "Area";
          id: string;
          name: string;
          active: boolean;
          slug: string;
          socialVisits: boolean;
        }
      | null
      | undefined;
  }>;
};

export type AreaSearchQueryVariables = Exact<{ [key: string]: never }>;

export type AreaSearchQuery = {
  __typename?: "Query";
  areas: Array<{
    __typename?: "Area";
    id: string;
    name: string;
    slug: string;
    socialVisits: boolean;
  }>;
};

export type BeneficiarySearchQueryVariables = Exact<{
  query: Scalars["String"];
}>;

export type BeneficiarySearchQuery = {
  __typename?: "Query";
  results: Array<{
    __typename?: "RestrictedBeneficiary";
    id: string;
    slug: string;
    name: string;
    phoneNumber?: string | null | undefined;
    dateOfBirth?: any | null | undefined;
    address?:
      | {
          __typename?: "Address";
          id: string;
          line1: string;
          line2?: string | null | undefined;
          city: string;
          postcode: string;
          lat?: number | null | undefined;
          lng?: number | null | undefined;
        }
      | null
      | undefined;
    area?:
      | {
          __typename?: "Area";
          id: string;
          name: string;
          slug: string;
          socialVisits: boolean;
        }
      | null
      | undefined;
  }>;
};

export type NotificationsQueryVariables = Exact<{ [key: string]: never }>;

export type NotificationsQuery = {
  __typename?: "Query";
  runner?:
    | {
        __typename?: "CurrentRunner";
        id: string;
        notifications: Array<{
          __typename?: "Notification";
          id: string;
          content: string;
          url: string;
          imageUrl: string;
          read: boolean;
          createdAt: any;
        }>;
      }
    | null
    | undefined;
};

export type NewsfeedFragment = {
  __typename?: "Newsfeed";
  id: string;
  page: number;
  limit: number;
  more: boolean;
  items: Array<
    | {
        __typename: "NewsfeedAttendance";
        id: string;
        time: any;
        session: {
          __typename?: "Session";
          id: string;
          slug: string;
          title: string;
          strapline: string;
          registerMax?: number | null | undefined;
          type: string;
          imageUrl: string;
          coverImageUrl: string;
          startedAt: any;
          duration?: number | null | undefined;
          area?:
            | {
                __typename?: "Area";
                id: string;
                name: string;
                slug: string;
                socialVisits: boolean;
              }
            | null
            | undefined;
          report?:
            | {
                __typename?: "Report";
                id: string;
                slug: string;
                title: string;
                description: string;
                imageUrl: string;
                coverImageUrl: string;
                publishedAt?: any | null | undefined;
                pictures: Array<string>;
                writtenBy?:
                  | {
                      __typename?: "Runner";
                      id: string;
                      name: string;
                      slug: string;
                      avatarUrl: string;
                      isMember: boolean;
                      neverRun: boolean;
                      verified: boolean;
                      pronouns?: string | null | undefined;
                      roles: Array<string>;
                    }
                  | null
                  | undefined;
                comments: Array<{
                  __typename?: "Comment";
                  id: string;
                  content: string;
                  createdAt: any;
                  runner: {
                    __typename?: "Runner";
                    id: string;
                    name: string;
                    slug: string;
                    avatarUrl: string;
                    isMember: boolean;
                    neverRun: boolean;
                    verified: boolean;
                    pronouns?: string | null | undefined;
                    roles: Array<string>;
                  };
                }>;
              }
            | null
            | undefined;
          comments: Array<{
            __typename?: "Comment";
            id: string;
            content: string;
            createdAt: any;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        };
        runners: Array<{
          __typename?: "Runner";
          id: string;
          name: string;
          slug: string;
          avatarUrl: string;
          isMember: boolean;
          neverRun: boolean;
          verified: boolean;
          pronouns?: string | null | undefined;
          roles: Array<string>;
        }>;
        cheers: Array<{
          __typename?: "Cheer";
          id: string;
          runner: {
            __typename?: "Runner";
            id: string;
            name: string;
            slug: string;
            avatarUrl: string;
            isMember: boolean;
            neverRun: boolean;
            verified: boolean;
            pronouns?: string | null | undefined;
            roles: Array<string>;
          };
          recipient: {
            __typename?: "Runner";
            id: string;
            name: string;
            slug: string;
            avatarUrl: string;
            isMember: boolean;
            neverRun: boolean;
            verified: boolean;
            pronouns?: string | null | undefined;
            roles: Array<string>;
          };
        }>;
      }
    | {
        __typename: "NewsfeedAward";
        id: string;
        time: any;
        description: string;
        badge: {
          __typename?: "Badge";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
        };
        runner: {
          __typename?: "Runner";
          id: string;
          name: string;
          slug: string;
          avatarUrl: string;
          isMember: boolean;
          neverRun: boolean;
          verified: boolean;
          pronouns?: string | null | undefined;
          roles: Array<string>;
        };
        cheers: Array<{
          __typename?: "Cheer";
          id: string;
          runner: {
            __typename?: "Runner";
            id: string;
            name: string;
            slug: string;
            avatarUrl: string;
            isMember: boolean;
            neverRun: boolean;
            verified: boolean;
            pronouns?: string | null | undefined;
            roles: Array<string>;
          };
          recipient: {
            __typename?: "Runner";
            id: string;
            name: string;
            slug: string;
            avatarUrl: string;
            isMember: boolean;
            neverRun: boolean;
            verified: boolean;
            pronouns?: string | null | undefined;
            roles: Array<string>;
          };
        }>;
        comments: Array<{
          __typename?: "Comment";
          id: string;
          content: string;
          createdAt: any;
          runner: {
            __typename?: "Runner";
            id: string;
            name: string;
            slug: string;
            avatarUrl: string;
            isMember: boolean;
            neverRun: boolean;
            verified: boolean;
            pronouns?: string | null | undefined;
            roles: Array<string>;
          };
        }>;
      }
    | {
        __typename: "NewsfeedCoachVisit";
        id: string;
        time: any;
        runner: {
          __typename?: "Runner";
          id: string;
          name: string;
          slug: string;
          avatarUrl: string;
          isMember: boolean;
          neverRun: boolean;
          verified: boolean;
          pronouns?: string | null | undefined;
          roles: Array<string>;
        };
        cheers: Array<{
          __typename?: "Cheer";
          id: string;
          runner: {
            __typename?: "Runner";
            id: string;
            name: string;
            slug: string;
            avatarUrl: string;
            isMember: boolean;
            neverRun: boolean;
            verified: boolean;
            pronouns?: string | null | undefined;
            roles: Array<string>;
          };
          recipient: {
            __typename?: "Runner";
            id: string;
            name: string;
            slug: string;
            avatarUrl: string;
            isMember: boolean;
            neverRun: boolean;
            verified: boolean;
            pronouns?: string | null | undefined;
            roles: Array<string>;
          };
        }>;
        comments: Array<{
          __typename?: "Comment";
          id: string;
          content: string;
          createdAt: any;
          runner: {
            __typename?: "Runner";
            id: string;
            name: string;
            slug: string;
            avatarUrl: string;
            isMember: boolean;
            neverRun: boolean;
            verified: boolean;
            pronouns?: string | null | undefined;
            roles: Array<string>;
          };
        }>;
      }
    | {
        __typename: "NewsfeedRegistration";
        id: string;
        time: any;
        session: {
          __typename?: "Session";
          registerMax?: number | null | undefined;
          id: string;
          slug: string;
          title: string;
          strapline: string;
          type: string;
          imageUrl: string;
          coverImageUrl: string;
          startedAt: any;
          duration?: number | null | undefined;
          signups: Array<{
            __typename?: "Runner";
            id: string;
            name: string;
            slug: string;
            avatarUrl: string;
            isMember: boolean;
            neverRun: boolean;
            verified: boolean;
            pronouns?: string | null | undefined;
            roles: Array<string>;
          }>;
          address?:
            | {
                __typename?: "Address";
                id: string;
                line1: string;
                line2?: string | null | undefined;
                city: string;
                postcode: string;
                lat?: number | null | undefined;
                lng?: number | null | undefined;
              }
            | null
            | undefined;
          area?:
            | {
                __typename?: "Area";
                id: string;
                name: string;
                slug: string;
                socialVisits: boolean;
              }
            | null
            | undefined;
          comments: Array<{
            __typename?: "Comment";
            id: string;
            content: string;
            createdAt: any;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        };
        runners: Array<{
          __typename?: "Runner";
          id: string;
          name: string;
          slug: string;
          avatarUrl: string;
          isMember: boolean;
          neverRun: boolean;
          verified: boolean;
          pronouns?: string | null | undefined;
          roles: Array<string>;
        }>;
        cheers: Array<{
          __typename?: "Cheer";
          id: string;
          runner: {
            __typename?: "Runner";
            id: string;
            name: string;
            slug: string;
            avatarUrl: string;
            isMember: boolean;
            neverRun: boolean;
            verified: boolean;
            pronouns?: string | null | undefined;
            roles: Array<string>;
          };
          recipient: {
            __typename?: "Runner";
            id: string;
            name: string;
            slug: string;
            avatarUrl: string;
            isMember: boolean;
            neverRun: boolean;
            verified: boolean;
            pronouns?: string | null | undefined;
            roles: Array<string>;
          };
        }>;
      }
    | {
        __typename: "NewsfeedWorkout";
        id: string;
        name: string;
        time: any;
        type: string;
        distance: number;
        duration: number;
        uri?: string | null | undefined;
        provider?: string | null | undefined;
        averagePace: string;
        runner: {
          __typename?: "Runner";
          id: string;
          name: string;
          slug: string;
          avatarUrl: string;
          isMember: boolean;
          neverRun: boolean;
          verified: boolean;
          pronouns?: string | null | undefined;
          roles: Array<string>;
        };
        cheers: Array<{
          __typename?: "Cheer";
          id: string;
          runner: {
            __typename?: "Runner";
            id: string;
            name: string;
            slug: string;
            avatarUrl: string;
            isMember: boolean;
            neverRun: boolean;
            verified: boolean;
            pronouns?: string | null | undefined;
            roles: Array<string>;
          };
          recipient: {
            __typename?: "Runner";
            id: string;
            name: string;
            slug: string;
            avatarUrl: string;
            isMember: boolean;
            neverRun: boolean;
            verified: boolean;
            pronouns?: string | null | undefined;
            roles: Array<string>;
          };
        }>;
        comments: Array<{
          __typename?: "Comment";
          id: string;
          content: string;
          createdAt: any;
          runner: {
            __typename?: "Runner";
            id: string;
            name: string;
            slug: string;
            avatarUrl: string;
            isMember: boolean;
            neverRun: boolean;
            verified: boolean;
            pronouns?: string | null | undefined;
            roles: Array<string>;
          };
        }>;
      }
  >;
};

export type NewsfeedQueryVariables = Exact<{
  type: NewsfeedType;
  runnerId?: Maybe<Scalars["ID"]>;
  page?: Maybe<Scalars["Int"]>;
}>;

export type NewsfeedQuery = {
  __typename?: "Query";
  newsfeed: {
    __typename?: "Newsfeed";
    id: string;
    page: number;
    limit: number;
    more: boolean;
    items: Array<
      | {
          __typename: "NewsfeedAttendance";
          id: string;
          time: any;
          session: {
            __typename?: "Session";
            id: string;
            slug: string;
            title: string;
            strapline: string;
            registerMax?: number | null | undefined;
            type: string;
            imageUrl: string;
            coverImageUrl: string;
            startedAt: any;
            duration?: number | null | undefined;
            area?:
              | {
                  __typename?: "Area";
                  id: string;
                  name: string;
                  slug: string;
                  socialVisits: boolean;
                }
              | null
              | undefined;
            report?:
              | {
                  __typename?: "Report";
                  id: string;
                  slug: string;
                  title: string;
                  description: string;
                  imageUrl: string;
                  coverImageUrl: string;
                  publishedAt?: any | null | undefined;
                  pictures: Array<string>;
                  writtenBy?:
                    | {
                        __typename?: "Runner";
                        id: string;
                        name: string;
                        slug: string;
                        avatarUrl: string;
                        isMember: boolean;
                        neverRun: boolean;
                        verified: boolean;
                        pronouns?: string | null | undefined;
                        roles: Array<string>;
                      }
                    | null
                    | undefined;
                  comments: Array<{
                    __typename?: "Comment";
                    id: string;
                    content: string;
                    createdAt: any;
                    runner: {
                      __typename?: "Runner";
                      id: string;
                      name: string;
                      slug: string;
                      avatarUrl: string;
                      isMember: boolean;
                      neverRun: boolean;
                      verified: boolean;
                      pronouns?: string | null | undefined;
                      roles: Array<string>;
                    };
                  }>;
                }
              | null
              | undefined;
            comments: Array<{
              __typename?: "Comment";
              id: string;
              content: string;
              createdAt: any;
              runner: {
                __typename?: "Runner";
                id: string;
                name: string;
                slug: string;
                avatarUrl: string;
                isMember: boolean;
                neverRun: boolean;
                verified: boolean;
                pronouns?: string | null | undefined;
                roles: Array<string>;
              };
            }>;
          };
          runners: Array<{
            __typename?: "Runner";
            id: string;
            name: string;
            slug: string;
            avatarUrl: string;
            isMember: boolean;
            neverRun: boolean;
            verified: boolean;
            pronouns?: string | null | undefined;
            roles: Array<string>;
          }>;
          cheers: Array<{
            __typename?: "Cheer";
            id: string;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
            recipient: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | {
          __typename: "NewsfeedAward";
          id: string;
          time: any;
          description: string;
          badge: {
            __typename?: "Badge";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
          };
          runner: {
            __typename?: "Runner";
            id: string;
            name: string;
            slug: string;
            avatarUrl: string;
            isMember: boolean;
            neverRun: boolean;
            verified: boolean;
            pronouns?: string | null | undefined;
            roles: Array<string>;
          };
          cheers: Array<{
            __typename?: "Cheer";
            id: string;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
            recipient: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
          comments: Array<{
            __typename?: "Comment";
            id: string;
            content: string;
            createdAt: any;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | {
          __typename: "NewsfeedCoachVisit";
          id: string;
          time: any;
          runner: {
            __typename?: "Runner";
            id: string;
            name: string;
            slug: string;
            avatarUrl: string;
            isMember: boolean;
            neverRun: boolean;
            verified: boolean;
            pronouns?: string | null | undefined;
            roles: Array<string>;
          };
          cheers: Array<{
            __typename?: "Cheer";
            id: string;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
            recipient: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
          comments: Array<{
            __typename?: "Comment";
            id: string;
            content: string;
            createdAt: any;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | {
          __typename: "NewsfeedRegistration";
          id: string;
          time: any;
          session: {
            __typename?: "Session";
            registerMax?: number | null | undefined;
            id: string;
            slug: string;
            title: string;
            strapline: string;
            type: string;
            imageUrl: string;
            coverImageUrl: string;
            startedAt: any;
            duration?: number | null | undefined;
            signups: Array<{
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            }>;
            address?:
              | {
                  __typename?: "Address";
                  id: string;
                  line1: string;
                  line2?: string | null | undefined;
                  city: string;
                  postcode: string;
                  lat?: number | null | undefined;
                  lng?: number | null | undefined;
                }
              | null
              | undefined;
            area?:
              | {
                  __typename?: "Area";
                  id: string;
                  name: string;
                  slug: string;
                  socialVisits: boolean;
                }
              | null
              | undefined;
            comments: Array<{
              __typename?: "Comment";
              id: string;
              content: string;
              createdAt: any;
              runner: {
                __typename?: "Runner";
                id: string;
                name: string;
                slug: string;
                avatarUrl: string;
                isMember: boolean;
                neverRun: boolean;
                verified: boolean;
                pronouns?: string | null | undefined;
                roles: Array<string>;
              };
            }>;
          };
          runners: Array<{
            __typename?: "Runner";
            id: string;
            name: string;
            slug: string;
            avatarUrl: string;
            isMember: boolean;
            neverRun: boolean;
            verified: boolean;
            pronouns?: string | null | undefined;
            roles: Array<string>;
          }>;
          cheers: Array<{
            __typename?: "Cheer";
            id: string;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
            recipient: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | {
          __typename: "NewsfeedWorkout";
          id: string;
          name: string;
          time: any;
          type: string;
          distance: number;
          duration: number;
          uri?: string | null | undefined;
          provider?: string | null | undefined;
          averagePace: string;
          runner: {
            __typename?: "Runner";
            id: string;
            name: string;
            slug: string;
            avatarUrl: string;
            isMember: boolean;
            neverRun: boolean;
            verified: boolean;
            pronouns?: string | null | undefined;
            roles: Array<string>;
          };
          cheers: Array<{
            __typename?: "Cheer";
            id: string;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
            recipient: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
          comments: Array<{
            __typename?: "Comment";
            id: string;
            content: string;
            createdAt: any;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
    >;
  };
};

export type ReportsFeedQueryVariables = Exact<{
  areaId?: Maybe<Scalars["ID"]>;
  organisationId?: Maybe<Scalars["ID"]>;
  before?: Maybe<Scalars["ISO8601DateTime"]>;
  runnerId?: Maybe<Scalars["ID"]>;
  sessionType?: Maybe<Scalars["String"]>;
}>;

export type ReportsFeedQuery = {
  __typename?: "Query";
  results: {
    __typename?: "ReportsResult";
    before?: any | null | undefined;
    more: boolean;
    areaId?: string | null | undefined;
    reports: Array<{
      __typename?: "Report";
      id: string;
      slug: string;
      title: string;
      description: string;
      imageUrl: string;
      coverImageUrl: string;
      publishedAt?: any | null | undefined;
      pictures: Array<string>;
      session: {
        __typename?: "Session";
        id: string;
        slug: string;
        title: string;
        strapline: string;
        registerMax?: number | null | undefined;
        type: string;
        imageUrl: string;
        coverImageUrl: string;
        startedAt: any;
        duration?: number | null | undefined;
        area?:
          | {
              __typename?: "Area";
              id: string;
              name: string;
              slug: string;
              socialVisits: boolean;
            }
          | null
          | undefined;
        signups: Array<{
          __typename?: "Runner";
          id: string;
          slug: string;
          name: string;
          avatarUrl: string;
          isMember: boolean;
          neverRun: boolean;
          verified: boolean;
          pronouns?: string | null | undefined;
          roles: Array<string>;
        }>;
      };
      writtenBy?:
        | {
            __typename?: "Runner";
            id: string;
            name: string;
            slug: string;
            avatarUrl: string;
            isMember: boolean;
            neverRun: boolean;
            verified: boolean;
            pronouns?: string | null | undefined;
            roles: Array<string>;
          }
        | null
        | undefined;
    }>;
  };
};

export type RunnerSearchQueryVariables = Exact<{
  query: Scalars["String"];
}>;

export type RunnerSearchQuery = {
  __typename?: "Query";
  results: Array<{
    __typename?: "Runner";
    id: string;
    name: string;
    slug: string;
    avatarUrl: string;
    isMember: boolean;
    neverRun: boolean;
    verified: boolean;
    pronouns?: string | null | undefined;
    roles: Array<string>;
  }>;
};

export type RunnerTooltipQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RunnerTooltipQuery = {
  __typename?: "Query";
  runner: {
    __typename?: "Runner";
    id: string;
    name: string;
    slug: string;
    avatarUrl: string;
    isMember: boolean;
    neverRun: boolean;
    verified: boolean;
    pronouns?: string | null | undefined;
    roles: Array<string>;
    followsYou: boolean;
    followedByYou: boolean;
    blockedByYou: boolean;
    homeArea?:
      | {
          __typename?: "Area";
          id: string;
          name: string;
          slug: string;
          socialVisits: boolean;
        }
      | null
      | undefined;
  };
};

export type SessionSearchQueryVariables = Exact<{
  query: Scalars["String"];
}>;

export type SessionSearchQuery = {
  __typename?: "Query";
  results: Array<{
    __typename?: "Session";
    id: string;
    slug: string;
    title: string;
    strapline: string;
    registerMax?: number | null | undefined;
    type: string;
    imageUrl: string;
    coverImageUrl: string;
    startedAt: any;
    duration?: number | null | undefined;
  }>;
};

export type SessionSignupDialogTrainingQueryVariables = Exact<{
  slug: Scalars["String"];
}>;

export type SessionSignupDialogTrainingQuery = {
  __typename?: "Query";
  training: {
    __typename?: "Training";
    id: string;
    slug: string;
    name: string;
    description: string;
    modules: Array<{
      __typename?: "TrainingModule";
      id: string;
      name: string;
      description: string;
      order: number;
      sections: Array<{
        __typename?: "TrainingModuleSection";
        id: string;
        name: string;
        description: string;
        order: number;
      }>;
      questions: Array<{
        __typename?: "TrainingModuleQuestion";
        id: string;
        question: string;
        description?: string | null | undefined;
        type: string;
        order: number;
      }>;
    }>;
  };
};

export type SessionSignupDialogQueryVariables = Exact<{ [key: string]: never }>;

export type SessionSignupDialogQuery = {
  __typename?: "Query";
  runner?:
    | {
        __typename?: "CurrentRunner";
        id: string;
        name: string;
        bio?: string | null | undefined;
        postcode?: string | null | undefined;
        slug: string;
        pronouns?: string | null | undefined;
        avatarUrl: string;
        roles: Array<string>;
        isMember: boolean;
        neverRun: boolean;
        verified: boolean;
        featureFlags: Array<string>;
        needsToGetCoachVerified: boolean;
        employment?:
          | {
              __typename?: "Employment";
              id: string;
              startedAt: any;
              employer: {
                __typename?: "Employer";
                id: string;
                name: string;
                imageUrl: string;
                slug: string;
              };
            }
          | null
          | undefined;
        homeArea?:
          | {
              __typename?: "Area";
              socialVisits: boolean;
              pairingVerifiedRunnersCount: number;
              id: string;
              name: string;
              slug: string;
            }
          | null
          | undefined;
        areas: Array<{
          __typename?: "Area";
          id: string;
          name: string;
          slug: string;
          socialVisits: boolean;
        }>;
        trainingStatuses: Array<{
          __typename?: "RunnerTrainingStatus";
          id: string;
          slug: string;
          status: TrainingStatus;
        }>;
      }
    | null
    | undefined;
};

export type SessionsFeedCalendarQueryVariables = Exact<{
  areaIds: Array<Scalars["ID"]> | Scalars["ID"];
  postcode?: Maybe<Scalars["String"]>;
  maxDistance: Scalars["Int"];
  from: Scalars["ISO8601DateTime"];
  sessionsCursorDate?: Maybe<Scalars["ISO8601DateTime"]>;
  sessionsCursorId?: Maybe<Scalars["ID"]>;
  taskRequestsCursorDate?: Maybe<Scalars["ISO8601DateTime"]>;
  taskRequestsCursorId?: Maybe<Scalars["ID"]>;
  limit?: Maybe<Scalars["Int"]>;
}>;

export type SessionsFeedCalendarQuery = {
  __typename?: "Query";
  runner?:
    | {
        __typename?: "CurrentRunner";
        id: string;
        upcomingSessions: Array<{
          __typename?: "Session";
          id: string;
          startedAt: any;
        }>;
      }
    | null
    | undefined;
  sessions: {
    __typename?: "SessionsResultV2";
    more: boolean;
    results: Array<{
      __typename?: "Session";
      id: string;
      startedAt: any;
      type: string;
      signedUp: boolean;
    }>;
  };
  taskRequests: {
    __typename?: "TaskRequestsResult";
    more: boolean;
    results: Array<{
      __typename?: "TaskRequest";
      id: string;
      declined: boolean;
      potentialTimes: Array<any>;
    }>;
  };
};

export type SessionsFeedQueryVariables = Exact<{
  types: Array<Scalars["String"]> | Scalars["String"];
  areaIds: Array<Scalars["ID"]> | Scalars["ID"];
  postcode?: Maybe<Scalars["String"]>;
  maxDistance: Scalars["Int"];
  from: Scalars["ISO8601DateTime"];
  sessionsCursorDate?: Maybe<Scalars["ISO8601DateTime"]>;
  sessionsCursorId?: Maybe<Scalars["ID"]>;
  taskRequestsCursorDate?: Maybe<Scalars["ISO8601DateTime"]>;
  taskRequestsCursorId?: Maybe<Scalars["ID"]>;
  limit?: Maybe<Scalars["Int"]>;
}>;

export type SessionsFeedQuery = {
  __typename?: "Query";
  sessions: {
    __typename?: "SessionsResultV2";
    more: boolean;
    results: Array<{
      __typename?: "Session";
      signedUp: boolean;
      coverImageUrl: string;
      id: string;
      slug: string;
      title: string;
      strapline: string;
      registerMax?: number | null | undefined;
      type: string;
      imageUrl: string;
      startedAt: any;
      duration?: number | null | undefined;
      address?:
        | {
            __typename?: "Address";
            id: string;
            line1: string;
            line2?: string | null | undefined;
            city: string;
            postcode: string;
            lat?: number | null | undefined;
            lng?: number | null | undefined;
          }
        | null
        | undefined;
      signups: Array<{
        __typename?: "Runner";
        id: string;
        name: string;
        slug: string;
        avatarUrl: string;
        isMember: boolean;
        neverRun: boolean;
        verified: boolean;
        pronouns?: string | null | undefined;
        roles: Array<string>;
      }>;
      area?:
        | {
            __typename?: "Area";
            id: string;
            name: string;
            slug: string;
            socialVisits: boolean;
          }
        | null
        | undefined;
      report?:
        | {
            __typename?: "Report";
            id: string;
            slug: string;
            title: string;
            description: string;
            imageUrl: string;
            coverImageUrl: string;
            publishedAt?: any | null | undefined;
            pictures: Array<string>;
            writtenBy?:
              | {
                  __typename?: "Runner";
                  id: string;
                  name: string;
                  slug: string;
                  avatarUrl: string;
                  isMember: boolean;
                  neverRun: boolean;
                  verified: boolean;
                  pronouns?: string | null | undefined;
                  roles: Array<string>;
                }
              | null
              | undefined;
          }
        | null
        | undefined;
    }>;
  };
  taskRequests: {
    __typename?: "TaskRequestsResult";
    more: boolean;
    results: Array<{
      __typename?: "TaskRequest";
      signedUp: boolean;
      declined: boolean;
      id: string;
      slug: string;
      title: string;
      outcome: string;
      deadline: any;
      registerMin: number;
      registerMax: number;
      lat?: number | null | undefined;
      lng?: number | null | undefined;
      potentialTimes: Array<any>;
      area: {
        __typename?: "Area";
        id: string;
        name: string;
        slug: string;
        socialVisits: boolean;
      };
      reservations: Array<{
        __typename?: "TaskRequestReservation";
        id: string;
        startedAt: any;
        runners: Array<{
          __typename?: "Runner";
          id: string;
          name: string;
          slug: string;
          avatarUrl: string;
          isMember: boolean;
          neverRun: boolean;
          verified: boolean;
          pronouns?: string | null | undefined;
          roles: Array<string>;
        }>;
      }>;
    }>;
  };
};

export type TaskRequestSearchQueryVariables = Exact<{
  query: Scalars["String"];
}>;

export type TaskRequestSearchQuery = {
  __typename?: "Query";
  results: Array<{
    __typename?: "TaskRequest";
    id: string;
    slug: string;
    title: string;
    outcome: string;
    deadline: any;
    registerMin: number;
    registerMax: number;
    lat?: number | null | undefined;
    lng?: number | null | undefined;
    potentialTimes: Array<any>;
  }>;
};

export type AddressFragment = {
  __typename?: "Address";
  id: string;
  line1: string;
  line2?: string | null | undefined;
  city: string;
  postcode: string;
  lat?: number | null | undefined;
  lng?: number | null | undefined;
};

export type AddressSearchResultFragment = {
  __typename?: "AddressSearchResult";
  id: string;
  postcode: string;
  line1: string;
  line2?: string | null | undefined;
  city: string;
  county: string;
  country: string;
  area?:
    | {
        __typename?: "Area";
        id: string;
        name: string;
        active: boolean;
        slug: string;
        socialVisits: boolean;
      }
    | null
    | undefined;
};

export type AreaFragment = {
  __typename?: "Area";
  id: string;
  name: string;
  slug: string;
  socialVisits: boolean;
};

export type BadgeFragment = {
  __typename?: "Badge";
  id: string;
  name: string;
  description: string;
  imageUrl: string;
};

export type BeneficiaryFragment = {
  __typename?: "Beneficiary";
  id: string;
  name: string;
};

export type CtaFragment = {
  __typename?: "CTA";
  id: string;
  title: string;
  description: string;
  icon: string;
  url: string;
  actionText: string;
  dismissText?: string | null | undefined;
};

export type CheerFragment = {
  __typename?: "Cheer";
  id: string;
  runner: {
    __typename?: "Runner";
    id: string;
    name: string;
    slug: string;
    avatarUrl: string;
    isMember: boolean;
    neverRun: boolean;
    verified: boolean;
    pronouns?: string | null | undefined;
    roles: Array<string>;
  };
  recipient: {
    __typename?: "Runner";
    id: string;
    name: string;
    slug: string;
    avatarUrl: string;
    isMember: boolean;
    neverRun: boolean;
    verified: boolean;
    pronouns?: string | null | undefined;
    roles: Array<string>;
  };
};

type Cheerable_NewsfeedAttendance_Fragment = {
  __typename?: "NewsfeedAttendance";
  id: string;
  cheers: Array<{
    __typename?: "Cheer";
    id: string;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
    recipient: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
};

type Cheerable_NewsfeedAward_Fragment = {
  __typename?: "NewsfeedAward";
  id: string;
  cheers: Array<{
    __typename?: "Cheer";
    id: string;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
    recipient: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
};

type Cheerable_NewsfeedCoachVisit_Fragment = {
  __typename?: "NewsfeedCoachVisit";
  id: string;
  cheers: Array<{
    __typename?: "Cheer";
    id: string;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
    recipient: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
};

type Cheerable_NewsfeedRegistration_Fragment = {
  __typename?: "NewsfeedRegistration";
  id: string;
  cheers: Array<{
    __typename?: "Cheer";
    id: string;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
    recipient: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
};

type Cheerable_NewsfeedWorkout_Fragment = {
  __typename?: "NewsfeedWorkout";
  id: string;
  cheers: Array<{
    __typename?: "Cheer";
    id: string;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
    recipient: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
};

export type CheerableFragment =
  | Cheerable_NewsfeedAttendance_Fragment
  | Cheerable_NewsfeedAward_Fragment
  | Cheerable_NewsfeedCoachVisit_Fragment
  | Cheerable_NewsfeedRegistration_Fragment
  | Cheerable_NewsfeedWorkout_Fragment;

export type CoachFragment = { __typename?: "Coach"; id: string; name: string };

export type CoachReferralFragment = {
  __typename?: "Coach";
  availableForVisit: string;
  distanceFromRunner?: string | null | undefined;
  id: string;
  interests: string;
  lat?: number | null | undefined;
  lng?: number | null | undefined;
  name: string;
  reasonForReferral: string;
  homeRiskAssesed: boolean;
  additionalInfo: string;
  area: {
    __typename?: "Area";
    id: string;
    name: string;
    slug: string;
    socialVisits: boolean;
  };
};

export type CommentFragment = {
  __typename?: "Comment";
  id: string;
  content: string;
  createdAt: any;
  runner: {
    __typename?: "Runner";
    id: string;
    name: string;
    slug: string;
    avatarUrl: string;
    isMember: boolean;
    neverRun: boolean;
    verified: boolean;
    pronouns?: string | null | undefined;
    roles: Array<string>;
  };
};

type Commentable_NewsfeedAttendance_Fragment = {
  __typename?: "NewsfeedAttendance";
  id: string;
  comments: Array<{
    __typename?: "Comment";
    id: string;
    content: string;
    createdAt: any;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
};

type Commentable_NewsfeedAward_Fragment = {
  __typename?: "NewsfeedAward";
  id: string;
  comments: Array<{
    __typename?: "Comment";
    id: string;
    content: string;
    createdAt: any;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
};

type Commentable_NewsfeedCoachVisit_Fragment = {
  __typename?: "NewsfeedCoachVisit";
  id: string;
  comments: Array<{
    __typename?: "Comment";
    id: string;
    content: string;
    createdAt: any;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
};

type Commentable_NewsfeedRegistration_Fragment = {
  __typename?: "NewsfeedRegistration";
  id: string;
  comments: Array<{
    __typename?: "Comment";
    id: string;
    content: string;
    createdAt: any;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
};

type Commentable_NewsfeedWorkout_Fragment = {
  __typename?: "NewsfeedWorkout";
  id: string;
  comments: Array<{
    __typename?: "Comment";
    id: string;
    content: string;
    createdAt: any;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
};

type Commentable_Report_Fragment = {
  __typename?: "Report";
  id: string;
  comments: Array<{
    __typename?: "Comment";
    id: string;
    content: string;
    createdAt: any;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
};

type Commentable_Session_Fragment = {
  __typename?: "Session";
  id: string;
  comments: Array<{
    __typename?: "Comment";
    id: string;
    content: string;
    createdAt: any;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
};

export type CommentableFragment =
  | Commentable_NewsfeedAttendance_Fragment
  | Commentable_NewsfeedAward_Fragment
  | Commentable_NewsfeedCoachVisit_Fragment
  | Commentable_NewsfeedRegistration_Fragment
  | Commentable_NewsfeedWorkout_Fragment
  | Commentable_Report_Fragment
  | Commentable_Session_Fragment;

export type CurrentRunnerFragment = {
  __typename?: "CurrentRunner";
  id: string;
  name: string;
  bio?: string | null | undefined;
  postcode?: string | null | undefined;
  slug: string;
  pronouns?: string | null | undefined;
  avatarUrl: string;
  roles: Array<string>;
  isMember: boolean;
  neverRun: boolean;
  verified: boolean;
  featureFlags: Array<string>;
  needsToGetCoachVerified: boolean;
  employment?:
    | {
        __typename?: "Employment";
        id: string;
        startedAt: any;
        employer: {
          __typename?: "Employer";
          id: string;
          name: string;
          imageUrl: string;
          slug: string;
        };
      }
    | null
    | undefined;
  homeArea?:
    | {
        __typename?: "Area";
        socialVisits: boolean;
        pairingVerifiedRunnersCount: number;
        id: string;
        name: string;
        slug: string;
      }
    | null
    | undefined;
  areas: Array<{
    __typename?: "Area";
    id: string;
    name: string;
    slug: string;
    socialVisits: boolean;
  }>;
};

export type CurrentRunnerTrainingStatusFragment = {
  __typename?: "CurrentRunner";
  id: string;
  trainingStatuses: Array<{
    __typename?: "RunnerTrainingStatus";
    id: string;
    slug: string;
    status: TrainingStatus;
  }>;
};

export type EmploymentFragment = {
  __typename?: "Employment";
  id: string;
  startedAt: any;
  employer: {
    __typename?: "Employer";
    id: string;
    name: string;
    imageUrl: string;
    slug: string;
  };
};

export type FollowStatusFragment = {
  __typename?: "Runner";
  id: string;
  followsYou: boolean;
  followedByYou: boolean;
  blockedByYou: boolean;
};

export type GroupFitnessActivityFragment = {
  __typename?: "GroupFitnessActivity";
  id: string;
  type: string;
  distance: number;
  startedAt: any;
  description: string;
  address: {
    __typename?: "Address";
    id: string;
    line1: string;
    line2?: string | null | undefined;
    city: string;
    postcode: string;
    lat?: number | null | undefined;
    lng?: number | null | undefined;
  };
};

export type MenuOptionFragment = {
  __typename?: "MenuOption";
  id: string;
  label: string;
  url: string;
  icon: string;
  completed: boolean;
  disabled: boolean;
  method: HttpMethod;
};

export type NewsfeedAttendanceFragment = {
  __typename: "NewsfeedAttendance";
  id: string;
  time: any;
  session: {
    __typename?: "Session";
    id: string;
    slug: string;
    title: string;
    strapline: string;
    registerMax?: number | null | undefined;
    type: string;
    imageUrl: string;
    coverImageUrl: string;
    startedAt: any;
    duration?: number | null | undefined;
    area?:
      | {
          __typename?: "Area";
          id: string;
          name: string;
          slug: string;
          socialVisits: boolean;
        }
      | null
      | undefined;
    report?:
      | {
          __typename?: "Report";
          id: string;
          slug: string;
          title: string;
          description: string;
          imageUrl: string;
          coverImageUrl: string;
          publishedAt?: any | null | undefined;
          pictures: Array<string>;
          writtenBy?:
            | {
                __typename?: "Runner";
                id: string;
                name: string;
                slug: string;
                avatarUrl: string;
                isMember: boolean;
                neverRun: boolean;
                verified: boolean;
                pronouns?: string | null | undefined;
                roles: Array<string>;
              }
            | null
            | undefined;
          comments: Array<{
            __typename?: "Comment";
            id: string;
            content: string;
            createdAt: any;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | null
      | undefined;
    comments: Array<{
      __typename?: "Comment";
      id: string;
      content: string;
      createdAt: any;
      runner: {
        __typename?: "Runner";
        id: string;
        name: string;
        slug: string;
        avatarUrl: string;
        isMember: boolean;
        neverRun: boolean;
        verified: boolean;
        pronouns?: string | null | undefined;
        roles: Array<string>;
      };
    }>;
  };
  runners: Array<{
    __typename?: "Runner";
    id: string;
    name: string;
    slug: string;
    avatarUrl: string;
    isMember: boolean;
    neverRun: boolean;
    verified: boolean;
    pronouns?: string | null | undefined;
    roles: Array<string>;
  }>;
  cheers: Array<{
    __typename?: "Cheer";
    id: string;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
    recipient: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
};

export type NewsfeedAwardFragment = {
  __typename: "NewsfeedAward";
  id: string;
  time: any;
  description: string;
  badge: {
    __typename?: "Badge";
    id: string;
    name: string;
    description: string;
    imageUrl: string;
  };
  runner: {
    __typename?: "Runner";
    id: string;
    name: string;
    slug: string;
    avatarUrl: string;
    isMember: boolean;
    neverRun: boolean;
    verified: boolean;
    pronouns?: string | null | undefined;
    roles: Array<string>;
  };
  cheers: Array<{
    __typename?: "Cheer";
    id: string;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
    recipient: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
  comments: Array<{
    __typename?: "Comment";
    id: string;
    content: string;
    createdAt: any;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
};

export type NewsfeedCoachVisitFragment = {
  __typename: "NewsfeedCoachVisit";
  id: string;
  time: any;
  runner: {
    __typename?: "Runner";
    id: string;
    name: string;
    slug: string;
    avatarUrl: string;
    isMember: boolean;
    neverRun: boolean;
    verified: boolean;
    pronouns?: string | null | undefined;
    roles: Array<string>;
  };
  cheers: Array<{
    __typename?: "Cheer";
    id: string;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
    recipient: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
  comments: Array<{
    __typename?: "Comment";
    id: string;
    content: string;
    createdAt: any;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
};

type NewsfeedItem_NewsfeedAttendance_Fragment = {
  __typename: "NewsfeedAttendance";
  id: string;
  time: any;
  session: {
    __typename?: "Session";
    id: string;
    slug: string;
    title: string;
    strapline: string;
    registerMax?: number | null | undefined;
    type: string;
    imageUrl: string;
    coverImageUrl: string;
    startedAt: any;
    duration?: number | null | undefined;
    area?:
      | {
          __typename?: "Area";
          id: string;
          name: string;
          slug: string;
          socialVisits: boolean;
        }
      | null
      | undefined;
    report?:
      | {
          __typename?: "Report";
          id: string;
          slug: string;
          title: string;
          description: string;
          imageUrl: string;
          coverImageUrl: string;
          publishedAt?: any | null | undefined;
          pictures: Array<string>;
          writtenBy?:
            | {
                __typename?: "Runner";
                id: string;
                name: string;
                slug: string;
                avatarUrl: string;
                isMember: boolean;
                neverRun: boolean;
                verified: boolean;
                pronouns?: string | null | undefined;
                roles: Array<string>;
              }
            | null
            | undefined;
          comments: Array<{
            __typename?: "Comment";
            id: string;
            content: string;
            createdAt: any;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | null
      | undefined;
    comments: Array<{
      __typename?: "Comment";
      id: string;
      content: string;
      createdAt: any;
      runner: {
        __typename?: "Runner";
        id: string;
        name: string;
        slug: string;
        avatarUrl: string;
        isMember: boolean;
        neverRun: boolean;
        verified: boolean;
        pronouns?: string | null | undefined;
        roles: Array<string>;
      };
    }>;
  };
  runners: Array<{
    __typename?: "Runner";
    id: string;
    name: string;
    slug: string;
    avatarUrl: string;
    isMember: boolean;
    neverRun: boolean;
    verified: boolean;
    pronouns?: string | null | undefined;
    roles: Array<string>;
  }>;
  cheers: Array<{
    __typename?: "Cheer";
    id: string;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
    recipient: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
};

type NewsfeedItem_NewsfeedAward_Fragment = {
  __typename: "NewsfeedAward";
  id: string;
  time: any;
  description: string;
  badge: {
    __typename?: "Badge";
    id: string;
    name: string;
    description: string;
    imageUrl: string;
  };
  runner: {
    __typename?: "Runner";
    id: string;
    name: string;
    slug: string;
    avatarUrl: string;
    isMember: boolean;
    neverRun: boolean;
    verified: boolean;
    pronouns?: string | null | undefined;
    roles: Array<string>;
  };
  cheers: Array<{
    __typename?: "Cheer";
    id: string;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
    recipient: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
  comments: Array<{
    __typename?: "Comment";
    id: string;
    content: string;
    createdAt: any;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
};

type NewsfeedItem_NewsfeedCoachVisit_Fragment = {
  __typename: "NewsfeedCoachVisit";
  id: string;
  time: any;
  runner: {
    __typename?: "Runner";
    id: string;
    name: string;
    slug: string;
    avatarUrl: string;
    isMember: boolean;
    neverRun: boolean;
    verified: boolean;
    pronouns?: string | null | undefined;
    roles: Array<string>;
  };
  cheers: Array<{
    __typename?: "Cheer";
    id: string;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
    recipient: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
  comments: Array<{
    __typename?: "Comment";
    id: string;
    content: string;
    createdAt: any;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
};

type NewsfeedItem_NewsfeedRegistration_Fragment = {
  __typename: "NewsfeedRegistration";
  id: string;
  time: any;
  session: {
    __typename?: "Session";
    registerMax?: number | null | undefined;
    id: string;
    slug: string;
    title: string;
    strapline: string;
    type: string;
    imageUrl: string;
    coverImageUrl: string;
    startedAt: any;
    duration?: number | null | undefined;
    signups: Array<{
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    }>;
    address?:
      | {
          __typename?: "Address";
          id: string;
          line1: string;
          line2?: string | null | undefined;
          city: string;
          postcode: string;
          lat?: number | null | undefined;
          lng?: number | null | undefined;
        }
      | null
      | undefined;
    area?:
      | {
          __typename?: "Area";
          id: string;
          name: string;
          slug: string;
          socialVisits: boolean;
        }
      | null
      | undefined;
    comments: Array<{
      __typename?: "Comment";
      id: string;
      content: string;
      createdAt: any;
      runner: {
        __typename?: "Runner";
        id: string;
        name: string;
        slug: string;
        avatarUrl: string;
        isMember: boolean;
        neverRun: boolean;
        verified: boolean;
        pronouns?: string | null | undefined;
        roles: Array<string>;
      };
    }>;
  };
  runners: Array<{
    __typename?: "Runner";
    id: string;
    name: string;
    slug: string;
    avatarUrl: string;
    isMember: boolean;
    neverRun: boolean;
    verified: boolean;
    pronouns?: string | null | undefined;
    roles: Array<string>;
  }>;
  cheers: Array<{
    __typename?: "Cheer";
    id: string;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
    recipient: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
};

type NewsfeedItem_NewsfeedWorkout_Fragment = {
  __typename: "NewsfeedWorkout";
  id: string;
  name: string;
  time: any;
  type: string;
  distance: number;
  duration: number;
  uri?: string | null | undefined;
  provider?: string | null | undefined;
  averagePace: string;
  runner: {
    __typename?: "Runner";
    id: string;
    name: string;
    slug: string;
    avatarUrl: string;
    isMember: boolean;
    neverRun: boolean;
    verified: boolean;
    pronouns?: string | null | undefined;
    roles: Array<string>;
  };
  cheers: Array<{
    __typename?: "Cheer";
    id: string;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
    recipient: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
  comments: Array<{
    __typename?: "Comment";
    id: string;
    content: string;
    createdAt: any;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
};

export type NewsfeedItemFragment =
  | NewsfeedItem_NewsfeedAttendance_Fragment
  | NewsfeedItem_NewsfeedAward_Fragment
  | NewsfeedItem_NewsfeedCoachVisit_Fragment
  | NewsfeedItem_NewsfeedRegistration_Fragment
  | NewsfeedItem_NewsfeedWorkout_Fragment;

export type NewsfeedRegistrationFragment = {
  __typename: "NewsfeedRegistration";
  id: string;
  time: any;
  session: {
    __typename?: "Session";
    registerMax?: number | null | undefined;
    id: string;
    slug: string;
    title: string;
    strapline: string;
    type: string;
    imageUrl: string;
    coverImageUrl: string;
    startedAt: any;
    duration?: number | null | undefined;
    signups: Array<{
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    }>;
    address?:
      | {
          __typename?: "Address";
          id: string;
          line1: string;
          line2?: string | null | undefined;
          city: string;
          postcode: string;
          lat?: number | null | undefined;
          lng?: number | null | undefined;
        }
      | null
      | undefined;
    area?:
      | {
          __typename?: "Area";
          id: string;
          name: string;
          slug: string;
          socialVisits: boolean;
        }
      | null
      | undefined;
    comments: Array<{
      __typename?: "Comment";
      id: string;
      content: string;
      createdAt: any;
      runner: {
        __typename?: "Runner";
        id: string;
        name: string;
        slug: string;
        avatarUrl: string;
        isMember: boolean;
        neverRun: boolean;
        verified: boolean;
        pronouns?: string | null | undefined;
        roles: Array<string>;
      };
    }>;
  };
  runners: Array<{
    __typename?: "Runner";
    id: string;
    name: string;
    slug: string;
    avatarUrl: string;
    isMember: boolean;
    neverRun: boolean;
    verified: boolean;
    pronouns?: string | null | undefined;
    roles: Array<string>;
  }>;
  cheers: Array<{
    __typename?: "Cheer";
    id: string;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
    recipient: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
};

export type NewsfeedWorkoutFragment = {
  __typename: "NewsfeedWorkout";
  id: string;
  name: string;
  time: any;
  type: string;
  distance: number;
  duration: number;
  uri?: string | null | undefined;
  provider?: string | null | undefined;
  averagePace: string;
  runner: {
    __typename?: "Runner";
    id: string;
    name: string;
    slug: string;
    avatarUrl: string;
    isMember: boolean;
    neverRun: boolean;
    verified: boolean;
    pronouns?: string | null | undefined;
    roles: Array<string>;
  };
  cheers: Array<{
    __typename?: "Cheer";
    id: string;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
    recipient: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
  comments: Array<{
    __typename?: "Comment";
    id: string;
    content: string;
    createdAt: any;
    runner: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  }>;
};

export type NotificationFragment = {
  __typename?: "Notification";
  id: string;
  content: string;
  url: string;
  imageUrl: string;
  read: boolean;
  createdAt: any;
};

export type OrganisationFragment = {
  __typename?: "Organisation";
  id: string;
  slug: string;
  strapline: string;
  name: string;
};

export type ReportFragment = {
  __typename?: "Report";
  id: string;
  slug: string;
  title: string;
  description: string;
  imageUrl: string;
  coverImageUrl: string;
  publishedAt?: any | null | undefined;
  pictures: Array<string>;
  writtenBy?:
    | {
        __typename?: "Runner";
        id: string;
        name: string;
        slug: string;
        avatarUrl: string;
        isMember: boolean;
        neverRun: boolean;
        verified: boolean;
        pronouns?: string | null | undefined;
        roles: Array<string>;
      }
    | null
    | undefined;
};

export type RestrictedBeneficiaryFragment = {
  __typename?: "RestrictedBeneficiary";
  id: string;
  slug: string;
  name: string;
  phoneNumber?: string | null | undefined;
  dateOfBirth?: any | null | undefined;
  area?:
    | {
        __typename?: "Area";
        id: string;
        name: string;
        slug: string;
        socialVisits: boolean;
      }
    | null
    | undefined;
  address?:
    | {
        __typename?: "Address";
        id: string;
        line1: string;
        line2?: string | null | undefined;
        city: string;
        postcode: string;
        lat?: number | null | undefined;
        lng?: number | null | undefined;
      }
    | null
    | undefined;
};

export type RunnerFragment = {
  __typename?: "Runner";
  id: string;
  name: string;
  slug: string;
  avatarUrl: string;
  isMember: boolean;
  neverRun: boolean;
  verified: boolean;
  pronouns?: string | null | undefined;
  roles: Array<string>;
};

export type RunnerStatsFragment = {
  __typename?: "RunnerStats";
  id: string;
  runs: number;
  goodDeeds: number;
  cheersReceived: number;
  cheersGiven: number;
};

export type RunnerStatusFragment = {
  __typename?: "Runner";
  id: string;
  isMember: boolean;
  neverRun: boolean;
  verified: boolean;
  roles: Array<string>;
};

export type RunnerStreakFragment = {
  __typename?: "RunnerStreak";
  id: string;
  title: string;
  description: string;
  status: string;
  imageUrl: string;
  count: number;
  verified: boolean;
  current: boolean;
};

export type SessionFragment = {
  __typename?: "Session";
  id: string;
  slug: string;
  title: string;
  strapline: string;
  registerMax?: number | null | undefined;
  type: string;
  imageUrl: string;
  coverImageUrl: string;
  startedAt: any;
  duration?: number | null | undefined;
};

export type SessionCardFragment = {
  __typename?: "Session";
  signedUp: boolean;
  coverImageUrl: string;
  id: string;
  slug: string;
  title: string;
  strapline: string;
  registerMax?: number | null | undefined;
  type: string;
  imageUrl: string;
  startedAt: any;
  duration?: number | null | undefined;
  address?:
    | {
        __typename?: "Address";
        id: string;
        line1: string;
        line2?: string | null | undefined;
        city: string;
        postcode: string;
        lat?: number | null | undefined;
        lng?: number | null | undefined;
      }
    | null
    | undefined;
  signups: Array<{
    __typename?: "Runner";
    id: string;
    name: string;
    slug: string;
    avatarUrl: string;
    isMember: boolean;
    neverRun: boolean;
    verified: boolean;
    pronouns?: string | null | undefined;
    roles: Array<string>;
  }>;
  area?:
    | {
        __typename?: "Area";
        id: string;
        name: string;
        slug: string;
        socialVisits: boolean;
      }
    | null
    | undefined;
  report?:
    | {
        __typename?: "Report";
        id: string;
        slug: string;
        title: string;
        description: string;
        imageUrl: string;
        coverImageUrl: string;
        publishedAt?: any | null | undefined;
        pictures: Array<string>;
        writtenBy?:
          | {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type SessionRiskFragment = {
  __typename?: "SessionRisk";
  id: string;
  name: string;
  description?: string | null | undefined;
  imageUrl: string;
};

export type StoryFragment = {
  __typename?: "Story";
  id: string;
  title: string;
  body: string;
  imageUrl: string;
  publishedAt?: any | null | undefined;
  strapline: string;
  slug: string;
  discardedAt?: any | null | undefined;
  createdAt: any;
  area?:
    | {
        __typename?: "Area";
        id: string;
        name: string;
        slug: string;
        socialVisits: boolean;
      }
    | null
    | undefined;
  author: {
    __typename?: "Runner";
    id: string;
    name: string;
    slug: string;
    avatarUrl: string;
    isMember: boolean;
    neverRun: boolean;
    verified: boolean;
    pronouns?: string | null | undefined;
    roles: Array<string>;
  };
};

export type TaskForceStatsFragment = {
  __typename?: "RunnerStats";
  id: string;
  sessionsLed: number;
  sessionsListed: number;
  sessionsBackmarked: number;
  sessionsPhotographed: number;
  walksLed: number;
  reportsWritten: number;
};

export type TaskRequestFragment = {
  __typename?: "TaskRequest";
  id: string;
  slug: string;
  title: string;
  outcome: string;
  deadline: any;
  registerMin: number;
  registerMax: number;
  lat?: number | null | undefined;
  lng?: number | null | undefined;
  potentialTimes: Array<any>;
};

export type TaskRequestCardFragment = {
  __typename?: "TaskRequest";
  signedUp: boolean;
  declined: boolean;
  id: string;
  slug: string;
  title: string;
  outcome: string;
  deadline: any;
  registerMin: number;
  registerMax: number;
  lat?: number | null | undefined;
  lng?: number | null | undefined;
  potentialTimes: Array<any>;
  area: {
    __typename?: "Area";
    id: string;
    name: string;
    slug: string;
    socialVisits: boolean;
  };
  reservations: Array<{
    __typename?: "TaskRequestReservation";
    id: string;
    startedAt: any;
    runners: Array<{
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    }>;
  }>;
};

export type TaskforceRoleFragment = {
  __typename?: "TaskforceRole";
  id: string;
  name: string;
  description: string;
  slug: string;
  runner?:
    | {
        __typename?: "Runner";
        id: string;
        name: string;
        slug: string;
        avatarUrl: string;
        isMember: boolean;
        neverRun: boolean;
        verified: boolean;
        pronouns?: string | null | undefined;
        roles: Array<string>;
      }
    | null
    | undefined;
};

export type TestPairingFragment = {
  __typename?: "TestPairing";
  acceptedAt?: any | null | undefined;
  completedAt?: any | null | undefined;
  id: string;
  notesForRunner?: string | null | undefined;
  rejectedAt?: any | null | undefined;
  rejectionNotes?: string | null | undefined;
  scheduledAt?: any | null | undefined;
  runnerVisitFeedback?: string | null | undefined;
  runnerVisitNotes?: string | null | undefined;
  runnerWantsPairing?: boolean | null | undefined;
  cancelledAt?: any | null | undefined;
  cancellationNotes?: string | null | undefined;
  coach: { __typename?: "Coach"; id: string; name: string };
};

export type TrainingFragment = {
  __typename?: "Training";
  id: string;
  slug: string;
  name: string;
  description: string;
};

export type TrainingModuleFragment = {
  __typename?: "TrainingModule";
  id: string;
  name: string;
  description: string;
  order: number;
};

export type TrainingModuleQuestionFragment = {
  __typename?: "TrainingModuleQuestion";
  id: string;
  question: string;
  description?: string | null | undefined;
  type: string;
  order: number;
};

export type TrainingModuleSectionFragment = {
  __typename?: "TrainingModuleSection";
  id: string;
  name: string;
  description: string;
  order: number;
};

export type TrainingStatusFragment = {
  __typename?: "RunnerTrainingStatus";
  id: string;
  slug: string;
  status: TrainingStatus;
};

export type BlockMutationVariables = Exact<{
  runnerIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type BlockMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "BlockMutationPayload";
    runners: Array<{
      __typename?: "Runner";
      id: string;
      followsYou: boolean;
      followedByYou: boolean;
      blockedByYou: boolean;
    }>;
  };
};

export type CheerMutationVariables = Exact<{
  cheerableActivityId: Scalars["ID"];
  cheerableType: CheerableType;
  runnerIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type CheerMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "CheerMutationPayload";
    cheerable:
      | {
          __typename?: "NewsfeedAttendance";
          id: string;
          cheers: Array<{
            __typename?: "Cheer";
            id: string;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
            recipient: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | {
          __typename?: "NewsfeedAward";
          id: string;
          cheers: Array<{
            __typename?: "Cheer";
            id: string;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
            recipient: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | {
          __typename?: "NewsfeedCoachVisit";
          id: string;
          cheers: Array<{
            __typename?: "Cheer";
            id: string;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
            recipient: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | {
          __typename?: "NewsfeedRegistration";
          id: string;
          cheers: Array<{
            __typename?: "Cheer";
            id: string;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
            recipient: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | {
          __typename?: "NewsfeedWorkout";
          id: string;
          cheers: Array<{
            __typename?: "Cheer";
            id: string;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
            recipient: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        };
  };
};

export type CompleteTrainingMutationVariables = Exact<{
  trainingId: Scalars["ID"];
  answers: Array<TrainingAnswerInput> | TrainingAnswerInput;
}>;

export type CompleteTrainingMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "CompleteTrainingMutationPayload";
    success: boolean;
    runner: {
      __typename?: "CurrentRunner";
      id: string;
      trainingStatuses: Array<{
        __typename?: "RunnerTrainingStatus";
        id: string;
        slug: string;
        status: TrainingStatus;
      }>;
    };
  };
};

export type CreateCommentMutationVariables = Exact<{
  commentableId: Scalars["ID"];
  commentableType: CommentableType;
  content: Scalars["String"];
}>;

export type CreateCommentMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "CreateCommentMutationPayload";
    commentable:
      | {
          __typename?: "NewsfeedAttendance";
          id: string;
          comments: Array<{
            __typename?: "Comment";
            id: string;
            content: string;
            createdAt: any;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | {
          __typename?: "NewsfeedAward";
          id: string;
          comments: Array<{
            __typename?: "Comment";
            id: string;
            content: string;
            createdAt: any;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | {
          __typename?: "NewsfeedCoachVisit";
          id: string;
          comments: Array<{
            __typename?: "Comment";
            id: string;
            content: string;
            createdAt: any;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | {
          __typename?: "NewsfeedRegistration";
          id: string;
          comments: Array<{
            __typename?: "Comment";
            id: string;
            content: string;
            createdAt: any;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | {
          __typename?: "NewsfeedWorkout";
          id: string;
          comments: Array<{
            __typename?: "Comment";
            id: string;
            content: string;
            createdAt: any;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | {
          __typename?: "Report";
          id: string;
          comments: Array<{
            __typename?: "Comment";
            id: string;
            content: string;
            createdAt: any;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | {
          __typename?: "Session";
          id: string;
          comments: Array<{
            __typename?: "Comment";
            id: string;
            content: string;
            createdAt: any;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        };
  };
};

export type DeclineTaskRequestMutationVariables = Exact<{
  taskRequestId: Scalars["ID"];
}>;

export type DeclineTaskRequestMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "DeclineTaskRequestMutationPayload";
    taskRequest: {
      __typename?: "TaskRequest";
      signedUp: boolean;
      declined: boolean;
      id: string;
      slug: string;
      title: string;
      outcome: string;
      deadline: any;
      registerMin: number;
      registerMax: number;
      lat?: number | null | undefined;
      lng?: number | null | undefined;
      potentialTimes: Array<any>;
      area: {
        __typename?: "Area";
        id: string;
        name: string;
        slug: string;
        socialVisits: boolean;
      };
      reservations: Array<{
        __typename?: "TaskRequestReservation";
        id: string;
        startedAt: any;
        runners: Array<{
          __typename?: "Runner";
          id: string;
          name: string;
          slug: string;
          avatarUrl: string;
          isMember: boolean;
          neverRun: boolean;
          verified: boolean;
          pronouns?: string | null | undefined;
          roles: Array<string>;
        }>;
      }>;
    };
  };
};

export type DeleteCommentMutationVariables = Exact<{
  commentId: Scalars["ID"];
}>;

export type DeleteCommentMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "DeleteCommentMutationPayload";
    commentable:
      | {
          __typename?: "NewsfeedAttendance";
          id: string;
          comments: Array<{
            __typename?: "Comment";
            id: string;
            content: string;
            createdAt: any;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | {
          __typename?: "NewsfeedAward";
          id: string;
          comments: Array<{
            __typename?: "Comment";
            id: string;
            content: string;
            createdAt: any;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | {
          __typename?: "NewsfeedCoachVisit";
          id: string;
          comments: Array<{
            __typename?: "Comment";
            id: string;
            content: string;
            createdAt: any;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | {
          __typename?: "NewsfeedRegistration";
          id: string;
          comments: Array<{
            __typename?: "Comment";
            id: string;
            content: string;
            createdAt: any;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | {
          __typename?: "NewsfeedWorkout";
          id: string;
          comments: Array<{
            __typename?: "Comment";
            id: string;
            content: string;
            createdAt: any;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | {
          __typename?: "Report";
          id: string;
          comments: Array<{
            __typename?: "Comment";
            id: string;
            content: string;
            createdAt: any;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | {
          __typename?: "Session";
          id: string;
          comments: Array<{
            __typename?: "Comment";
            id: string;
            content: string;
            createdAt: any;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        };
  };
};

export type DeleteGroupFitnessActivityMutationVariables = Exact<{
  groupFitnessActivityId: Scalars["ID"];
}>;

export type DeleteGroupFitnessActivityMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "DeleteGroupFitnessActivityMutationPayload";
    session: {
      __typename?: "Session";
      id: string;
      slug: string;
      title: string;
      strapline: string;
      registerMax?: number | null | undefined;
      type: string;
      imageUrl: string;
      coverImageUrl: string;
      startedAt: any;
      duration?: number | null | undefined;
      groupFitnessActivities: Array<{
        __typename?: "GroupFitnessActivity";
        id: string;
        type: string;
        distance: number;
        startedAt: any;
        description: string;
        address: {
          __typename?: "Address";
          id: string;
          line1: string;
          line2?: string | null | undefined;
          city: string;
          postcode: string;
          lat?: number | null | undefined;
          lng?: number | null | undefined;
        };
      }>;
    };
  };
};

export type DismissCtaMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DismissCtaMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "DismissCTAMutationPayload";
    id: string;
    currentRunner: {
      __typename?: "CurrentRunner";
      id: string;
      name: string;
      bio?: string | null | undefined;
      postcode?: string | null | undefined;
      slug: string;
      pronouns?: string | null | undefined;
      avatarUrl: string;
      roles: Array<string>;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      featureFlags: Array<string>;
      needsToGetCoachVerified: boolean;
      ctas: Array<{
        __typename?: "CTA";
        id: string;
        title: string;
        description: string;
        icon: string;
        url: string;
        actionText: string;
        dismissText?: string | null | undefined;
      }>;
      employment?:
        | {
            __typename?: "Employment";
            id: string;
            startedAt: any;
            employer: {
              __typename?: "Employer";
              id: string;
              name: string;
              imageUrl: string;
              slug: string;
            };
          }
        | null
        | undefined;
      homeArea?:
        | {
            __typename?: "Area";
            socialVisits: boolean;
            pairingVerifiedRunnersCount: number;
            id: string;
            name: string;
            slug: string;
          }
        | null
        | undefined;
      areas: Array<{
        __typename?: "Area";
        id: string;
        name: string;
        slug: string;
        socialVisits: boolean;
      }>;
    };
  };
};

export type FollowMutationVariables = Exact<{
  runnerIds: Array<Scalars["ID"]> | Scalars["ID"];
  message: Scalars["String"];
}>;

export type FollowMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "FollowMutationPayload";
    runners: Array<{
      __typename?: "Runner";
      id: string;
      followsYou: boolean;
      followedByYou: boolean;
      blockedByYou: boolean;
    }>;
  };
};

export type JoinWaitingListMutationVariables = Exact<{
  sessionId: Scalars["ID"];
}>;

export type JoinWaitingListMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "JoinWaitingListMutationPayload";
    session: { __typename?: "Session"; id: string; onWaitingList: boolean };
  };
};

export type LeaveWaitingListMutationVariables = Exact<{
  sessionId: Scalars["ID"];
}>;

export type LeaveWaitingListMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "LeaveWaitingListMutationPayload";
    session: { __typename?: "Session"; id: string; onWaitingList: boolean };
  };
};

export type MarkAllNotificationsAsReadMutationVariables = Exact<{
  [key: string]: never;
}>;

export type MarkAllNotificationsAsReadMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "MarkAllNotificationsAsReadMutationPayload";
    notifications: Array<{
      __typename?: "Notification";
      id: string;
      content: string;
      url: string;
      imageUrl: string;
      read: boolean;
      createdAt: any;
    }>;
  };
};

export type RequestTestPairingMutationVariables = Exact<{
  coachId: Scalars["ID"];
  runnerSuggestedTimes: Array<Scalars["JSON"]> | Scalars["JSON"];
}>;

export type RequestTestPairingMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "RequestTestPairingMutationPayload";
    errors?: Array<string> | null | undefined;
    testPairing?:
      | {
          __typename?: "TestPairing";
          acceptedAt?: any | null | undefined;
          completedAt?: any | null | undefined;
          id: string;
          notesForRunner?: string | null | undefined;
          rejectedAt?: any | null | undefined;
          rejectionNotes?: string | null | undefined;
          scheduledAt?: any | null | undefined;
          runnerVisitFeedback?: string | null | undefined;
          runnerVisitNotes?: string | null | undefined;
          runnerWantsPairing?: boolean | null | undefined;
          cancelledAt?: any | null | undefined;
          cancellationNotes?: string | null | undefined;
          coach: { __typename?: "Coach"; id: string; name: string };
        }
      | null
      | undefined;
  };
};

export type SignUpToSessionMutationVariables = Exact<{
  sessionId: Scalars["ID"];
  groupFitnessActivityId?: Maybe<Scalars["ID"]>;
  taskforceRoleIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type SignUpToSessionMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "SignUpToSessionMutationPayload";
    session: {
      __typename?: "Session";
      signedUp: boolean;
      coverImageUrl: string;
      id: string;
      slug: string;
      title: string;
      strapline: string;
      registerMax?: number | null | undefined;
      type: string;
      imageUrl: string;
      startedAt: any;
      duration?: number | null | undefined;
      address?:
        | {
            __typename?: "Address";
            id: string;
            line1: string;
            line2?: string | null | undefined;
            city: string;
            postcode: string;
            lat?: number | null | undefined;
            lng?: number | null | undefined;
          }
        | null
        | undefined;
      signups: Array<{
        __typename?: "Runner";
        id: string;
        name: string;
        slug: string;
        avatarUrl: string;
        isMember: boolean;
        neverRun: boolean;
        verified: boolean;
        pronouns?: string | null | undefined;
        roles: Array<string>;
      }>;
      area?:
        | {
            __typename?: "Area";
            id: string;
            name: string;
            slug: string;
            socialVisits: boolean;
          }
        | null
        | undefined;
      report?:
        | {
            __typename?: "Report";
            id: string;
            slug: string;
            title: string;
            description: string;
            imageUrl: string;
            coverImageUrl: string;
            publishedAt?: any | null | undefined;
            pictures: Array<string>;
            writtenBy?:
              | {
                  __typename?: "Runner";
                  id: string;
                  name: string;
                  slug: string;
                  avatarUrl: string;
                  isMember: boolean;
                  neverRun: boolean;
                  verified: boolean;
                  pronouns?: string | null | undefined;
                  roles: Array<string>;
                }
              | null
              | undefined;
          }
        | null
        | undefined;
    };
  };
};

export type UnSignUpFromSessionMutationVariables = Exact<{
  sessionId: Scalars["ID"];
}>;

export type UnSignUpFromSessionMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "UnSignUpFromSessionMutationPayload";
    session: {
      __typename?: "Session";
      signedUp: boolean;
      coverImageUrl: string;
      id: string;
      slug: string;
      title: string;
      strapline: string;
      registerMax?: number | null | undefined;
      type: string;
      imageUrl: string;
      startedAt: any;
      duration?: number | null | undefined;
      address?:
        | {
            __typename?: "Address";
            id: string;
            line1: string;
            line2?: string | null | undefined;
            city: string;
            postcode: string;
            lat?: number | null | undefined;
            lng?: number | null | undefined;
          }
        | null
        | undefined;
      signups: Array<{
        __typename?: "Runner";
        id: string;
        name: string;
        slug: string;
        avatarUrl: string;
        isMember: boolean;
        neverRun: boolean;
        verified: boolean;
        pronouns?: string | null | undefined;
        roles: Array<string>;
      }>;
      area?:
        | {
            __typename?: "Area";
            id: string;
            name: string;
            slug: string;
            socialVisits: boolean;
          }
        | null
        | undefined;
      report?:
        | {
            __typename?: "Report";
            id: string;
            slug: string;
            title: string;
            description: string;
            imageUrl: string;
            coverImageUrl: string;
            publishedAt?: any | null | undefined;
            pictures: Array<string>;
            writtenBy?:
              | {
                  __typename?: "Runner";
                  id: string;
                  name: string;
                  slug: string;
                  avatarUrl: string;
                  isMember: boolean;
                  neverRun: boolean;
                  verified: boolean;
                  pronouns?: string | null | undefined;
                  roles: Array<string>;
                }
              | null
              | undefined;
          }
        | null
        | undefined;
    };
  };
};

export type UnblockMutationVariables = Exact<{
  runnerIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type UnblockMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "UnblockMutationPayload";
    runners: Array<{
      __typename?: "Runner";
      id: string;
      followsYou: boolean;
      followedByYou: boolean;
      blockedByYou: boolean;
    }>;
  };
};

export type UncheerMutationVariables = Exact<{
  cheerableActivityId: Scalars["ID"];
  cheerableType: CheerableType;
  runnerIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type UncheerMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "UncheerMutationPayload";
    cheerable:
      | {
          __typename?: "NewsfeedAttendance";
          id: string;
          cheers: Array<{
            __typename?: "Cheer";
            id: string;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
            recipient: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | {
          __typename?: "NewsfeedAward";
          id: string;
          cheers: Array<{
            __typename?: "Cheer";
            id: string;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
            recipient: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | {
          __typename?: "NewsfeedCoachVisit";
          id: string;
          cheers: Array<{
            __typename?: "Cheer";
            id: string;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
            recipient: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | {
          __typename?: "NewsfeedRegistration";
          id: string;
          cheers: Array<{
            __typename?: "Cheer";
            id: string;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
            recipient: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        }
      | {
          __typename?: "NewsfeedWorkout";
          id: string;
          cheers: Array<{
            __typename?: "Cheer";
            id: string;
            runner: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
            recipient: {
              __typename?: "Runner";
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            };
          }>;
        };
  };
};

export type UndeclineTaskRequestMutationVariables = Exact<{
  taskRequestId: Scalars["ID"];
}>;

export type UndeclineTaskRequestMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "UndeclineTaskRequestMutationPayload";
    taskRequest: {
      __typename?: "TaskRequest";
      signedUp: boolean;
      declined: boolean;
      id: string;
      slug: string;
      title: string;
      outcome: string;
      deadline: any;
      registerMin: number;
      registerMax: number;
      lat?: number | null | undefined;
      lng?: number | null | undefined;
      potentialTimes: Array<any>;
      area: {
        __typename?: "Area";
        id: string;
        name: string;
        slug: string;
        socialVisits: boolean;
      };
      reservations: Array<{
        __typename?: "TaskRequestReservation";
        id: string;
        startedAt: any;
        runners: Array<{
          __typename?: "Runner";
          id: string;
          name: string;
          slug: string;
          avatarUrl: string;
          isMember: boolean;
          neverRun: boolean;
          verified: boolean;
          pronouns?: string | null | undefined;
          roles: Array<string>;
        }>;
      }>;
    };
  };
};

export type UnfollowMutationVariables = Exact<{
  runnerIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type UnfollowMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "UnfollowMutationPayload";
    runners: Array<{
      __typename?: "Runner";
      id: string;
      followsYou: boolean;
      followedByYou: boolean;
      blockedByYou: boolean;
    }>;
  };
};

export type UnpublishReportMutationVariables = Exact<{
  reportId: Scalars["ID"];
}>;

export type UnpublishReportMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "UnpublishReportMutationPayload";
    report: {
      __typename?: "Report";
      id: string;
      slug: string;
      title: string;
      description: string;
      imageUrl: string;
      coverImageUrl: string;
      publishedAt?: any | null | undefined;
      pictures: Array<string>;
      writtenBy?:
        | {
            __typename?: "Runner";
            id: string;
            name: string;
            slug: string;
            avatarUrl: string;
            isMember: boolean;
            neverRun: boolean;
            verified: boolean;
            pronouns?: string | null | undefined;
            roles: Array<string>;
          }
        | null
        | undefined;
    };
  };
};

export type UpdateAreaMutationVariables = Exact<{
  areaId: Scalars["ID"];
  description?: Maybe<Scalars["String"]>;
}>;

export type UpdateAreaMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "UpdateAreaMutationPayload";
    area: {
      __typename?: "Area";
      description: string;
      id: string;
      name: string;
      slug: string;
      socialVisits: boolean;
    };
  };
};

export type UpdateOrganisationMutationVariables = Exact<{
  organisationId: Scalars["ID"];
  description?: Maybe<Scalars["String"]>;
}>;

export type UpdateOrganisationMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "UpdateOrganisationMutationPayload";
    organisation: {
      __typename?: "Organisation";
      description?: string | null | undefined;
      id: string;
      slug: string;
      strapline: string;
      name: string;
    };
  };
};

export type AreaPageSessionsQueryVariables = Exact<{
  types: Array<Scalars["String"]> | Scalars["String"];
  areaIds: Array<Scalars["ID"]> | Scalars["ID"];
  from: Scalars["ISO8601DateTime"];
  sessionsCursorDate?: Maybe<Scalars["ISO8601DateTime"]>;
  sessionsCursorId?: Maybe<Scalars["ID"]>;
  taskRequestsCursorDate?: Maybe<Scalars["ISO8601DateTime"]>;
  taskRequestsCursorId?: Maybe<Scalars["ID"]>;
  limit?: Maybe<Scalars["Int"]>;
}>;

export type AreaPageSessionsQuery = {
  __typename?: "Query";
  sessions: {
    __typename?: "SessionsResultV2";
    more: boolean;
    results: Array<{
      __typename?: "Session";
      signedUp: boolean;
      coverImageUrl: string;
      id: string;
      slug: string;
      title: string;
      strapline: string;
      registerMax?: number | null | undefined;
      type: string;
      imageUrl: string;
      startedAt: any;
      duration?: number | null | undefined;
      address?:
        | {
            __typename?: "Address";
            id: string;
            line1: string;
            line2?: string | null | undefined;
            city: string;
            postcode: string;
            lat?: number | null | undefined;
            lng?: number | null | undefined;
          }
        | null
        | undefined;
      signups: Array<{
        __typename?: "Runner";
        id: string;
        name: string;
        slug: string;
        avatarUrl: string;
        isMember: boolean;
        neverRun: boolean;
        verified: boolean;
        pronouns?: string | null | undefined;
        roles: Array<string>;
      }>;
      area?:
        | {
            __typename?: "Area";
            id: string;
            name: string;
            slug: string;
            socialVisits: boolean;
          }
        | null
        | undefined;
      report?:
        | {
            __typename?: "Report";
            id: string;
            slug: string;
            title: string;
            description: string;
            imageUrl: string;
            coverImageUrl: string;
            publishedAt?: any | null | undefined;
            pictures: Array<string>;
            writtenBy?:
              | {
                  __typename?: "Runner";
                  id: string;
                  name: string;
                  slug: string;
                  avatarUrl: string;
                  isMember: boolean;
                  neverRun: boolean;
                  verified: boolean;
                  pronouns?: string | null | undefined;
                  roles: Array<string>;
                }
              | null
              | undefined;
          }
        | null
        | undefined;
    }>;
  };
  taskRequests: {
    __typename?: "TaskRequestsResult";
    more: boolean;
    results: Array<{
      __typename?: "TaskRequest";
      signedUp: boolean;
      declined: boolean;
      id: string;
      slug: string;
      title: string;
      outcome: string;
      deadline: any;
      registerMin: number;
      registerMax: number;
      lat?: number | null | undefined;
      lng?: number | null | undefined;
      potentialTimes: Array<any>;
      area: {
        __typename?: "Area";
        id: string;
        name: string;
        slug: string;
        socialVisits: boolean;
      };
      reservations: Array<{
        __typename?: "TaskRequestReservation";
        id: string;
        startedAt: any;
        runners: Array<{
          __typename?: "Runner";
          id: string;
          name: string;
          slug: string;
          avatarUrl: string;
          isMember: boolean;
          neverRun: boolean;
          verified: boolean;
          pronouns?: string | null | undefined;
          roles: Array<string>;
        }>;
      }>;
    }>;
  };
};

export type AreaPageQueryVariables = Exact<{
  slug: Scalars["String"];
}>;

export type AreaPageQuery = {
  __typename?: "Query";
  area?:
    | {
        __typename?: "Area";
        description: string;
        active: boolean;
        imageUrl: string;
        email?: string | null | undefined;
        twitter?: string | null | undefined;
        facebook?: string | null | undefined;
        instagram?: string | null | undefined;
        id: string;
        name: string;
        slug: string;
        socialVisits: boolean;
        areaActivator?:
          | {
              __typename?: "Runner";
              bio: string;
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            }
          | null
          | undefined;
        taskforce: Array<{
          __typename?: "Runner";
          id: string;
          name: string;
          slug: string;
          avatarUrl: string;
          isMember: boolean;
          neverRun: boolean;
          verified: boolean;
          pronouns?: string | null | undefined;
          roles: Array<string>;
        }>;
        runLeaders: Array<{
          __typename?: "Runner";
          id: string;
          name: string;
          slug: string;
          avatarUrl: string;
          isMember: boolean;
          neverRun: boolean;
          verified: boolean;
          pronouns?: string | null | undefined;
          roles: Array<string>;
        }>;
        partners: Array<{
          __typename?: "AreaPartner";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          url: string;
        }>;
      }
    | null
    | undefined;
};

export type ContactPageQueryVariables = Exact<{
  slug: Scalars["String"];
}>;

export type ContactPageQuery = {
  __typename?: "Query";
  area?:
    | {
        __typename?: "Area";
        email?: string | null | undefined;
        twitter?: string | null | undefined;
        facebook?: string | null | undefined;
        instagram?: string | null | undefined;
        id: string;
        name: string;
        slug: string;
        socialVisits: boolean;
        areaActivator?:
          | {
              __typename?: "Runner";
              bio: string;
              id: string;
              name: string;
              slug: string;
              avatarUrl: string;
              isMember: boolean;
              neverRun: boolean;
              verified: boolean;
              pronouns?: string | null | undefined;
              roles: Array<string>;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type DashboardPageQueryVariables = Exact<{ [key: string]: never }>;

export type DashboardPageQuery = {
  __typename?: "Query";
  runner?:
    | {
        __typename?: "CurrentRunner";
        roles: Array<string>;
        id: string;
        name: string;
        bio?: string | null | undefined;
        postcode?: string | null | undefined;
        slug: string;
        pronouns?: string | null | undefined;
        avatarUrl: string;
        isMember: boolean;
        neverRun: boolean;
        verified: boolean;
        featureFlags: Array<string>;
        needsToGetCoachVerified: boolean;
        upcomingSessions: Array<{
          __typename?: "Session";
          signedUp: boolean;
          coverImageUrl: string;
          id: string;
          slug: string;
          title: string;
          strapline: string;
          registerMax?: number | null | undefined;
          type: string;
          imageUrl: string;
          startedAt: any;
          duration?: number | null | undefined;
          address?:
            | {
                __typename?: "Address";
                id: string;
                line1: string;
                line2?: string | null | undefined;
                city: string;
                postcode: string;
                lat?: number | null | undefined;
                lng?: number | null | undefined;
              }
            | null
            | undefined;
          signups: Array<{
            __typename?: "Runner";
            id: string;
            name: string;
            slug: string;
            avatarUrl: string;
            isMember: boolean;
            neverRun: boolean;
            verified: boolean;
            pronouns?: string | null | undefined;
            roles: Array<string>;
          }>;
          area?:
            | {
                __typename?: "Area";
                id: string;
                name: string;
                slug: string;
                socialVisits: boolean;
              }
            | null
            | undefined;
          report?:
            | {
                __typename?: "Report";
                id: string;
                slug: string;
                title: string;
                description: string;
                imageUrl: string;
                coverImageUrl: string;
                publishedAt?: any | null | undefined;
                pictures: Array<string>;
                writtenBy?:
                  | {
                      __typename?: "Runner";
                      id: string;
                      name: string;
                      slug: string;
                      avatarUrl: string;
                      isMember: boolean;
                      neverRun: boolean;
                      verified: boolean;
                      pronouns?: string | null | undefined;
                      roles: Array<string>;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }>;
        suggestedSessions: Array<{
          __typename?: "Session";
          signedUp: boolean;
          coverImageUrl: string;
          id: string;
          slug: string;
          title: string;
          strapline: string;
          registerMax?: number | null | undefined;
          type: string;
          imageUrl: string;
          startedAt: any;
          duration?: number | null | undefined;
          address?:
            | {
                __typename?: "Address";
                id: string;
                line1: string;
                line2?: string | null | undefined;
                city: string;
                postcode: string;
                lat?: number | null | undefined;
                lng?: number | null | undefined;
              }
            | null
            | undefined;
          signups: Array<{
            __typename?: "Runner";
            id: string;
            name: string;
            slug: string;
            avatarUrl: string;
            isMember: boolean;
            neverRun: boolean;
            verified: boolean;
            pronouns?: string | null | undefined;
            roles: Array<string>;
          }>;
          area?:
            | {
                __typename?: "Area";
                id: string;
                name: string;
                slug: string;
                socialVisits: boolean;
              }
            | null
            | undefined;
          report?:
            | {
                __typename?: "Report";
                id: string;
                slug: string;
                title: string;
                description: string;
                imageUrl: string;
                coverImageUrl: string;
                publishedAt?: any | null | undefined;
                pictures: Array<string>;
                writtenBy?:
                  | {
                      __typename?: "Runner";
                      id: string;
                      name: string;
                      slug: string;
                      avatarUrl: string;
                      isMember: boolean;
                      neverRun: boolean;
                      verified: boolean;
                      pronouns?: string | null | undefined;
                      roles: Array<string>;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }>;
        stats: {
          __typename?: "RunnerStats";
          id: string;
          runs: number;
          goodDeeds: number;
          cheersReceived: number;
          cheersGiven: number;
        };
        ctas: Array<{
          __typename?: "CTA";
          id: string;
          title: string;
          description: string;
          icon: string;
          url: string;
          actionText: string;
          dismissText?: string | null | undefined;
        }>;
        employment?:
          | {
              __typename?: "Employment";
              id: string;
              startedAt: any;
              employer: {
                __typename?: "Employer";
                id: string;
                name: string;
                imageUrl: string;
                slug: string;
              };
            }
          | null
          | undefined;
        homeArea?:
          | {
              __typename?: "Area";
              socialVisits: boolean;
              pairingVerifiedRunnersCount: number;
              id: string;
              name: string;
              slug: string;
            }
          | null
          | undefined;
        areas: Array<{
          __typename?: "Area";
          id: string;
          name: string;
          slug: string;
          socialVisits: boolean;
        }>;
      }
    | null
    | undefined;
};

export type DeletedStoryPageQueryVariables = Exact<{
  before?: Maybe<Scalars["ISO8601DateTime"]>;
}>;

export type DeletedStoryPageQuery = {
  __typename?: "Query";
  deletedStories: {
    __typename?: "StoriesResult";
    more: boolean;
    stories: Array<{
      __typename?: "Story";
      discardedAt?: any | null | undefined;
      id: string;
      title: string;
      body: string;
      imageUrl: string;
      publishedAt?: any | null | undefined;
      strapline: string;
      slug: string;
      createdAt: any;
      area?:
        | {
            __typename?: "Area";
            id: string;
            name: string;
            slug: string;
            socialVisits: boolean;
          }
        | null
        | undefined;
      author: {
        __typename?: "Runner";
        id: string;
        name: string;
        slug: string;
        avatarUrl: string;
        isMember: boolean;
        neverRun: boolean;
        verified: boolean;
        pronouns?: string | null | undefined;
        roles: Array<string>;
      };
    }>;
  };
};

export type DraftedStoryPageQueryVariables = Exact<{
  before?: Maybe<Scalars["ISO8601DateTime"]>;
}>;

export type DraftedStoryPageQuery = {
  __typename?: "Query";
  draftedStories: {
    __typename?: "StoriesResult";
    more: boolean;
    stories: Array<{
      __typename?: "Story";
      discardedAt?: any | null | undefined;
      id: string;
      title: string;
      body: string;
      imageUrl: string;
      publishedAt?: any | null | undefined;
      strapline: string;
      slug: string;
      createdAt: any;
      area?:
        | {
            __typename?: "Area";
            id: string;
            name: string;
            slug: string;
            socialVisits: boolean;
          }
        | null
        | undefined;
      author: {
        __typename?: "Runner";
        id: string;
        name: string;
        slug: string;
        avatarUrl: string;
        isMember: boolean;
        neverRun: boolean;
        verified: boolean;
        pronouns?: string | null | undefined;
        roles: Array<string>;
      };
    }>;
  };
};

export type HomePageQueryVariables = Exact<{ [key: string]: never }>;

export type HomePageQuery = {
  __typename?: "Query";
  stats: {
    __typename?: "Stats";
    goodgymers: number;
    areas: number;
    tasks: number;
    beneficiaries: number;
  };
};

export type MyTeamsPageQueryVariables = Exact<{ [key: string]: never }>;

export type MyTeamsPageQuery = {
  __typename?: "Query";
  team: {
    __typename?: "Team";
    supporters: Array<{
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      followsYou: boolean;
      blockedByYou: boolean;
      followedByYou: boolean;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    }>;
    suggestions: Array<{
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      followsYou: boolean;
      blockedByYou: boolean;
      followedByYou: boolean;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    }>;
    supportings: Array<{
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      followsYou: boolean;
      blockedByYou: boolean;
      followedByYou: boolean;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    }>;
  };
};

export type OpsSupportPageQueryVariables = Exact<{ [key: string]: never }>;

export type OpsSupportPageQuery = { __typename?: "Query"; ping: string };

export type OrganisationPageQueryVariables = Exact<{
  slug: Scalars["String"];
}>;

export type OrganisationPageQuery = {
  __typename?: "Query";
  organisation: {
    __typename?: "Organisation";
    description?: string | null | undefined;
    url?: string | null | undefined;
    logoUrl?: string | null | undefined;
    imageUrl: string;
    totalSessions: number;
    totalRunners: number;
    id: string;
    slug: string;
    strapline: string;
    name: string;
    area?:
      | {
          __typename?: "Area";
          id: string;
          name: string;
          slug: string;
          socialVisits: boolean;
        }
      | null
      | undefined;
    topSupporters: Array<{
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    }>;
    upcomingSessions: Array<{
      __typename?: "Session";
      id: string;
      slug: string;
      title: string;
      strapline: string;
      registerMax?: number | null | undefined;
      type: string;
      imageUrl: string;
      coverImageUrl: string;
      startedAt: any;
      duration?: number | null | undefined;
    }>;
  };
};

export type ProfilePageQueryVariables = Exact<{
  slug: Scalars["ID"];
  currentRunnerPresent: Scalars["Boolean"];
}>;

export type ProfilePageQuery = {
  __typename?: "Query";
  runner: {
    __typename?: "Runner";
    joinedAt: any;
    bio: string;
    twitter?: string | null | undefined;
    instagram?: string | null | undefined;
    isMember: boolean;
    neverRun: boolean;
    verified: boolean;
    roles: Array<string>;
    id: string;
    name: string;
    slug: string;
    avatarUrl: string;
    pronouns?: string | null | undefined;
    followsYou: boolean;
    followedByYou: boolean;
    blockedByYou: boolean;
    homeArea?:
      | {
          __typename?: "Area";
          id: string;
          name: string;
          slug: string;
          socialVisits: boolean;
        }
      | null
      | undefined;
    awards: Array<{
      __typename?: "Award";
      id: string;
      badge: {
        __typename?: "Badge";
        id: string;
        name: string;
        description: string;
        imageUrl: string;
      };
    }>;
    stats: {
      __typename?: "RunnerStats";
      id: string;
      runs: number;
      goodDeeds: number;
      cheersReceived: number;
      cheersGiven: number;
      sessionsLed: number;
      sessionsListed: number;
      sessionsBackmarked: number;
      sessionsPhotographed: number;
      walksLed: number;
      reportsWritten: number;
    };
    streaks: Array<{
      __typename?: "RunnerStreak";
      id: string;
      title: string;
      description: string;
      status: string;
      imageUrl: string;
      count: number;
      verified: boolean;
      current: boolean;
    }>;
    employment?:
      | {
          __typename?: "Employment";
          id: string;
          startedAt: any;
          employer: {
            __typename?: "Employer";
            id: string;
            name: string;
            imageUrl: string;
            slug: string;
          };
        }
      | null
      | undefined;
    upcomingSessions: Array<{
      __typename?: "Session";
      signedUp: boolean;
      coverImageUrl: string;
      id: string;
      slug: string;
      title: string;
      strapline: string;
      registerMax?: number | null | undefined;
      type: string;
      imageUrl: string;
      startedAt: any;
      duration?: number | null | undefined;
      address?:
        | {
            __typename?: "Address";
            id: string;
            line1: string;
            line2?: string | null | undefined;
            city: string;
            postcode: string;
            lat?: number | null | undefined;
            lng?: number | null | undefined;
          }
        | null
        | undefined;
      signups: Array<{
        __typename?: "Runner";
        id: string;
        name: string;
        slug: string;
        avatarUrl: string;
        isMember: boolean;
        neverRun: boolean;
        verified: boolean;
        pronouns?: string | null | undefined;
        roles: Array<string>;
      }>;
      area?:
        | {
            __typename?: "Area";
            id: string;
            name: string;
            slug: string;
            socialVisits: boolean;
          }
        | null
        | undefined;
      report?:
        | {
            __typename?: "Report";
            id: string;
            slug: string;
            title: string;
            description: string;
            imageUrl: string;
            coverImageUrl: string;
            publishedAt?: any | null | undefined;
            pictures: Array<string>;
            writtenBy?:
              | {
                  __typename?: "Runner";
                  id: string;
                  name: string;
                  slug: string;
                  avatarUrl: string;
                  isMember: boolean;
                  neverRun: boolean;
                  verified: boolean;
                  pronouns?: string | null | undefined;
                  roles: Array<string>;
                }
              | null
              | undefined;
          }
        | null
        | undefined;
    }>;
    user?: { __typename?: "User"; email: string };
  };
};

export type ReportPageQueryVariables = Exact<{
  slug: Scalars["String"];
}>;

export type ReportPageQuery = {
  __typename?: "Query";
  report: {
    __typename?: "Report";
    title: string;
    description: string;
    slug: string;
    imageUrl: string;
    id: string;
    coverImageUrl: string;
    publishedAt?: any | null | undefined;
    pictures: Array<string>;
    writtenBy?:
      | {
          __typename?: "Runner";
          id: string;
          name: string;
          slug: string;
          avatarUrl: string;
          isMember: boolean;
          neverRun: boolean;
          verified: boolean;
          pronouns?: string | null | undefined;
          roles: Array<string>;
        }
      | null
      | undefined;
    session: {
      __typename?: "Session";
      signedUp: boolean;
      id: string;
      slug: string;
      title: string;
      strapline: string;
      registerMax?: number | null | undefined;
      type: string;
      imageUrl: string;
      coverImageUrl: string;
      startedAt: any;
      duration?: number | null | undefined;
      organisation?:
        | {
            __typename?: "Organisation";
            url?: string | null | undefined;
            logoUrl?: string | null | undefined;
            description?: string | null | undefined;
            id: string;
            slug: string;
            strapline: string;
            name: string;
            area?:
              | {
                  __typename?: "Area";
                  id: string;
                  name: string;
                  slug: string;
                  socialVisits: boolean;
                }
              | null
              | undefined;
          }
        | null
        | undefined;
      signups: Array<{
        __typename?: "Runner";
        id: string;
        name: string;
        slug: string;
        avatarUrl: string;
        isMember: boolean;
        neverRun: boolean;
        verified: boolean;
        pronouns?: string | null | undefined;
        roles: Array<string>;
      }>;
      ledBy?:
        | {
            __typename?: "Runner";
            bio: string;
            roles: Array<string>;
            id: string;
            name: string;
            slug: string;
            avatarUrl: string;
            isMember: boolean;
            neverRun: boolean;
            verified: boolean;
            pronouns?: string | null | undefined;
            followsYou: boolean;
            followedByYou: boolean;
            blockedByYou: boolean;
            homeArea?:
              | {
                  __typename?: "Area";
                  id: string;
                  name: string;
                  slug: string;
                  socialVisits: boolean;
                }
              | null
              | undefined;
          }
        | null
        | undefined;
      area?:
        | {
            __typename?: "Area";
            id: string;
            name: string;
            slug: string;
            socialVisits: boolean;
            upcomingSessions: Array<{
              __typename?: "Session";
              signedUp: boolean;
              id: string;
              slug: string;
              title: string;
              strapline: string;
              registerMax?: number | null | undefined;
              type: string;
              imageUrl: string;
              coverImageUrl: string;
              startedAt: any;
              duration?: number | null | undefined;
              signups: Array<{
                __typename?: "Runner";
                id: string;
                name: string;
                slug: string;
                avatarUrl: string;
                isMember: boolean;
                neverRun: boolean;
                verified: boolean;
                pronouns?: string | null | undefined;
                roles: Array<string>;
              }>;
              area?:
                | {
                    __typename?: "Area";
                    id: string;
                    name: string;
                    slug: string;
                    socialVisits: boolean;
                  }
                | null
                | undefined;
            }>;
          }
        | null
        | undefined;
      attendees: Array<{
        __typename?: "Runner";
        id: string;
        name: string;
        slug: string;
        avatarUrl: string;
        isMember: boolean;
        neverRun: boolean;
        verified: boolean;
        pronouns?: string | null | undefined;
        roles: Array<string>;
      }>;
    };
    comments: Array<{
      __typename?: "Comment";
      id: string;
      content: string;
      createdAt: any;
      runner: {
        __typename?: "Runner";
        id: string;
        name: string;
        slug: string;
        avatarUrl: string;
        isMember: boolean;
        neverRun: boolean;
        verified: boolean;
        pronouns?: string | null | undefined;
        roles: Array<string>;
      };
    }>;
  };
};

export type ReportsPageQueryVariables = Exact<{ [key: string]: never }>;

export type ReportsPageQuery = { __typename?: "Query"; ping: string };

export type SessionPageAdminMenuQueryVariables = Exact<{
  sessionId: Scalars["ID"];
}>;

export type SessionPageAdminMenuQuery = {
  __typename?: "Query";
  options: Array<{
    __typename?: "MenuOption";
    id: string;
    label: string;
    url: string;
    icon: string;
    completed: boolean;
    disabled: boolean;
    method: HttpMethod;
  }>;
};

export type SessionPageQueryVariables = Exact<{
  slug: Scalars["String"];
}>;

export type SessionPageQuery = {
  __typename?: "Query";
  session: {
    __typename?: "Session";
    imageUrls: Array<string>;
    cancelledAt?: any | null | undefined;
    registerDeadline?: any | null | undefined;
    description: string;
    signedUp: boolean;
    onWaitingList: boolean;
    walkable: boolean;
    minDistance?: number | null | undefined;
    maxDistance?: number | null | undefined;
    id: string;
    slug: string;
    title: string;
    strapline: string;
    registerMax?: number | null | undefined;
    type: string;
    imageUrl: string;
    coverImageUrl: string;
    startedAt: any;
    duration?: number | null | undefined;
    report?:
      | {
          __typename?: "Report";
          id: string;
          slug: string;
          title: string;
          description: string;
          imageUrl: string;
          coverImageUrl: string;
          publishedAt?: any | null | undefined;
          pictures: Array<string>;
          writtenBy?:
            | {
                __typename?: "Runner";
                id: string;
                name: string;
                slug: string;
                avatarUrl: string;
                isMember: boolean;
                neverRun: boolean;
                verified: boolean;
                pronouns?: string | null | undefined;
                roles: Array<string>;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    organisation?:
      | {
          __typename?: "Organisation";
          description?: string | null | undefined;
          logoUrl?: string | null | undefined;
          id: string;
          slug: string;
          strapline: string;
          name: string;
        }
      | null
      | undefined;
    risks: Array<{
      __typename?: "SessionRisk";
      id: string;
      name: string;
      description?: string | null | undefined;
      imageUrl: string;
    }>;
    address?:
      | {
          __typename?: "Address";
          accessNotes?: string | null | undefined;
          otherInformation?: string | null | undefined;
          id: string;
          line1: string;
          line2?: string | null | undefined;
          city: string;
          postcode: string;
          lat?: number | null | undefined;
          lng?: number | null | undefined;
        }
      | null
      | undefined;
    signups: Array<{
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    }>;
    ledBy?:
      | {
          __typename?: "Runner";
          bio: string;
          id: string;
          name: string;
          slug: string;
          avatarUrl: string;
          isMember: boolean;
          neverRun: boolean;
          verified: boolean;
          pronouns?: string | null | undefined;
          roles: Array<string>;
        }
      | null
      | undefined;
    area?:
      | {
          __typename?: "Area";
          email?: string | null | undefined;
          id: string;
          name: string;
          slug: string;
          socialVisits: boolean;
          areaActivator?:
            | {
                __typename?: "Runner";
                id: string;
                name: string;
                slug: string;
                avatarUrl: string;
                isMember: boolean;
                neverRun: boolean;
                verified: boolean;
                pronouns?: string | null | undefined;
                roles: Array<string>;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    groupFitnessActivities: Array<{
      __typename?: "GroupFitnessActivity";
      id: string;
      type: string;
      distance: number;
      startedAt: any;
      description: string;
      ledBy: {
        __typename?: "Runner";
        id: string;
        name: string;
        slug: string;
        avatarUrl: string;
        isMember: boolean;
        neverRun: boolean;
        verified: boolean;
        pronouns?: string | null | undefined;
        roles: Array<string>;
      };
      signups: Array<{
        __typename?: "Runner";
        id: string;
        name: string;
        slug: string;
        avatarUrl: string;
        isMember: boolean;
        neverRun: boolean;
        verified: boolean;
        pronouns?: string | null | undefined;
        roles: Array<string>;
      }>;
      address: {
        __typename?: "Address";
        id: string;
        line1: string;
        line2?: string | null | undefined;
        city: string;
        postcode: string;
        lat?: number | null | undefined;
        lng?: number | null | undefined;
      };
    }>;
    taskforceRoles: Array<{
      __typename?: "TaskforceRole";
      id: string;
      name: string;
      description: string;
      slug: string;
      runner?:
        | {
            __typename?: "Runner";
            id: string;
            name: string;
            slug: string;
            avatarUrl: string;
            isMember: boolean;
            neverRun: boolean;
            verified: boolean;
            pronouns?: string | null | undefined;
            roles: Array<string>;
          }
        | null
        | undefined;
    }>;
    taskRequest?:
      | {
          __typename?: "TaskRequest";
          risky: Risky;
          id: string;
          slug: string;
          title: string;
          outcome: string;
          deadline: any;
          registerMin: number;
          registerMax: number;
          lat?: number | null | undefined;
          lng?: number | null | undefined;
          potentialTimes: Array<any>;
          beneficiary: { __typename?: "Beneficiary"; id: string; name: string };
        }
      | null
      | undefined;
    comments: Array<{
      __typename?: "Comment";
      id: string;
      content: string;
      createdAt: any;
      runner: {
        __typename?: "Runner";
        id: string;
        name: string;
        slug: string;
        avatarUrl: string;
        isMember: boolean;
        neverRun: boolean;
        verified: boolean;
        pronouns?: string | null | undefined;
        roles: Array<string>;
      };
    }>;
  };
};

export type AvailableCoachPageQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type AvailableCoachPageQuery = {
  __typename?: "Query";
  availableCoach: {
    __typename?: "Coach";
    availableForVisit: string;
    distanceFromRunner?: string | null | undefined;
    id: string;
    interests: string;
    lat?: number | null | undefined;
    lng?: number | null | undefined;
    name: string;
    reasonForReferral: string;
    homeRiskAssesed: boolean;
    additionalInfo: string;
    area: {
      __typename?: "Area";
      id: string;
      name: string;
      slug: string;
      socialVisits: boolean;
    };
  };
};

export type TestPairingsQueryVariables = Exact<{
  page?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
}>;

export type TestPairingsQuery = {
  __typename?: "Query";
  testPairings: {
    __typename?: "TestPairingsResult";
    more: boolean;
    results: Array<{
      __typename?: "TestPairing";
      acceptedAt?: any | null | undefined;
      completedAt?: any | null | undefined;
      id: string;
      notesForRunner?: string | null | undefined;
      rejectedAt?: any | null | undefined;
      rejectionNotes?: string | null | undefined;
      scheduledAt?: any | null | undefined;
      runnerVisitFeedback?: string | null | undefined;
      runnerVisitNotes?: string | null | undefined;
      runnerWantsPairing?: boolean | null | undefined;
      cancelledAt?: any | null | undefined;
      cancellationNotes?: string | null | undefined;
      coach: { __typename?: "Coach"; id: string; name: string };
    }>;
  };
};

export type TestPairingQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type TestPairingQuery = {
  __typename?: "Query";
  testPairing: {
    __typename?: "TestPairing";
    acceptedAt?: any | null | undefined;
    completedAt?: any | null | undefined;
    id: string;
    notesForRunner?: string | null | undefined;
    rejectedAt?: any | null | undefined;
    rejectionNotes?: string | null | undefined;
    scheduledAt?: any | null | undefined;
    runnerVisitFeedback?: string | null | undefined;
    runnerVisitNotes?: string | null | undefined;
    runnerWantsPairing?: boolean | null | undefined;
    cancelledAt?: any | null | undefined;
    cancellationNotes?: string | null | undefined;
    coach: { __typename?: "Coach"; id: string; name: string };
  };
};

export type AvailableCoachesQueryVariables = Exact<{
  areaIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  postcode?: Maybe<Scalars["String"]>;
  maxDistance?: Maybe<Scalars["Int"]>;
  page?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
}>;

export type AvailableCoachesQuery = {
  __typename?: "Query";
  availableCoaches: {
    __typename?: "AvailableCoachesResult";
    more: boolean;
    results: Array<{
      __typename?: "Coach";
      availableForVisit: string;
      distanceFromRunner?: string | null | undefined;
      id: string;
      interests: string;
      lat?: number | null | undefined;
      lng?: number | null | undefined;
      name: string;
      reasonForReferral: string;
      homeRiskAssesed: boolean;
      additionalInfo: string;
      area: {
        __typename?: "Area";
        id: string;
        name: string;
        slug: string;
        socialVisits: boolean;
      };
    }>;
  };
};

export type AvailableCoachQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type AvailableCoachQuery = {
  __typename?: "Query";
  availableCoach: {
    __typename?: "Coach";
    availableForVisit: string;
    distanceFromRunner?: string | null | undefined;
    id: string;
    interests: string;
    lat?: number | null | undefined;
    lng?: number | null | undefined;
    name: string;
    reasonForReferral: string;
    homeRiskAssesed: boolean;
    additionalInfo: string;
    area: {
      __typename?: "Area";
      id: string;
      name: string;
      slug: string;
      socialVisits: boolean;
    };
  };
};

export type StoriesPageQueryVariables = Exact<{
  before?: Maybe<Scalars["ISO8601DateTime"]>;
}>;

export type StoriesPageQuery = {
  __typename?: "Query";
  stories: {
    __typename?: "StoriesResult";
    more: boolean;
    stories: Array<{
      __typename?: "Story";
      discardedAt?: any | null | undefined;
      createdAt: any;
      id: string;
      title: string;
      body: string;
      imageUrl: string;
      publishedAt?: any | null | undefined;
      strapline: string;
      slug: string;
      area?:
        | {
            __typename?: "Area";
            id: string;
            name: string;
            slug: string;
            socialVisits: boolean;
          }
        | null
        | undefined;
      author: {
        __typename?: "Runner";
        id: string;
        name: string;
        slug: string;
        avatarUrl: string;
        isMember: boolean;
        neverRun: boolean;
        verified: boolean;
        pronouns?: string | null | undefined;
        roles: Array<string>;
      };
    }>;
  };
};

export type DeleteStoryMutationVariables = Exact<{
  storyId: Scalars["ID"];
}>;

export type DeleteStoryMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "DeleteStoryPayload";
    story: {
      __typename?: "Story";
      id: string;
      title: string;
      body: string;
      imageUrl: string;
      publishedAt?: any | null | undefined;
      strapline: string;
      slug: string;
      discardedAt?: any | null | undefined;
      createdAt: any;
      area?:
        | {
            __typename?: "Area";
            id: string;
            name: string;
            slug: string;
            socialVisits: boolean;
          }
        | null
        | undefined;
      author: {
        __typename?: "Runner";
        id: string;
        name: string;
        slug: string;
        avatarUrl: string;
        isMember: boolean;
        neverRun: boolean;
        verified: boolean;
        pronouns?: string | null | undefined;
        roles: Array<string>;
      };
    };
  };
};

export type PublishStoryMutationVariables = Exact<{
  storyId: Scalars["ID"];
}>;

export type PublishStoryMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "PublishStoryPayload";
    story: {
      __typename?: "Story";
      id: string;
      title: string;
      body: string;
      imageUrl: string;
      publishedAt?: any | null | undefined;
      strapline: string;
      slug: string;
      discardedAt?: any | null | undefined;
      createdAt: any;
      area?:
        | {
            __typename?: "Area";
            id: string;
            name: string;
            slug: string;
            socialVisits: boolean;
          }
        | null
        | undefined;
      author: {
        __typename?: "Runner";
        id: string;
        name: string;
        slug: string;
        avatarUrl: string;
        isMember: boolean;
        neverRun: boolean;
        verified: boolean;
        pronouns?: string | null | undefined;
        roles: Array<string>;
      };
    };
  };
};

export type StoryPageQueryVariables = Exact<{
  slug: Scalars["String"];
}>;

export type StoryPageQuery = {
  __typename?: "Query";
  story: {
    __typename?: "Story";
    discardedAt?: any | null | undefined;
    id: string;
    title: string;
    body: string;
    imageUrl: string;
    publishedAt?: any | null | undefined;
    strapline: string;
    slug: string;
    createdAt: any;
    area?:
      | {
          __typename?: "Area";
          id: string;
          name: string;
          slug: string;
          socialVisits: boolean;
        }
      | null
      | undefined;
    author: {
      __typename?: "Runner";
      id: string;
      name: string;
      slug: string;
      avatarUrl: string;
      isMember: boolean;
      neverRun: boolean;
      verified: boolean;
      pronouns?: string | null | undefined;
      roles: Array<string>;
    };
  };
};

export type CancelTestPairingMutationVariables = Exact<{
  id: Scalars["ID"];
  cancellationNotes: Scalars["String"];
}>;

export type CancelTestPairingMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "CancelTestPairingMutationPayload";
    testPairing?:
      | {
          __typename?: "TestPairing";
          acceptedAt?: any | null | undefined;
          completedAt?: any | null | undefined;
          id: string;
          notesForRunner?: string | null | undefined;
          rejectedAt?: any | null | undefined;
          rejectionNotes?: string | null | undefined;
          scheduledAt?: any | null | undefined;
          runnerVisitFeedback?: string | null | undefined;
          runnerVisitNotes?: string | null | undefined;
          runnerWantsPairing?: boolean | null | undefined;
          cancelledAt?: any | null | undefined;
          cancellationNotes?: string | null | undefined;
          coach: { __typename?: "Coach"; id: string; name: string };
        }
      | null
      | undefined;
  };
};

export type RescheduleTestPairingMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RescheduleTestPairingMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "CancelTestPairingMutationPayload";
    testPairing?:
      | {
          __typename?: "TestPairing";
          acceptedAt?: any | null | undefined;
          completedAt?: any | null | undefined;
          id: string;
          notesForRunner?: string | null | undefined;
          rejectedAt?: any | null | undefined;
          rejectionNotes?: string | null | undefined;
          scheduledAt?: any | null | undefined;
          runnerVisitFeedback?: string | null | undefined;
          runnerVisitNotes?: string | null | undefined;
          runnerWantsPairing?: boolean | null | undefined;
          cancelledAt?: any | null | undefined;
          cancellationNotes?: string | null | undefined;
          coach: { __typename?: "Coach"; id: string; name: string };
        }
      | null
      | undefined;
  };
};

export type CompleteTestPairingMutationVariables = Exact<{
  id: Scalars["ID"];
  runnerVisitFeedback: Scalars["String"];
  runnerVisitNotes: Scalars["String"];
  runnerWantsPairing: Scalars["Boolean"];
}>;

export type CompleteTestPairingMutation = {
  __typename?: "Mutation";
  response: {
    __typename?: "CompleteTestPairingMutationPayload";
    testPairing?:
      | {
          __typename?: "TestPairing";
          acceptedAt?: any | null | undefined;
          completedAt?: any | null | undefined;
          id: string;
          notesForRunner?: string | null | undefined;
          rejectedAt?: any | null | undefined;
          rejectionNotes?: string | null | undefined;
          scheduledAt?: any | null | undefined;
          runnerVisitFeedback?: string | null | undefined;
          runnerVisitNotes?: string | null | undefined;
          runnerWantsPairing?: boolean | null | undefined;
          cancelledAt?: any | null | undefined;
          cancellationNotes?: string | null | undefined;
          coach: { __typename?: "Coach"; id: string; name: string };
        }
      | null
      | undefined;
  };
};

export type TestPairingPageQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type TestPairingPageQuery = {
  __typename?: "Query";
  testPairing: {
    __typename?: "TestPairing";
    id: string;
    acceptedAt?: any | null | undefined;
    rejectedAt?: any | null | undefined;
    cancelledAt?: any | null | undefined;
    scheduledAt?: any | null | undefined;
    completedAt?: any | null | undefined;
    notesForRunner?: string | null | undefined;
    runnerVisitFeedback?: string | null | undefined;
    runnerVisitNotes?: string | null | undefined;
    runnerWantsPairing?: boolean | null | undefined;
    rejectionNotes?: string | null | undefined;
    cancellationNotes?: string | null | undefined;
    coach: {
      __typename?: "Coach";
      availableForVisit: string;
      distanceFromRunner?: string | null | undefined;
      id: string;
      interests: string;
      lat?: number | null | undefined;
      lng?: number | null | undefined;
      name: string;
      reasonForReferral: string;
      homeRiskAssesed: boolean;
      additionalInfo: string;
      area: {
        __typename?: "Area";
        id: string;
        name: string;
        slug: string;
        socialVisits: boolean;
      };
    };
  };
};

export const RunnerFragmentDoc = gql`
  fragment Runner on Runner {
    id
    name
    slug
    avatarUrl
    isMember
    neverRun
    verified
    pronouns
    roles
  }
`;
export const CheerFragmentDoc = gql`
  fragment Cheer on Cheer {
    id
    runner {
      ...Runner
    }
    recipient {
      ...Runner
    }
  }
  ${RunnerFragmentDoc}
`;
export const CheerableFragmentDoc = gql`
  fragment Cheerable on Cheerable {
    id
    cheers {
      ...Cheer
    }
  }
  ${CheerFragmentDoc}
`;
export const SessionFragmentDoc = gql`
  fragment Session on Session {
    id
    slug
    title
    strapline
    registerMax
    type
    imageUrl
    coverImageUrl
    startedAt
    duration
  }
`;
export const CommentFragmentDoc = gql`
  fragment Comment on Comment {
    id
    content
    runner {
      ...Runner
    }
    createdAt
  }
  ${RunnerFragmentDoc}
`;
export const CommentableFragmentDoc = gql`
  fragment Commentable on Commentable {
    id
    comments {
      ...Comment
    }
  }
  ${CommentFragmentDoc}
`;
export const AreaFragmentDoc = gql`
  fragment Area on Area {
    id
    name
    slug
    socialVisits
  }
`;
export const ReportFragmentDoc = gql`
  fragment Report on Report {
    id
    slug
    title
    description
    imageUrl
    coverImageUrl
    publishedAt
    writtenBy {
      ...Runner
    }
    pictures
  }
  ${RunnerFragmentDoc}
`;
export const NewsfeedAttendanceFragmentDoc = gql`
  fragment NewsfeedAttendance on NewsfeedAttendance {
    id
    time
    ...Cheerable
    session {
      ...Session
      ...Commentable
      area {
        ...Area
      }
      report {
        ...Report
        ...Commentable
      }
    }
    runners {
      ...Runner
    }
    __typename
  }
  ${CheerableFragmentDoc}
  ${SessionFragmentDoc}
  ${CommentableFragmentDoc}
  ${AreaFragmentDoc}
  ${ReportFragmentDoc}
  ${RunnerFragmentDoc}
`;
export const BadgeFragmentDoc = gql`
  fragment Badge on Badge {
    id
    name
    description
    imageUrl
  }
`;
export const NewsfeedAwardFragmentDoc = gql`
  fragment NewsfeedAward on NewsfeedAward {
    id
    time
    description
    ...Cheerable
    ...Commentable
    badge {
      ...Badge
    }
    runner {
      ...Runner
    }
    __typename
  }
  ${CheerableFragmentDoc}
  ${CommentableFragmentDoc}
  ${BadgeFragmentDoc}
  ${RunnerFragmentDoc}
`;
export const NewsfeedCoachVisitFragmentDoc = gql`
  fragment NewsfeedCoachVisit on NewsfeedCoachVisit {
    id
    time
    ...Cheerable
    ...Commentable
    runner {
      ...Runner
    }
    __typename
  }
  ${CheerableFragmentDoc}
  ${CommentableFragmentDoc}
  ${RunnerFragmentDoc}
`;
export const AddressFragmentDoc = gql`
  fragment Address on Address {
    id
    line1
    line2
    city
    postcode
    lat
    lng
  }
`;
export const NewsfeedRegistrationFragmentDoc = gql`
  fragment NewsfeedRegistration on NewsfeedRegistration {
    id
    time
    ...Cheerable
    session {
      ...Session
      ...Commentable
      registerMax
      signups {
        ...Runner
      }
      address {
        ...Address
      }
      area {
        ...Area
      }
    }
    runners {
      ...Runner
    }
    __typename
  }
  ${CheerableFragmentDoc}
  ${SessionFragmentDoc}
  ${CommentableFragmentDoc}
  ${RunnerFragmentDoc}
  ${AddressFragmentDoc}
  ${AreaFragmentDoc}
`;
export const NewsfeedWorkoutFragmentDoc = gql`
  fragment NewsfeedWorkout on NewsfeedWorkout {
    id
    name
    time
    type
    distance
    duration
    uri
    provider
    averagePace
    ...Cheerable
    ...Commentable
    runner {
      ...Runner
    }
    __typename
  }
  ${CheerableFragmentDoc}
  ${CommentableFragmentDoc}
  ${RunnerFragmentDoc}
`;
export const NewsfeedItemFragmentDoc = gql`
  fragment NewsfeedItem on NewsfeedItem {
    ... on NewsfeedAttendance {
      ...NewsfeedAttendance
    }
    ... on NewsfeedAward {
      ...NewsfeedAward
    }
    ... on NewsfeedCoachVisit {
      ...NewsfeedCoachVisit
    }
    ... on NewsfeedRegistration {
      ...NewsfeedRegistration
    }
    ... on NewsfeedWorkout {
      ...NewsfeedWorkout
    }
  }
  ${NewsfeedAttendanceFragmentDoc}
  ${NewsfeedAwardFragmentDoc}
  ${NewsfeedCoachVisitFragmentDoc}
  ${NewsfeedRegistrationFragmentDoc}
  ${NewsfeedWorkoutFragmentDoc}
`;
export const NewsfeedFragmentDoc = gql`
  fragment Newsfeed on Newsfeed {
    id
    page
    limit
    more
    items {
      ...NewsfeedItem
    }
  }
  ${NewsfeedItemFragmentDoc}
`;
export const AddressSearchResultFragmentDoc = gql`
  fragment AddressSearchResult on AddressSearchResult {
    id
    postcode
    line1
    line2
    city
    county
    country
    area {
      id
      name
      active
      slug
      socialVisits
    }
  }
`;
export const BeneficiaryFragmentDoc = gql`
  fragment Beneficiary on Beneficiary {
    id
    name
  }
`;
export const CtaFragmentDoc = gql`
  fragment CTA on CTA {
    id
    title
    description
    icon
    url
    actionText
    dismissText
  }
`;
export const CoachFragmentDoc = gql`
  fragment Coach on Coach {
    id
    name
  }
`;
export const CoachReferralFragmentDoc = gql`
  fragment CoachReferral on Coach {
    area {
      ...Area
    }
    availableForVisit
    distanceFromRunner
    id
    interests
    lat
    lng
    name
    reasonForReferral
    homeRiskAssesed
    additionalInfo
  }
  ${AreaFragmentDoc}
`;
export const EmploymentFragmentDoc = gql`
  fragment Employment on Employment {
    id
    startedAt
    employer {
      id
      name
      imageUrl
      slug
    }
  }
`;
export const CurrentRunnerFragmentDoc = gql`
  fragment CurrentRunner on CurrentRunner {
    id
    name
    bio
    postcode
    slug
    pronouns
    avatarUrl
    roles
    employment {
      ...Employment
    }
    homeArea {
      ...Area
      socialVisits
      pairingVerifiedRunnersCount
    }
    areas {
      ...Area
    }
    isMember
    neverRun
    verified
    featureFlags
    needsToGetCoachVerified
  }
  ${EmploymentFragmentDoc}
  ${AreaFragmentDoc}
`;
export const TrainingStatusFragmentDoc = gql`
  fragment TrainingStatus on RunnerTrainingStatus {
    id
    slug
    status
  }
`;
export const CurrentRunnerTrainingStatusFragmentDoc = gql`
  fragment CurrentRunnerTrainingStatus on CurrentRunner {
    id
    trainingStatuses {
      ...TrainingStatus
    }
  }
  ${TrainingStatusFragmentDoc}
`;
export const FollowStatusFragmentDoc = gql`
  fragment FollowStatus on Runner {
    id
    followsYou
    followedByYou
    blockedByYou
  }
`;
export const GroupFitnessActivityFragmentDoc = gql`
  fragment GroupFitnessActivity on GroupFitnessActivity {
    id
    type
    distance
    startedAt
    description
    address {
      ...Address
    }
  }
  ${AddressFragmentDoc}
`;
export const MenuOptionFragmentDoc = gql`
  fragment MenuOption on MenuOption {
    id
    label
    url
    icon
    completed
    disabled
    method
  }
`;
export const NotificationFragmentDoc = gql`
  fragment Notification on Notification {
    id
    content
    url
    imageUrl
    read
    createdAt
  }
`;
export const OrganisationFragmentDoc = gql`
  fragment Organisation on Organisation {
    id
    slug
    strapline
    name
  }
`;
export const RestrictedBeneficiaryFragmentDoc = gql`
  fragment RestrictedBeneficiary on RestrictedBeneficiary {
    id
    slug
    name
    phoneNumber
    dateOfBirth
    area {
      ...Area
    }
    address {
      ...Address
    }
  }
  ${AreaFragmentDoc}
  ${AddressFragmentDoc}
`;
export const RunnerStatsFragmentDoc = gql`
  fragment RunnerStats on RunnerStats {
    id
    runs
    goodDeeds
    cheersReceived
    cheersGiven
  }
`;
export const RunnerStatusFragmentDoc = gql`
  fragment RunnerStatus on Runner {
    id
    isMember
    neverRun
    verified
    roles
  }
`;
export const RunnerStreakFragmentDoc = gql`
  fragment RunnerStreak on RunnerStreak {
    id
    title
    description
    status
    imageUrl
    count
    verified
    current
  }
`;
export const SessionCardFragmentDoc = gql`
  fragment SessionCard on Session {
    ...Session
    address {
      ...Address
    }
    signups {
      ...Runner
    }
    area {
      ...Area
    }
    report {
      ...Report
    }
    signedUp
    coverImageUrl
  }
  ${SessionFragmentDoc}
  ${AddressFragmentDoc}
  ${RunnerFragmentDoc}
  ${AreaFragmentDoc}
  ${ReportFragmentDoc}
`;
export const SessionRiskFragmentDoc = gql`
  fragment SessionRisk on SessionRisk {
    id
    name
    description
    imageUrl
  }
`;
export const StoryFragmentDoc = gql`
  fragment Story on Story {
    id
    area {
      ...Area
    }
    title
    body
    imageUrl
    publishedAt
    strapline
    slug
    author {
      ...Runner
    }
    discardedAt
    createdAt
  }
  ${AreaFragmentDoc}
  ${RunnerFragmentDoc}
`;
export const TaskForceStatsFragmentDoc = gql`
  fragment TaskForceStats on RunnerStats {
    id
    sessionsLed
    sessionsListed
    sessionsBackmarked
    sessionsPhotographed
    walksLed
    reportsWritten
  }
`;
export const TaskRequestFragmentDoc = gql`
  fragment TaskRequest on TaskRequest {
    id
    slug
    title
    outcome
    deadline
    registerMin
    registerMax
    lat
    lng
    potentialTimes
  }
`;
export const TaskRequestCardFragmentDoc = gql`
  fragment TaskRequestCard on TaskRequest {
    ...TaskRequest
    signedUp
    declined
    area {
      ...Area
    }
    reservations {
      id
      runners {
        ...Runner
      }
      startedAt
    }
  }
  ${TaskRequestFragmentDoc}
  ${AreaFragmentDoc}
  ${RunnerFragmentDoc}
`;
export const TaskforceRoleFragmentDoc = gql`
  fragment TaskforceRole on TaskforceRole {
    id
    name
    description
    slug
    runner {
      ...Runner
    }
  }
  ${RunnerFragmentDoc}
`;
export const TestPairingFragmentDoc = gql`
  fragment TestPairing on TestPairing {
    acceptedAt
    coach {
      id
      name
    }
    completedAt
    id
    notesForRunner
    rejectedAt
    rejectionNotes
    scheduledAt
    runnerVisitFeedback
    runnerVisitNotes
    runnerWantsPairing
    cancelledAt
    cancellationNotes
  }
`;
export const TrainingFragmentDoc = gql`
  fragment Training on Training {
    id
    slug
    name
    description
  }
`;
export const TrainingModuleFragmentDoc = gql`
  fragment TrainingModule on TrainingModule {
    id
    name
    description
    order
  }
`;
export const TrainingModuleQuestionFragmentDoc = gql`
  fragment TrainingModuleQuestion on TrainingModuleQuestion {
    id
    question
    description
    type
    order
  }
`;
export const TrainingModuleSectionFragmentDoc = gql`
  fragment TrainingModuleSection on TrainingModuleSection {
    id
    name
    description
    order
  }
`;
export const AuthDocument = gql`
  query Auth {
    user: currentUser {
      id
      email
    }
    runner: currentRunner {
      ...CurrentRunner
      donation {
        id
        giftaid
      }
      needsToGetMissionVerified
      needsToGetCoachVerified
      pairing {
        id
        coach {
          ...Coach
        }
      }
    }
    referrer: currentReferrer {
      id
      name
    }
  }
  ${CurrentRunnerFragmentDoc}
  ${CoachFragmentDoc}
`;

/**
 * __useAuthQuery__
 *
 * To run a query within a React component, call `useAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthQuery(
  baseOptions?: Apollo.QueryHookOptions<AuthQuery, AuthQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuthQuery, AuthQueryVariables>(AuthDocument, options);
}
export function useAuthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuthQuery, AuthQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuthQuery, AuthQueryVariables>(
    AuthDocument,
    options
  );
}
export type AuthQueryHookResult = ReturnType<typeof useAuthQuery>;
export type AuthLazyQueryHookResult = ReturnType<typeof useAuthLazyQuery>;
export type AuthQueryResult = Apollo.QueryResult<AuthQuery, AuthQueryVariables>;
export const AddressSearchDocument = gql`
  query AddressSearch($query: String!) {
    results: autocompleteAddress(query: $query) {
      ...AddressSearchResult
    }
  }
  ${AddressSearchResultFragmentDoc}
`;

/**
 * __useAddressSearchQuery__
 *
 * To run a query within a React component, call `useAddressSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAddressSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    AddressSearchQuery,
    AddressSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AddressSearchQuery, AddressSearchQueryVariables>(
    AddressSearchDocument,
    options
  );
}
export function useAddressSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AddressSearchQuery,
    AddressSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AddressSearchQuery, AddressSearchQueryVariables>(
    AddressSearchDocument,
    options
  );
}
export type AddressSearchQueryHookResult = ReturnType<
  typeof useAddressSearchQuery
>;
export type AddressSearchLazyQueryHookResult = ReturnType<
  typeof useAddressSearchLazyQuery
>;
export type AddressSearchQueryResult = Apollo.QueryResult<
  AddressSearchQuery,
  AddressSearchQueryVariables
>;
export const AreaSearchDocument = gql`
  query AreaSearch {
    areas {
      ...Area
    }
  }
  ${AreaFragmentDoc}
`;

/**
 * __useAreaSearchQuery__
 *
 * To run a query within a React component, call `useAreaSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaSearchQuery({
 *   variables: {
 *   },
 * });
 */
export function useAreaSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AreaSearchQuery,
    AreaSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AreaSearchQuery, AreaSearchQueryVariables>(
    AreaSearchDocument,
    options
  );
}
export function useAreaSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AreaSearchQuery,
    AreaSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AreaSearchQuery, AreaSearchQueryVariables>(
    AreaSearchDocument,
    options
  );
}
export type AreaSearchQueryHookResult = ReturnType<typeof useAreaSearchQuery>;
export type AreaSearchLazyQueryHookResult = ReturnType<
  typeof useAreaSearchLazyQuery
>;
export type AreaSearchQueryResult = Apollo.QueryResult<
  AreaSearchQuery,
  AreaSearchQueryVariables
>;
export const BeneficiarySearchDocument = gql`
  query BeneficiarySearch($query: String!) {
    results: autocompleteBeneficiary(query: $query) {
      ...RestrictedBeneficiary
      address {
        ...Address
      }
    }
  }
  ${RestrictedBeneficiaryFragmentDoc}
  ${AddressFragmentDoc}
`;

/**
 * __useBeneficiarySearchQuery__
 *
 * To run a query within a React component, call `useBeneficiarySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useBeneficiarySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBeneficiarySearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useBeneficiarySearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    BeneficiarySearchQuery,
    BeneficiarySearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BeneficiarySearchQuery,
    BeneficiarySearchQueryVariables
  >(BeneficiarySearchDocument, options);
}
export function useBeneficiarySearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BeneficiarySearchQuery,
    BeneficiarySearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BeneficiarySearchQuery,
    BeneficiarySearchQueryVariables
  >(BeneficiarySearchDocument, options);
}
export type BeneficiarySearchQueryHookResult = ReturnType<
  typeof useBeneficiarySearchQuery
>;
export type BeneficiarySearchLazyQueryHookResult = ReturnType<
  typeof useBeneficiarySearchLazyQuery
>;
export type BeneficiarySearchQueryResult = Apollo.QueryResult<
  BeneficiarySearchQuery,
  BeneficiarySearchQueryVariables
>;
export const NotificationsDocument = gql`
  query Notifications {
    runner: currentRunner {
      id
      notifications {
        ...Notification
      }
    }
  }
  ${NotificationFragmentDoc}
`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options
  );
}
export function useNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options
  );
}
export type NotificationsQueryHookResult = ReturnType<
  typeof useNotificationsQuery
>;
export type NotificationsLazyQueryHookResult = ReturnType<
  typeof useNotificationsLazyQuery
>;
export type NotificationsQueryResult = Apollo.QueryResult<
  NotificationsQuery,
  NotificationsQueryVariables
>;
export const NewsfeedDocument = gql`
  query Newsfeed($type: NewsfeedType!, $runnerId: ID, $page: Int) {
    newsfeed(type: $type, runnerId: $runnerId, page: $page) {
      ...Newsfeed
    }
  }
  ${NewsfeedFragmentDoc}
`;

/**
 * __useNewsfeedQuery__
 *
 * To run a query within a React component, call `useNewsfeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsfeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsfeedQuery({
 *   variables: {
 *      type: // value for 'type'
 *      runnerId: // value for 'runnerId'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useNewsfeedQuery(
  baseOptions: Apollo.QueryHookOptions<NewsfeedQuery, NewsfeedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NewsfeedQuery, NewsfeedQueryVariables>(
    NewsfeedDocument,
    options
  );
}
export function useNewsfeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NewsfeedQuery,
    NewsfeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NewsfeedQuery, NewsfeedQueryVariables>(
    NewsfeedDocument,
    options
  );
}
export type NewsfeedQueryHookResult = ReturnType<typeof useNewsfeedQuery>;
export type NewsfeedLazyQueryHookResult = ReturnType<
  typeof useNewsfeedLazyQuery
>;
export type NewsfeedQueryResult = Apollo.QueryResult<
  NewsfeedQuery,
  NewsfeedQueryVariables
>;
export const ReportsFeedDocument = gql`
  query ReportsFeed(
    $areaId: ID
    $organisationId: ID
    $before: ISO8601DateTime
    $runnerId: ID
    $sessionType: String
  ) {
    results: reports(
      areaId: $areaId
      organisationId: $organisationId
      before: $before
      runnerId: $runnerId
      sessionType: $sessionType
    ) {
      before
      more
      areaId
      reports {
        ...Report
        session {
          ...Session
          area {
            ...Area
          }
          signups {
            id
            slug
            name
            avatarUrl
            isMember
            neverRun
            verified
            pronouns
            roles
          }
        }
      }
    }
  }
  ${ReportFragmentDoc}
  ${SessionFragmentDoc}
  ${AreaFragmentDoc}
`;

/**
 * __useReportsFeedQuery__
 *
 * To run a query within a React component, call `useReportsFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsFeedQuery({
 *   variables: {
 *      areaId: // value for 'areaId'
 *      organisationId: // value for 'organisationId'
 *      before: // value for 'before'
 *      runnerId: // value for 'runnerId'
 *      sessionType: // value for 'sessionType'
 *   },
 * });
 */
export function useReportsFeedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReportsFeedQuery,
    ReportsFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReportsFeedQuery, ReportsFeedQueryVariables>(
    ReportsFeedDocument,
    options
  );
}
export function useReportsFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportsFeedQuery,
    ReportsFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReportsFeedQuery, ReportsFeedQueryVariables>(
    ReportsFeedDocument,
    options
  );
}
export type ReportsFeedQueryHookResult = ReturnType<typeof useReportsFeedQuery>;
export type ReportsFeedLazyQueryHookResult = ReturnType<
  typeof useReportsFeedLazyQuery
>;
export type ReportsFeedQueryResult = Apollo.QueryResult<
  ReportsFeedQuery,
  ReportsFeedQueryVariables
>;
export const RunnerSearchDocument = gql`
  query RunnerSearch($query: String!) {
    results: autocompleteRunner(query: $query) {
      ...Runner
    }
  }
  ${RunnerFragmentDoc}
`;

/**
 * __useRunnerSearchQuery__
 *
 * To run a query within a React component, call `useRunnerSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunnerSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunnerSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useRunnerSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    RunnerSearchQuery,
    RunnerSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RunnerSearchQuery, RunnerSearchQueryVariables>(
    RunnerSearchDocument,
    options
  );
}
export function useRunnerSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RunnerSearchQuery,
    RunnerSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RunnerSearchQuery, RunnerSearchQueryVariables>(
    RunnerSearchDocument,
    options
  );
}
export type RunnerSearchQueryHookResult = ReturnType<
  typeof useRunnerSearchQuery
>;
export type RunnerSearchLazyQueryHookResult = ReturnType<
  typeof useRunnerSearchLazyQuery
>;
export type RunnerSearchQueryResult = Apollo.QueryResult<
  RunnerSearchQuery,
  RunnerSearchQueryVariables
>;
export const RunnerTooltipDocument = gql`
  query RunnerTooltip($id: ID!) {
    runner(id: $id) {
      ...Runner
      ...RunnerStatus
      ...FollowStatus
      homeArea {
        ...Area
      }
    }
  }
  ${RunnerFragmentDoc}
  ${RunnerStatusFragmentDoc}
  ${FollowStatusFragmentDoc}
  ${AreaFragmentDoc}
`;

/**
 * __useRunnerTooltipQuery__
 *
 * To run a query within a React component, call `useRunnerTooltipQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunnerTooltipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunnerTooltipQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRunnerTooltipQuery(
  baseOptions: Apollo.QueryHookOptions<
    RunnerTooltipQuery,
    RunnerTooltipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RunnerTooltipQuery, RunnerTooltipQueryVariables>(
    RunnerTooltipDocument,
    options
  );
}
export function useRunnerTooltipLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RunnerTooltipQuery,
    RunnerTooltipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RunnerTooltipQuery, RunnerTooltipQueryVariables>(
    RunnerTooltipDocument,
    options
  );
}
export type RunnerTooltipQueryHookResult = ReturnType<
  typeof useRunnerTooltipQuery
>;
export type RunnerTooltipLazyQueryHookResult = ReturnType<
  typeof useRunnerTooltipLazyQuery
>;
export type RunnerTooltipQueryResult = Apollo.QueryResult<
  RunnerTooltipQuery,
  RunnerTooltipQueryVariables
>;
export const SessionSearchDocument = gql`
  query SessionSearch($query: String!) {
    results: autocompleteSession(query: $query) {
      ...Session
    }
  }
  ${SessionFragmentDoc}
`;

/**
 * __useSessionSearchQuery__
 *
 * To run a query within a React component, call `useSessionSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSessionSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    SessionSearchQuery,
    SessionSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SessionSearchQuery, SessionSearchQueryVariables>(
    SessionSearchDocument,
    options
  );
}
export function useSessionSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SessionSearchQuery,
    SessionSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SessionSearchQuery, SessionSearchQueryVariables>(
    SessionSearchDocument,
    options
  );
}
export type SessionSearchQueryHookResult = ReturnType<
  typeof useSessionSearchQuery
>;
export type SessionSearchLazyQueryHookResult = ReturnType<
  typeof useSessionSearchLazyQuery
>;
export type SessionSearchQueryResult = Apollo.QueryResult<
  SessionSearchQuery,
  SessionSearchQueryVariables
>;
export const SessionSignupDialogTrainingDocument = gql`
  query SessionSignupDialogTraining($slug: String!) {
    training(slug: $slug) {
      ...Training
      modules {
        ...TrainingModule
        sections {
          ...TrainingModuleSection
        }
        questions {
          ...TrainingModuleQuestion
        }
      }
    }
  }
  ${TrainingFragmentDoc}
  ${TrainingModuleFragmentDoc}
  ${TrainingModuleSectionFragmentDoc}
  ${TrainingModuleQuestionFragmentDoc}
`;

/**
 * __useSessionSignupDialogTrainingQuery__
 *
 * To run a query within a React component, call `useSessionSignupDialogTrainingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionSignupDialogTrainingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionSignupDialogTrainingQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useSessionSignupDialogTrainingQuery(
  baseOptions: Apollo.QueryHookOptions<
    SessionSignupDialogTrainingQuery,
    SessionSignupDialogTrainingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SessionSignupDialogTrainingQuery,
    SessionSignupDialogTrainingQueryVariables
  >(SessionSignupDialogTrainingDocument, options);
}
export function useSessionSignupDialogTrainingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SessionSignupDialogTrainingQuery,
    SessionSignupDialogTrainingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SessionSignupDialogTrainingQuery,
    SessionSignupDialogTrainingQueryVariables
  >(SessionSignupDialogTrainingDocument, options);
}
export type SessionSignupDialogTrainingQueryHookResult = ReturnType<
  typeof useSessionSignupDialogTrainingQuery
>;
export type SessionSignupDialogTrainingLazyQueryHookResult = ReturnType<
  typeof useSessionSignupDialogTrainingLazyQuery
>;
export type SessionSignupDialogTrainingQueryResult = Apollo.QueryResult<
  SessionSignupDialogTrainingQuery,
  SessionSignupDialogTrainingQueryVariables
>;
export const SessionSignupDialogDocument = gql`
  query SessionSignupDialog {
    runner: currentRunner {
      ...CurrentRunner
      ...CurrentRunnerTrainingStatus
    }
  }
  ${CurrentRunnerFragmentDoc}
  ${CurrentRunnerTrainingStatusFragmentDoc}
`;

/**
 * __useSessionSignupDialogQuery__
 *
 * To run a query within a React component, call `useSessionSignupDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionSignupDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionSignupDialogQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessionSignupDialogQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SessionSignupDialogQuery,
    SessionSignupDialogQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SessionSignupDialogQuery,
    SessionSignupDialogQueryVariables
  >(SessionSignupDialogDocument, options);
}
export function useSessionSignupDialogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SessionSignupDialogQuery,
    SessionSignupDialogQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SessionSignupDialogQuery,
    SessionSignupDialogQueryVariables
  >(SessionSignupDialogDocument, options);
}
export type SessionSignupDialogQueryHookResult = ReturnType<
  typeof useSessionSignupDialogQuery
>;
export type SessionSignupDialogLazyQueryHookResult = ReturnType<
  typeof useSessionSignupDialogLazyQuery
>;
export type SessionSignupDialogQueryResult = Apollo.QueryResult<
  SessionSignupDialogQuery,
  SessionSignupDialogQueryVariables
>;
export const SessionsFeedCalendarDocument = gql`
  query SessionsFeedCalendar(
    $areaIds: [ID!]!
    $postcode: String
    $maxDistance: Int!
    $from: ISO8601DateTime!
    $sessionsCursorDate: ISO8601DateTime
    $sessionsCursorId: ID
    $taskRequestsCursorDate: ISO8601DateTime
    $taskRequestsCursorId: ID
    $limit: Int
  ) {
    runner: currentRunner {
      id
      upcomingSessions {
        id
        startedAt
      }
    }
    sessions: sessionsV2(
      areaIds: $areaIds
      postcode: $postcode
      maxDistance: $maxDistance
      from: $from
      cursorDate: $sessionsCursorDate
      cursorId: $sessionsCursorId
      limit: $limit
    ) {
      more
      results {
        id
        startedAt
        type
        signedUp
      }
    }
    taskRequests(
      areaIds: $areaIds
      postcode: $postcode
      maxDistance: $maxDistance
      from: $from
      cursorDate: $taskRequestsCursorDate
      cursorId: $taskRequestsCursorId
      limit: $limit
    ) {
      more
      results {
        id
        declined
        potentialTimes
      }
    }
  }
`;

/**
 * __useSessionsFeedCalendarQuery__
 *
 * To run a query within a React component, call `useSessionsFeedCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionsFeedCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionsFeedCalendarQuery({
 *   variables: {
 *      areaIds: // value for 'areaIds'
 *      postcode: // value for 'postcode'
 *      maxDistance: // value for 'maxDistance'
 *      from: // value for 'from'
 *      sessionsCursorDate: // value for 'sessionsCursorDate'
 *      sessionsCursorId: // value for 'sessionsCursorId'
 *      taskRequestsCursorDate: // value for 'taskRequestsCursorDate'
 *      taskRequestsCursorId: // value for 'taskRequestsCursorId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSessionsFeedCalendarQuery(
  baseOptions: Apollo.QueryHookOptions<
    SessionsFeedCalendarQuery,
    SessionsFeedCalendarQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SessionsFeedCalendarQuery,
    SessionsFeedCalendarQueryVariables
  >(SessionsFeedCalendarDocument, options);
}
export function useSessionsFeedCalendarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SessionsFeedCalendarQuery,
    SessionsFeedCalendarQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SessionsFeedCalendarQuery,
    SessionsFeedCalendarQueryVariables
  >(SessionsFeedCalendarDocument, options);
}
export type SessionsFeedCalendarQueryHookResult = ReturnType<
  typeof useSessionsFeedCalendarQuery
>;
export type SessionsFeedCalendarLazyQueryHookResult = ReturnType<
  typeof useSessionsFeedCalendarLazyQuery
>;
export type SessionsFeedCalendarQueryResult = Apollo.QueryResult<
  SessionsFeedCalendarQuery,
  SessionsFeedCalendarQueryVariables
>;
export const SessionsFeedDocument = gql`
  query SessionsFeed(
    $types: [String!]!
    $areaIds: [ID!]!
    $postcode: String
    $maxDistance: Int!
    $from: ISO8601DateTime!
    $sessionsCursorDate: ISO8601DateTime
    $sessionsCursorId: ID
    $taskRequestsCursorDate: ISO8601DateTime
    $taskRequestsCursorId: ID
    $limit: Int
  ) {
    sessions: sessionsV2(
      types: $types
      areaIds: $areaIds
      postcode: $postcode
      maxDistance: $maxDistance
      from: $from
      cursorDate: $sessionsCursorDate
      cursorId: $sessionsCursorId
      limit: $limit
    ) {
      more
      results {
        ...SessionCard
      }
    }
    taskRequests(
      types: $types
      areaIds: $areaIds
      postcode: $postcode
      maxDistance: $maxDistance
      from: $from
      cursorDate: $taskRequestsCursorDate
      cursorId: $taskRequestsCursorId
      limit: $limit
    ) {
      more
      results {
        ...TaskRequestCard
      }
    }
  }
  ${SessionCardFragmentDoc}
  ${TaskRequestCardFragmentDoc}
`;

/**
 * __useSessionsFeedQuery__
 *
 * To run a query within a React component, call `useSessionsFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionsFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionsFeedQuery({
 *   variables: {
 *      types: // value for 'types'
 *      areaIds: // value for 'areaIds'
 *      postcode: // value for 'postcode'
 *      maxDistance: // value for 'maxDistance'
 *      from: // value for 'from'
 *      sessionsCursorDate: // value for 'sessionsCursorDate'
 *      sessionsCursorId: // value for 'sessionsCursorId'
 *      taskRequestsCursorDate: // value for 'taskRequestsCursorDate'
 *      taskRequestsCursorId: // value for 'taskRequestsCursorId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSessionsFeedQuery(
  baseOptions: Apollo.QueryHookOptions<
    SessionsFeedQuery,
    SessionsFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SessionsFeedQuery, SessionsFeedQueryVariables>(
    SessionsFeedDocument,
    options
  );
}
export function useSessionsFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SessionsFeedQuery,
    SessionsFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SessionsFeedQuery, SessionsFeedQueryVariables>(
    SessionsFeedDocument,
    options
  );
}
export type SessionsFeedQueryHookResult = ReturnType<
  typeof useSessionsFeedQuery
>;
export type SessionsFeedLazyQueryHookResult = ReturnType<
  typeof useSessionsFeedLazyQuery
>;
export type SessionsFeedQueryResult = Apollo.QueryResult<
  SessionsFeedQuery,
  SessionsFeedQueryVariables
>;
export const TaskRequestSearchDocument = gql`
  query TaskRequestSearch($query: String!) {
    results: autocompleteTaskRequest(query: $query) {
      ...TaskRequest
    }
  }
  ${TaskRequestFragmentDoc}
`;

/**
 * __useTaskRequestSearchQuery__
 *
 * To run a query within a React component, call `useTaskRequestSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskRequestSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskRequestSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useTaskRequestSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    TaskRequestSearchQuery,
    TaskRequestSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TaskRequestSearchQuery,
    TaskRequestSearchQueryVariables
  >(TaskRequestSearchDocument, options);
}
export function useTaskRequestSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaskRequestSearchQuery,
    TaskRequestSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TaskRequestSearchQuery,
    TaskRequestSearchQueryVariables
  >(TaskRequestSearchDocument, options);
}
export type TaskRequestSearchQueryHookResult = ReturnType<
  typeof useTaskRequestSearchQuery
>;
export type TaskRequestSearchLazyQueryHookResult = ReturnType<
  typeof useTaskRequestSearchLazyQuery
>;
export type TaskRequestSearchQueryResult = Apollo.QueryResult<
  TaskRequestSearchQuery,
  TaskRequestSearchQueryVariables
>;
export const BlockDocument = gql`
  mutation Block($runnerIds: [ID!]!) {
    response: block(runnerIds: $runnerIds) {
      runners {
        ...FollowStatus
      }
    }
  }
  ${FollowStatusFragmentDoc}
`;
export type BlockMutationFn = Apollo.MutationFunction<
  BlockMutation,
  BlockMutationVariables
>;

/**
 * __useBlockMutation__
 *
 * To run a mutation, you first call `useBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockMutation, { data, loading, error }] = useBlockMutation({
 *   variables: {
 *      runnerIds: // value for 'runnerIds'
 *   },
 * });
 */
export function useBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BlockMutation,
    BlockMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BlockMutation, BlockMutationVariables>(
    BlockDocument,
    options
  );
}
export type BlockMutationHookResult = ReturnType<typeof useBlockMutation>;
export type BlockMutationResult = Apollo.MutationResult<BlockMutation>;
export type BlockMutationOptions = Apollo.BaseMutationOptions<
  BlockMutation,
  BlockMutationVariables
>;
export const CheerDocument = gql`
  mutation Cheer(
    $cheerableActivityId: ID!
    $cheerableType: CheerableType!
    $runnerIds: [ID!]!
  ) {
    response: cheer(
      cheerableActivityId: $cheerableActivityId
      cheerableType: $cheerableType
      runnerIds: $runnerIds
    ) {
      cheerable {
        ...Cheerable
      }
    }
  }
  ${CheerableFragmentDoc}
`;
export type CheerMutationFn = Apollo.MutationFunction<
  CheerMutation,
  CheerMutationVariables
>;

/**
 * __useCheerMutation__
 *
 * To run a mutation, you first call `useCheerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cheerMutation, { data, loading, error }] = useCheerMutation({
 *   variables: {
 *      cheerableActivityId: // value for 'cheerableActivityId'
 *      cheerableType: // value for 'cheerableType'
 *      runnerIds: // value for 'runnerIds'
 *   },
 * });
 */
export function useCheerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheerMutation,
    CheerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CheerMutation, CheerMutationVariables>(
    CheerDocument,
    options
  );
}
export type CheerMutationHookResult = ReturnType<typeof useCheerMutation>;
export type CheerMutationResult = Apollo.MutationResult<CheerMutation>;
export type CheerMutationOptions = Apollo.BaseMutationOptions<
  CheerMutation,
  CheerMutationVariables
>;
export const CompleteTrainingDocument = gql`
  mutation CompleteTraining(
    $trainingId: ID!
    $answers: [TrainingAnswerInput!]!
  ) {
    response: completeTraining(trainingId: $trainingId, answers: $answers) {
      runner {
        ...CurrentRunnerTrainingStatus
      }
      success
    }
  }
  ${CurrentRunnerTrainingStatusFragmentDoc}
`;
export type CompleteTrainingMutationFn = Apollo.MutationFunction<
  CompleteTrainingMutation,
  CompleteTrainingMutationVariables
>;

/**
 * __useCompleteTrainingMutation__
 *
 * To run a mutation, you first call `useCompleteTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeTrainingMutation, { data, loading, error }] = useCompleteTrainingMutation({
 *   variables: {
 *      trainingId: // value for 'trainingId'
 *      answers: // value for 'answers'
 *   },
 * });
 */
export function useCompleteTrainingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteTrainingMutation,
    CompleteTrainingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteTrainingMutation,
    CompleteTrainingMutationVariables
  >(CompleteTrainingDocument, options);
}
export type CompleteTrainingMutationHookResult = ReturnType<
  typeof useCompleteTrainingMutation
>;
export type CompleteTrainingMutationResult =
  Apollo.MutationResult<CompleteTrainingMutation>;
export type CompleteTrainingMutationOptions = Apollo.BaseMutationOptions<
  CompleteTrainingMutation,
  CompleteTrainingMutationVariables
>;
export const CreateCommentDocument = gql`
  mutation CreateComment(
    $commentableId: ID!
    $commentableType: CommentableType!
    $content: String!
  ) {
    response: createComment(
      commentableId: $commentableId
      commentableType: $commentableType
      content: $content
    ) {
      commentable {
        ...Commentable
      }
    }
  }
  ${CommentableFragmentDoc}
`;
export type CreateCommentMutationFn = Apollo.MutationFunction<
  CreateCommentMutation,
  CreateCommentMutationVariables
>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      commentableId: // value for 'commentableId'
 *      commentableType: // value for 'commentableType'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCreateCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCommentMutation,
    CreateCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCommentMutation,
    CreateCommentMutationVariables
  >(CreateCommentDocument, options);
}
export type CreateCommentMutationHookResult = ReturnType<
  typeof useCreateCommentMutation
>;
export type CreateCommentMutationResult =
  Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateCommentMutation,
  CreateCommentMutationVariables
>;
export const DeclineTaskRequestDocument = gql`
  mutation DeclineTaskRequest($taskRequestId: ID!) {
    response: declineTaskRequest(taskRequestId: $taskRequestId) {
      taskRequest {
        ...TaskRequestCard
      }
    }
  }
  ${TaskRequestCardFragmentDoc}
`;
export type DeclineTaskRequestMutationFn = Apollo.MutationFunction<
  DeclineTaskRequestMutation,
  DeclineTaskRequestMutationVariables
>;

/**
 * __useDeclineTaskRequestMutation__
 *
 * To run a mutation, you first call `useDeclineTaskRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineTaskRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineTaskRequestMutation, { data, loading, error }] = useDeclineTaskRequestMutation({
 *   variables: {
 *      taskRequestId: // value for 'taskRequestId'
 *   },
 * });
 */
export function useDeclineTaskRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeclineTaskRequestMutation,
    DeclineTaskRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeclineTaskRequestMutation,
    DeclineTaskRequestMutationVariables
  >(DeclineTaskRequestDocument, options);
}
export type DeclineTaskRequestMutationHookResult = ReturnType<
  typeof useDeclineTaskRequestMutation
>;
export type DeclineTaskRequestMutationResult =
  Apollo.MutationResult<DeclineTaskRequestMutation>;
export type DeclineTaskRequestMutationOptions = Apollo.BaseMutationOptions<
  DeclineTaskRequestMutation,
  DeclineTaskRequestMutationVariables
>;
export const DeleteCommentDocument = gql`
  mutation DeleteComment($commentId: ID!) {
    response: deleteComment(commentId: $commentId) {
      commentable {
        ...Commentable
      }
    }
  }
  ${CommentableFragmentDoc}
`;
export type DeleteCommentMutationFn = Apollo.MutationFunction<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useDeleteCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCommentMutation,
    DeleteCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCommentMutation,
    DeleteCommentMutationVariables
  >(DeleteCommentDocument, options);
}
export type DeleteCommentMutationHookResult = ReturnType<
  typeof useDeleteCommentMutation
>;
export type DeleteCommentMutationResult =
  Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>;
export const DeleteGroupFitnessActivityDocument = gql`
  mutation DeleteGroupFitnessActivity($groupFitnessActivityId: ID!) {
    response: deleteGroupFitnessActivity(
      groupFitnessActivityId: $groupFitnessActivityId
    ) {
      session {
        ...Session
        groupFitnessActivities {
          ...GroupFitnessActivity
        }
      }
    }
  }
  ${SessionFragmentDoc}
  ${GroupFitnessActivityFragmentDoc}
`;
export type DeleteGroupFitnessActivityMutationFn = Apollo.MutationFunction<
  DeleteGroupFitnessActivityMutation,
  DeleteGroupFitnessActivityMutationVariables
>;

/**
 * __useDeleteGroupFitnessActivityMutation__
 *
 * To run a mutation, you first call `useDeleteGroupFitnessActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupFitnessActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupFitnessActivityMutation, { data, loading, error }] = useDeleteGroupFitnessActivityMutation({
 *   variables: {
 *      groupFitnessActivityId: // value for 'groupFitnessActivityId'
 *   },
 * });
 */
export function useDeleteGroupFitnessActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGroupFitnessActivityMutation,
    DeleteGroupFitnessActivityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteGroupFitnessActivityMutation,
    DeleteGroupFitnessActivityMutationVariables
  >(DeleteGroupFitnessActivityDocument, options);
}
export type DeleteGroupFitnessActivityMutationHookResult = ReturnType<
  typeof useDeleteGroupFitnessActivityMutation
>;
export type DeleteGroupFitnessActivityMutationResult =
  Apollo.MutationResult<DeleteGroupFitnessActivityMutation>;
export type DeleteGroupFitnessActivityMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteGroupFitnessActivityMutation,
    DeleteGroupFitnessActivityMutationVariables
  >;
export const DismissCtaDocument = gql`
  mutation DismissCta($id: ID!) {
    response: dismissCta(id: $id) {
      id
      currentRunner {
        ...CurrentRunner
        ctas {
          ...CTA
        }
      }
    }
  }
  ${CurrentRunnerFragmentDoc}
  ${CtaFragmentDoc}
`;
export type DismissCtaMutationFn = Apollo.MutationFunction<
  DismissCtaMutation,
  DismissCtaMutationVariables
>;

/**
 * __useDismissCtaMutation__
 *
 * To run a mutation, you first call `useDismissCtaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissCtaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissCtaMutation, { data, loading, error }] = useDismissCtaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDismissCtaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DismissCtaMutation,
    DismissCtaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DismissCtaMutation, DismissCtaMutationVariables>(
    DismissCtaDocument,
    options
  );
}
export type DismissCtaMutationHookResult = ReturnType<
  typeof useDismissCtaMutation
>;
export type DismissCtaMutationResult =
  Apollo.MutationResult<DismissCtaMutation>;
export type DismissCtaMutationOptions = Apollo.BaseMutationOptions<
  DismissCtaMutation,
  DismissCtaMutationVariables
>;
export const FollowDocument = gql`
  mutation Follow($runnerIds: [ID!]!, $message: String!) {
    response: follow(runnerIds: $runnerIds, message: $message) {
      runners {
        ...FollowStatus
      }
    }
  }
  ${FollowStatusFragmentDoc}
`;
export type FollowMutationFn = Apollo.MutationFunction<
  FollowMutation,
  FollowMutationVariables
>;

/**
 * __useFollowMutation__
 *
 * To run a mutation, you first call `useFollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followMutation, { data, loading, error }] = useFollowMutation({
 *   variables: {
 *      runnerIds: // value for 'runnerIds'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useFollowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FollowMutation,
    FollowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FollowMutation, FollowMutationVariables>(
    FollowDocument,
    options
  );
}
export type FollowMutationHookResult = ReturnType<typeof useFollowMutation>;
export type FollowMutationResult = Apollo.MutationResult<FollowMutation>;
export type FollowMutationOptions = Apollo.BaseMutationOptions<
  FollowMutation,
  FollowMutationVariables
>;
export const JoinWaitingListDocument = gql`
  mutation JoinWaitingList($sessionId: ID!) {
    response: joinWaitingList(sessionId: $sessionId) {
      session {
        id
        onWaitingList
      }
    }
  }
`;
export type JoinWaitingListMutationFn = Apollo.MutationFunction<
  JoinWaitingListMutation,
  JoinWaitingListMutationVariables
>;

/**
 * __useJoinWaitingListMutation__
 *
 * To run a mutation, you first call `useJoinWaitingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinWaitingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinWaitingListMutation, { data, loading, error }] = useJoinWaitingListMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useJoinWaitingListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinWaitingListMutation,
    JoinWaitingListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    JoinWaitingListMutation,
    JoinWaitingListMutationVariables
  >(JoinWaitingListDocument, options);
}
export type JoinWaitingListMutationHookResult = ReturnType<
  typeof useJoinWaitingListMutation
>;
export type JoinWaitingListMutationResult =
  Apollo.MutationResult<JoinWaitingListMutation>;
export type JoinWaitingListMutationOptions = Apollo.BaseMutationOptions<
  JoinWaitingListMutation,
  JoinWaitingListMutationVariables
>;
export const LeaveWaitingListDocument = gql`
  mutation LeaveWaitingList($sessionId: ID!) {
    response: leaveWaitingList(sessionId: $sessionId) {
      session {
        id
        onWaitingList
      }
    }
  }
`;
export type LeaveWaitingListMutationFn = Apollo.MutationFunction<
  LeaveWaitingListMutation,
  LeaveWaitingListMutationVariables
>;

/**
 * __useLeaveWaitingListMutation__
 *
 * To run a mutation, you first call `useLeaveWaitingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveWaitingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveWaitingListMutation, { data, loading, error }] = useLeaveWaitingListMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useLeaveWaitingListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeaveWaitingListMutation,
    LeaveWaitingListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LeaveWaitingListMutation,
    LeaveWaitingListMutationVariables
  >(LeaveWaitingListDocument, options);
}
export type LeaveWaitingListMutationHookResult = ReturnType<
  typeof useLeaveWaitingListMutation
>;
export type LeaveWaitingListMutationResult =
  Apollo.MutationResult<LeaveWaitingListMutation>;
export type LeaveWaitingListMutationOptions = Apollo.BaseMutationOptions<
  LeaveWaitingListMutation,
  LeaveWaitingListMutationVariables
>;
export const MarkAllNotificationsAsReadDocument = gql`
  mutation MarkAllNotificationsAsRead {
    response: markAllNotificationsAsRead {
      notifications {
        ...Notification
      }
    }
  }
  ${NotificationFragmentDoc}
`;
export type MarkAllNotificationsAsReadMutationFn = Apollo.MutationFunction<
  MarkAllNotificationsAsReadMutation,
  MarkAllNotificationsAsReadMutationVariables
>;

/**
 * __useMarkAllNotificationsAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAllNotificationsAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllNotificationsAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllNotificationsAsReadMutation, { data, loading, error }] = useMarkAllNotificationsAsReadMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkAllNotificationsAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkAllNotificationsAsReadMutation,
    MarkAllNotificationsAsReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkAllNotificationsAsReadMutation,
    MarkAllNotificationsAsReadMutationVariables
  >(MarkAllNotificationsAsReadDocument, options);
}
export type MarkAllNotificationsAsReadMutationHookResult = ReturnType<
  typeof useMarkAllNotificationsAsReadMutation
>;
export type MarkAllNotificationsAsReadMutationResult =
  Apollo.MutationResult<MarkAllNotificationsAsReadMutation>;
export type MarkAllNotificationsAsReadMutationOptions =
  Apollo.BaseMutationOptions<
    MarkAllNotificationsAsReadMutation,
    MarkAllNotificationsAsReadMutationVariables
  >;
export const RequestTestPairingDocument = gql`
  mutation RequestTestPairing($coachId: ID!, $runnerSuggestedTimes: [JSON!]!) {
    response: requestTestPairing(
      coachId: $coachId
      runnerSuggestedTimes: $runnerSuggestedTimes
    ) {
      testPairing {
        ...TestPairing
      }
      errors
    }
  }
  ${TestPairingFragmentDoc}
`;
export type RequestTestPairingMutationFn = Apollo.MutationFunction<
  RequestTestPairingMutation,
  RequestTestPairingMutationVariables
>;

/**
 * __useRequestTestPairingMutation__
 *
 * To run a mutation, you first call `useRequestTestPairingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestTestPairingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestTestPairingMutation, { data, loading, error }] = useRequestTestPairingMutation({
 *   variables: {
 *      coachId: // value for 'coachId'
 *      runnerSuggestedTimes: // value for 'runnerSuggestedTimes'
 *   },
 * });
 */
export function useRequestTestPairingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestTestPairingMutation,
    RequestTestPairingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestTestPairingMutation,
    RequestTestPairingMutationVariables
  >(RequestTestPairingDocument, options);
}
export type RequestTestPairingMutationHookResult = ReturnType<
  typeof useRequestTestPairingMutation
>;
export type RequestTestPairingMutationResult =
  Apollo.MutationResult<RequestTestPairingMutation>;
export type RequestTestPairingMutationOptions = Apollo.BaseMutationOptions<
  RequestTestPairingMutation,
  RequestTestPairingMutationVariables
>;
export const SignUpToSessionDocument = gql`
  mutation SignUpToSession(
    $sessionId: ID!
    $groupFitnessActivityId: ID
    $taskforceRoleIds: [ID!]!
  ) {
    response: signUpToSession(
      sessionId: $sessionId
      groupFitnessActivityId: $groupFitnessActivityId
      taskforceRoleIds: $taskforceRoleIds
    ) {
      session {
        ...SessionCard
      }
    }
  }
  ${SessionCardFragmentDoc}
`;
export type SignUpToSessionMutationFn = Apollo.MutationFunction<
  SignUpToSessionMutation,
  SignUpToSessionMutationVariables
>;

/**
 * __useSignUpToSessionMutation__
 *
 * To run a mutation, you first call `useSignUpToSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpToSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpToSessionMutation, { data, loading, error }] = useSignUpToSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      groupFitnessActivityId: // value for 'groupFitnessActivityId'
 *      taskforceRoleIds: // value for 'taskforceRoleIds'
 *   },
 * });
 */
export function useSignUpToSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpToSessionMutation,
    SignUpToSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignUpToSessionMutation,
    SignUpToSessionMutationVariables
  >(SignUpToSessionDocument, options);
}
export type SignUpToSessionMutationHookResult = ReturnType<
  typeof useSignUpToSessionMutation
>;
export type SignUpToSessionMutationResult =
  Apollo.MutationResult<SignUpToSessionMutation>;
export type SignUpToSessionMutationOptions = Apollo.BaseMutationOptions<
  SignUpToSessionMutation,
  SignUpToSessionMutationVariables
>;
export const UnSignUpFromSessionDocument = gql`
  mutation UnSignUpFromSession($sessionId: ID!) {
    response: unSignUpFromSession(sessionId: $sessionId) {
      session {
        ...SessionCard
      }
    }
  }
  ${SessionCardFragmentDoc}
`;
export type UnSignUpFromSessionMutationFn = Apollo.MutationFunction<
  UnSignUpFromSessionMutation,
  UnSignUpFromSessionMutationVariables
>;

/**
 * __useUnSignUpFromSessionMutation__
 *
 * To run a mutation, you first call `useUnSignUpFromSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnSignUpFromSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unSignUpFromSessionMutation, { data, loading, error }] = useUnSignUpFromSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useUnSignUpFromSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnSignUpFromSessionMutation,
    UnSignUpFromSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnSignUpFromSessionMutation,
    UnSignUpFromSessionMutationVariables
  >(UnSignUpFromSessionDocument, options);
}
export type UnSignUpFromSessionMutationHookResult = ReturnType<
  typeof useUnSignUpFromSessionMutation
>;
export type UnSignUpFromSessionMutationResult =
  Apollo.MutationResult<UnSignUpFromSessionMutation>;
export type UnSignUpFromSessionMutationOptions = Apollo.BaseMutationOptions<
  UnSignUpFromSessionMutation,
  UnSignUpFromSessionMutationVariables
>;
export const UnblockDocument = gql`
  mutation Unblock($runnerIds: [ID!]!) {
    response: unblock(runnerIds: $runnerIds) {
      runners {
        ...FollowStatus
      }
    }
  }
  ${FollowStatusFragmentDoc}
`;
export type UnblockMutationFn = Apollo.MutationFunction<
  UnblockMutation,
  UnblockMutationVariables
>;

/**
 * __useUnblockMutation__
 *
 * To run a mutation, you first call `useUnblockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnblockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unblockMutation, { data, loading, error }] = useUnblockMutation({
 *   variables: {
 *      runnerIds: // value for 'runnerIds'
 *   },
 * });
 */
export function useUnblockMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnblockMutation,
    UnblockMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnblockMutation, UnblockMutationVariables>(
    UnblockDocument,
    options
  );
}
export type UnblockMutationHookResult = ReturnType<typeof useUnblockMutation>;
export type UnblockMutationResult = Apollo.MutationResult<UnblockMutation>;
export type UnblockMutationOptions = Apollo.BaseMutationOptions<
  UnblockMutation,
  UnblockMutationVariables
>;
export const UncheerDocument = gql`
  mutation Uncheer(
    $cheerableActivityId: ID!
    $cheerableType: CheerableType!
    $runnerIds: [ID!]!
  ) {
    response: uncheer(
      cheerableActivityId: $cheerableActivityId
      cheerableType: $cheerableType
      runnerIds: $runnerIds
    ) {
      cheerable {
        ...Cheerable
      }
    }
  }
  ${CheerableFragmentDoc}
`;
export type UncheerMutationFn = Apollo.MutationFunction<
  UncheerMutation,
  UncheerMutationVariables
>;

/**
 * __useUncheerMutation__
 *
 * To run a mutation, you first call `useUncheerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUncheerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uncheerMutation, { data, loading, error }] = useUncheerMutation({
 *   variables: {
 *      cheerableActivityId: // value for 'cheerableActivityId'
 *      cheerableType: // value for 'cheerableType'
 *      runnerIds: // value for 'runnerIds'
 *   },
 * });
 */
export function useUncheerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UncheerMutation,
    UncheerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UncheerMutation, UncheerMutationVariables>(
    UncheerDocument,
    options
  );
}
export type UncheerMutationHookResult = ReturnType<typeof useUncheerMutation>;
export type UncheerMutationResult = Apollo.MutationResult<UncheerMutation>;
export type UncheerMutationOptions = Apollo.BaseMutationOptions<
  UncheerMutation,
  UncheerMutationVariables
>;
export const UndeclineTaskRequestDocument = gql`
  mutation UndeclineTaskRequest($taskRequestId: ID!) {
    response: undeclineTaskRequest(taskRequestId: $taskRequestId) {
      taskRequest {
        ...TaskRequestCard
      }
    }
  }
  ${TaskRequestCardFragmentDoc}
`;
export type UndeclineTaskRequestMutationFn = Apollo.MutationFunction<
  UndeclineTaskRequestMutation,
  UndeclineTaskRequestMutationVariables
>;

/**
 * __useUndeclineTaskRequestMutation__
 *
 * To run a mutation, you first call `useUndeclineTaskRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndeclineTaskRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undeclineTaskRequestMutation, { data, loading, error }] = useUndeclineTaskRequestMutation({
 *   variables: {
 *      taskRequestId: // value for 'taskRequestId'
 *   },
 * });
 */
export function useUndeclineTaskRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UndeclineTaskRequestMutation,
    UndeclineTaskRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UndeclineTaskRequestMutation,
    UndeclineTaskRequestMutationVariables
  >(UndeclineTaskRequestDocument, options);
}
export type UndeclineTaskRequestMutationHookResult = ReturnType<
  typeof useUndeclineTaskRequestMutation
>;
export type UndeclineTaskRequestMutationResult =
  Apollo.MutationResult<UndeclineTaskRequestMutation>;
export type UndeclineTaskRequestMutationOptions = Apollo.BaseMutationOptions<
  UndeclineTaskRequestMutation,
  UndeclineTaskRequestMutationVariables
>;
export const UnfollowDocument = gql`
  mutation Unfollow($runnerIds: [ID!]!) {
    response: unfollow(runnerIds: $runnerIds) {
      runners {
        ...FollowStatus
      }
    }
  }
  ${FollowStatusFragmentDoc}
`;
export type UnfollowMutationFn = Apollo.MutationFunction<
  UnfollowMutation,
  UnfollowMutationVariables
>;

/**
 * __useUnfollowMutation__
 *
 * To run a mutation, you first call `useUnfollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfollowMutation, { data, loading, error }] = useUnfollowMutation({
 *   variables: {
 *      runnerIds: // value for 'runnerIds'
 *   },
 * });
 */
export function useUnfollowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnfollowMutation,
    UnfollowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnfollowMutation, UnfollowMutationVariables>(
    UnfollowDocument,
    options
  );
}
export type UnfollowMutationHookResult = ReturnType<typeof useUnfollowMutation>;
export type UnfollowMutationResult = Apollo.MutationResult<UnfollowMutation>;
export type UnfollowMutationOptions = Apollo.BaseMutationOptions<
  UnfollowMutation,
  UnfollowMutationVariables
>;
export const UnpublishReportDocument = gql`
  mutation UnpublishReport($reportId: ID!) {
    response: unpublishReport(reportId: $reportId) {
      report {
        ...Report
      }
    }
  }
  ${ReportFragmentDoc}
`;
export type UnpublishReportMutationFn = Apollo.MutationFunction<
  UnpublishReportMutation,
  UnpublishReportMutationVariables
>;

/**
 * __useUnpublishReportMutation__
 *
 * To run a mutation, you first call `useUnpublishReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishReportMutation, { data, loading, error }] = useUnpublishReportMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useUnpublishReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnpublishReportMutation,
    UnpublishReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnpublishReportMutation,
    UnpublishReportMutationVariables
  >(UnpublishReportDocument, options);
}
export type UnpublishReportMutationHookResult = ReturnType<
  typeof useUnpublishReportMutation
>;
export type UnpublishReportMutationResult =
  Apollo.MutationResult<UnpublishReportMutation>;
export type UnpublishReportMutationOptions = Apollo.BaseMutationOptions<
  UnpublishReportMutation,
  UnpublishReportMutationVariables
>;
export const UpdateAreaDocument = gql`
  mutation UpdateArea($areaId: ID!, $description: String) {
    response: updateArea(areaId: $areaId, description: $description) {
      area {
        ...Area
        description
      }
    }
  }
  ${AreaFragmentDoc}
`;
export type UpdateAreaMutationFn = Apollo.MutationFunction<
  UpdateAreaMutation,
  UpdateAreaMutationVariables
>;

/**
 * __useUpdateAreaMutation__
 *
 * To run a mutation, you first call `useUpdateAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAreaMutation, { data, loading, error }] = useUpdateAreaMutation({
 *   variables: {
 *      areaId: // value for 'areaId'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateAreaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAreaMutation,
    UpdateAreaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAreaMutation, UpdateAreaMutationVariables>(
    UpdateAreaDocument,
    options
  );
}
export type UpdateAreaMutationHookResult = ReturnType<
  typeof useUpdateAreaMutation
>;
export type UpdateAreaMutationResult =
  Apollo.MutationResult<UpdateAreaMutation>;
export type UpdateAreaMutationOptions = Apollo.BaseMutationOptions<
  UpdateAreaMutation,
  UpdateAreaMutationVariables
>;
export const UpdateOrganisationDocument = gql`
  mutation UpdateOrganisation($organisationId: ID!, $description: String) {
    response: updateOrganisation(
      organisationId: $organisationId
      description: $description
    ) {
      organisation {
        ...Organisation
        description
      }
    }
  }
  ${OrganisationFragmentDoc}
`;
export type UpdateOrganisationMutationFn = Apollo.MutationFunction<
  UpdateOrganisationMutation,
  UpdateOrganisationMutationVariables
>;

/**
 * __useUpdateOrganisationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganisationMutation, { data, loading, error }] = useUpdateOrganisationMutation({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateOrganisationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganisationMutation,
    UpdateOrganisationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganisationMutation,
    UpdateOrganisationMutationVariables
  >(UpdateOrganisationDocument, options);
}
export type UpdateOrganisationMutationHookResult = ReturnType<
  typeof useUpdateOrganisationMutation
>;
export type UpdateOrganisationMutationResult =
  Apollo.MutationResult<UpdateOrganisationMutation>;
export type UpdateOrganisationMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganisationMutation,
  UpdateOrganisationMutationVariables
>;
export const AreaPageSessionsDocument = gql`
  query AreaPageSessions(
    $types: [String!]!
    $areaIds: [ID!]!
    $from: ISO8601DateTime!
    $sessionsCursorDate: ISO8601DateTime
    $sessionsCursorId: ID
    $taskRequestsCursorDate: ISO8601DateTime
    $taskRequestsCursorId: ID
    $limit: Int
  ) {
    sessions: sessionsV2(
      types: $types
      areaIds: $areaIds
      from: $from
      cursorDate: $sessionsCursorDate
      cursorId: $sessionsCursorId
      limit: $limit
    ) {
      more
      results {
        ...SessionCard
      }
    }
    taskRequests(
      types: $types
      areaIds: $areaIds
      from: $from
      cursorDate: $taskRequestsCursorDate
      cursorId: $taskRequestsCursorId
      limit: $limit
    ) {
      more
      results {
        ...TaskRequestCard
      }
    }
  }
  ${SessionCardFragmentDoc}
  ${TaskRequestCardFragmentDoc}
`;

/**
 * __useAreaPageSessionsQuery__
 *
 * To run a query within a React component, call `useAreaPageSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaPageSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaPageSessionsQuery({
 *   variables: {
 *      types: // value for 'types'
 *      areaIds: // value for 'areaIds'
 *      from: // value for 'from'
 *      sessionsCursorDate: // value for 'sessionsCursorDate'
 *      sessionsCursorId: // value for 'sessionsCursorId'
 *      taskRequestsCursorDate: // value for 'taskRequestsCursorDate'
 *      taskRequestsCursorId: // value for 'taskRequestsCursorId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAreaPageSessionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AreaPageSessionsQuery,
    AreaPageSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AreaPageSessionsQuery, AreaPageSessionsQueryVariables>(
    AreaPageSessionsDocument,
    options
  );
}
export function useAreaPageSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AreaPageSessionsQuery,
    AreaPageSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AreaPageSessionsQuery,
    AreaPageSessionsQueryVariables
  >(AreaPageSessionsDocument, options);
}
export type AreaPageSessionsQueryHookResult = ReturnType<
  typeof useAreaPageSessionsQuery
>;
export type AreaPageSessionsLazyQueryHookResult = ReturnType<
  typeof useAreaPageSessionsLazyQuery
>;
export type AreaPageSessionsQueryResult = Apollo.QueryResult<
  AreaPageSessionsQuery,
  AreaPageSessionsQueryVariables
>;
export const AreaPageDocument = gql`
  query AreaPage($slug: String!) {
    area(slug: $slug) {
      ...Area
      description
      active
      imageUrl
      areaActivator {
        ...Runner
        bio
      }
      taskforce {
        ...Runner
      }
      runLeaders {
        ...Runner
      }
      partners {
        id
        name
        description
        imageUrl
        url
      }
      email
      twitter
      facebook
      instagram
    }
  }
  ${AreaFragmentDoc}
  ${RunnerFragmentDoc}
`;

/**
 * __useAreaPageQuery__
 *
 * To run a query within a React component, call `useAreaPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useAreaPageQuery(
  baseOptions: Apollo.QueryHookOptions<AreaPageQuery, AreaPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AreaPageQuery, AreaPageQueryVariables>(
    AreaPageDocument,
    options
  );
}
export function useAreaPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AreaPageQuery,
    AreaPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AreaPageQuery, AreaPageQueryVariables>(
    AreaPageDocument,
    options
  );
}
export type AreaPageQueryHookResult = ReturnType<typeof useAreaPageQuery>;
export type AreaPageLazyQueryHookResult = ReturnType<
  typeof useAreaPageLazyQuery
>;
export type AreaPageQueryResult = Apollo.QueryResult<
  AreaPageQuery,
  AreaPageQueryVariables
>;
export const ContactPageDocument = gql`
  query ContactPage($slug: String!) {
    area(slug: $slug) {
      ...Area
      areaActivator {
        ...Runner
        bio
      }
      email
      twitter
      facebook
      instagram
    }
  }
  ${AreaFragmentDoc}
  ${RunnerFragmentDoc}
`;

/**
 * __useContactPageQuery__
 *
 * To run a query within a React component, call `useContactPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useContactPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContactPageQuery,
    ContactPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContactPageQuery, ContactPageQueryVariables>(
    ContactPageDocument,
    options
  );
}
export function useContactPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContactPageQuery,
    ContactPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContactPageQuery, ContactPageQueryVariables>(
    ContactPageDocument,
    options
  );
}
export type ContactPageQueryHookResult = ReturnType<typeof useContactPageQuery>;
export type ContactPageLazyQueryHookResult = ReturnType<
  typeof useContactPageLazyQuery
>;
export type ContactPageQueryResult = Apollo.QueryResult<
  ContactPageQuery,
  ContactPageQueryVariables
>;
export const DashboardPageDocument = gql`
  query DashboardPage {
    runner: currentRunner {
      ...CurrentRunner
      upcomingSessions {
        ...SessionCard
      }
      suggestedSessions {
        ...SessionCard
      }
      roles
      stats {
        ...RunnerStats
      }
      ctas {
        ...CTA
      }
    }
  }
  ${CurrentRunnerFragmentDoc}
  ${SessionCardFragmentDoc}
  ${RunnerStatsFragmentDoc}
  ${CtaFragmentDoc}
`;

/**
 * __useDashboardPageQuery__
 *
 * To run a query within a React component, call `useDashboardPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DashboardPageQuery,
    DashboardPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DashboardPageQuery, DashboardPageQueryVariables>(
    DashboardPageDocument,
    options
  );
}
export function useDashboardPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardPageQuery,
    DashboardPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DashboardPageQuery, DashboardPageQueryVariables>(
    DashboardPageDocument,
    options
  );
}
export type DashboardPageQueryHookResult = ReturnType<
  typeof useDashboardPageQuery
>;
export type DashboardPageLazyQueryHookResult = ReturnType<
  typeof useDashboardPageLazyQuery
>;
export type DashboardPageQueryResult = Apollo.QueryResult<
  DashboardPageQuery,
  DashboardPageQueryVariables
>;
export const DeletedStoryPageDocument = gql`
  query DeletedStoryPage($before: ISO8601DateTime) {
    deletedStories(before: $before) {
      stories {
        ...Story
        discardedAt
      }
      more
    }
  }
  ${StoryFragmentDoc}
`;

/**
 * __useDeletedStoryPageQuery__
 *
 * To run a query within a React component, call `useDeletedStoryPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeletedStoryPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedStoryPageQuery({
 *   variables: {
 *      before: // value for 'before'
 *   },
 * });
 */
export function useDeletedStoryPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DeletedStoryPageQuery,
    DeletedStoryPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DeletedStoryPageQuery, DeletedStoryPageQueryVariables>(
    DeletedStoryPageDocument,
    options
  );
}
export function useDeletedStoryPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DeletedStoryPageQuery,
    DeletedStoryPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DeletedStoryPageQuery,
    DeletedStoryPageQueryVariables
  >(DeletedStoryPageDocument, options);
}
export type DeletedStoryPageQueryHookResult = ReturnType<
  typeof useDeletedStoryPageQuery
>;
export type DeletedStoryPageLazyQueryHookResult = ReturnType<
  typeof useDeletedStoryPageLazyQuery
>;
export type DeletedStoryPageQueryResult = Apollo.QueryResult<
  DeletedStoryPageQuery,
  DeletedStoryPageQueryVariables
>;
export const DraftedStoryPageDocument = gql`
  query DraftedStoryPage($before: ISO8601DateTime) {
    draftedStories(before: $before) {
      stories {
        ...Story
        discardedAt
      }
      more
    }
  }
  ${StoryFragmentDoc}
`;

/**
 * __useDraftedStoryPageQuery__
 *
 * To run a query within a React component, call `useDraftedStoryPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftedStoryPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftedStoryPageQuery({
 *   variables: {
 *      before: // value for 'before'
 *   },
 * });
 */
export function useDraftedStoryPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DraftedStoryPageQuery,
    DraftedStoryPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DraftedStoryPageQuery, DraftedStoryPageQueryVariables>(
    DraftedStoryPageDocument,
    options
  );
}
export function useDraftedStoryPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DraftedStoryPageQuery,
    DraftedStoryPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DraftedStoryPageQuery,
    DraftedStoryPageQueryVariables
  >(DraftedStoryPageDocument, options);
}
export type DraftedStoryPageQueryHookResult = ReturnType<
  typeof useDraftedStoryPageQuery
>;
export type DraftedStoryPageLazyQueryHookResult = ReturnType<
  typeof useDraftedStoryPageLazyQuery
>;
export type DraftedStoryPageQueryResult = Apollo.QueryResult<
  DraftedStoryPageQuery,
  DraftedStoryPageQueryVariables
>;
export const HomePageDocument = gql`
  query HomePage {
    stats {
      goodgymers
      areas
      tasks
      beneficiaries
    }
  }
`;

/**
 * __useHomePageQuery__
 *
 * To run a query within a React component, call `useHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomePageQuery(
  baseOptions?: Apollo.QueryHookOptions<HomePageQuery, HomePageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomePageQuery, HomePageQueryVariables>(
    HomePageDocument,
    options
  );
}
export function useHomePageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomePageQuery,
    HomePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomePageQuery, HomePageQueryVariables>(
    HomePageDocument,
    options
  );
}
export type HomePageQueryHookResult = ReturnType<typeof useHomePageQuery>;
export type HomePageLazyQueryHookResult = ReturnType<
  typeof useHomePageLazyQuery
>;
export type HomePageQueryResult = Apollo.QueryResult<
  HomePageQuery,
  HomePageQueryVariables
>;
export const MyTeamsPageDocument = gql`
  query MyTeamsPage {
    team {
      supporters {
        id
        name
        slug
        avatarUrl
        followsYou
        blockedByYou
        followedByYou
        isMember
        neverRun
        verified
        pronouns
        roles
      }
      suggestions {
        id
        name
        slug
        avatarUrl
        followsYou
        blockedByYou
        followedByYou
        isMember
        neverRun
        verified
        pronouns
        roles
      }
      supportings {
        id
        name
        slug
        avatarUrl
        followsYou
        blockedByYou
        followedByYou
        isMember
        neverRun
        verified
        pronouns
        roles
      }
    }
  }
`;

/**
 * __useMyTeamsPageQuery__
 *
 * To run a query within a React component, call `useMyTeamsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTeamsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTeamsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyTeamsPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyTeamsPageQuery,
    MyTeamsPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyTeamsPageQuery, MyTeamsPageQueryVariables>(
    MyTeamsPageDocument,
    options
  );
}
export function useMyTeamsPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyTeamsPageQuery,
    MyTeamsPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyTeamsPageQuery, MyTeamsPageQueryVariables>(
    MyTeamsPageDocument,
    options
  );
}
export type MyTeamsPageQueryHookResult = ReturnType<typeof useMyTeamsPageQuery>;
export type MyTeamsPageLazyQueryHookResult = ReturnType<
  typeof useMyTeamsPageLazyQuery
>;
export type MyTeamsPageQueryResult = Apollo.QueryResult<
  MyTeamsPageQuery,
  MyTeamsPageQueryVariables
>;
export const OpsSupportPageDocument = gql`
  query OpsSupportPage {
    ping
  }
`;

/**
 * __useOpsSupportPageQuery__
 *
 * To run a query within a React component, call `useOpsSupportPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpsSupportPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpsSupportPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useOpsSupportPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OpsSupportPageQuery,
    OpsSupportPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OpsSupportPageQuery, OpsSupportPageQueryVariables>(
    OpsSupportPageDocument,
    options
  );
}
export function useOpsSupportPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OpsSupportPageQuery,
    OpsSupportPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OpsSupportPageQuery, OpsSupportPageQueryVariables>(
    OpsSupportPageDocument,
    options
  );
}
export type OpsSupportPageQueryHookResult = ReturnType<
  typeof useOpsSupportPageQuery
>;
export type OpsSupportPageLazyQueryHookResult = ReturnType<
  typeof useOpsSupportPageLazyQuery
>;
export type OpsSupportPageQueryResult = Apollo.QueryResult<
  OpsSupportPageQuery,
  OpsSupportPageQueryVariables
>;
export const OrganisationPageDocument = gql`
  query OrganisationPage($slug: String!) {
    organisation(slug: $slug) {
      ...Organisation
      area {
        ...Area
      }
      description
      url
      logoUrl
      imageUrl
      totalSessions
      totalRunners
      topSupporters {
        ...Runner
      }
      upcomingSessions {
        ...Session
      }
    }
  }
  ${OrganisationFragmentDoc}
  ${AreaFragmentDoc}
  ${RunnerFragmentDoc}
  ${SessionFragmentDoc}
`;

/**
 * __useOrganisationPageQuery__
 *
 * To run a query within a React component, call `useOrganisationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useOrganisationPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganisationPageQuery,
    OrganisationPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganisationPageQuery, OrganisationPageQueryVariables>(
    OrganisationPageDocument,
    options
  );
}
export function useOrganisationPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganisationPageQuery,
    OrganisationPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganisationPageQuery,
    OrganisationPageQueryVariables
  >(OrganisationPageDocument, options);
}
export type OrganisationPageQueryHookResult = ReturnType<
  typeof useOrganisationPageQuery
>;
export type OrganisationPageLazyQueryHookResult = ReturnType<
  typeof useOrganisationPageLazyQuery
>;
export type OrganisationPageQueryResult = Apollo.QueryResult<
  OrganisationPageQuery,
  OrganisationPageQueryVariables
>;
export const ProfilePageDocument = gql`
  query ProfilePage($slug: ID!, $currentRunnerPresent: Boolean!) {
    runner(id: $slug) {
      ...Runner
      ...FollowStatus
      homeArea {
        ...Area
      }
      awards {
        id
        badge {
          ...Badge
        }
      }
      stats {
        ...RunnerStats
        ...TaskForceStats
      }
      streaks {
        ...RunnerStreak
      }
      employment {
        ...Employment
      }
      upcomingSessions(limit: 1) {
        ...SessionCard
      }
      joinedAt
      bio
      twitter
      instagram
      isMember
      neverRun
      verified
      roles
      user @include(if: $currentRunnerPresent) {
        email
      }
    }
  }
  ${RunnerFragmentDoc}
  ${FollowStatusFragmentDoc}
  ${AreaFragmentDoc}
  ${BadgeFragmentDoc}
  ${RunnerStatsFragmentDoc}
  ${TaskForceStatsFragmentDoc}
  ${RunnerStreakFragmentDoc}
  ${EmploymentFragmentDoc}
  ${SessionCardFragmentDoc}
`;

/**
 * __useProfilePageQuery__
 *
 * To run a query within a React component, call `useProfilePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilePageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      currentRunnerPresent: // value for 'currentRunnerPresent'
 *   },
 * });
 */
export function useProfilePageQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProfilePageQuery,
    ProfilePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProfilePageQuery, ProfilePageQueryVariables>(
    ProfilePageDocument,
    options
  );
}
export function useProfilePageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfilePageQuery,
    ProfilePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProfilePageQuery, ProfilePageQueryVariables>(
    ProfilePageDocument,
    options
  );
}
export type ProfilePageQueryHookResult = ReturnType<typeof useProfilePageQuery>;
export type ProfilePageLazyQueryHookResult = ReturnType<
  typeof useProfilePageLazyQuery
>;
export type ProfilePageQueryResult = Apollo.QueryResult<
  ProfilePageQuery,
  ProfilePageQueryVariables
>;
export const ReportPageDocument = gql`
  query ReportPage($slug: String!) {
    report(slug: $slug) {
      ...Report
      title
      description
      slug
      imageUrl
      writtenBy {
        ...Runner
      }
      session {
        ...Session
        organisation {
          ...Organisation
          url
          logoUrl
          description
          area {
            ...Area
          }
        }
        signedUp
        signups {
          ...Runner
        }
        ledBy {
          ...Runner
          ...RunnerStatus
          ...FollowStatus
          bio
          roles
          homeArea {
            ...Area
          }
        }
        area {
          ...Area
          upcomingSessions(limit: 1) {
            ...Session
            signedUp
            signups {
              ...Runner
            }
            area {
              ...Area
            }
          }
        }
        attendees {
          ...Runner
        }
      }
      comments {
        ...Comment
      }
    }
  }
  ${ReportFragmentDoc}
  ${RunnerFragmentDoc}
  ${SessionFragmentDoc}
  ${OrganisationFragmentDoc}
  ${AreaFragmentDoc}
  ${RunnerStatusFragmentDoc}
  ${FollowStatusFragmentDoc}
  ${CommentFragmentDoc}
`;

/**
 * __useReportPageQuery__
 *
 * To run a query within a React component, call `useReportPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useReportPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportPageQuery,
    ReportPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReportPageQuery, ReportPageQueryVariables>(
    ReportPageDocument,
    options
  );
}
export function useReportPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportPageQuery,
    ReportPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReportPageQuery, ReportPageQueryVariables>(
    ReportPageDocument,
    options
  );
}
export type ReportPageQueryHookResult = ReturnType<typeof useReportPageQuery>;
export type ReportPageLazyQueryHookResult = ReturnType<
  typeof useReportPageLazyQuery
>;
export type ReportPageQueryResult = Apollo.QueryResult<
  ReportPageQuery,
  ReportPageQueryVariables
>;
export const ReportsPageDocument = gql`
  query ReportsPage {
    ping
  }
`;

/**
 * __useReportsPageQuery__
 *
 * To run a query within a React component, call `useReportsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportsPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReportsPageQuery,
    ReportsPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReportsPageQuery, ReportsPageQueryVariables>(
    ReportsPageDocument,
    options
  );
}
export function useReportsPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportsPageQuery,
    ReportsPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReportsPageQuery, ReportsPageQueryVariables>(
    ReportsPageDocument,
    options
  );
}
export type ReportsPageQueryHookResult = ReturnType<typeof useReportsPageQuery>;
export type ReportsPageLazyQueryHookResult = ReturnType<
  typeof useReportsPageLazyQuery
>;
export type ReportsPageQueryResult = Apollo.QueryResult<
  ReportsPageQuery,
  ReportsPageQueryVariables
>;
export const SessionPageAdminMenuDocument = gql`
  query SessionPageAdminMenu($sessionId: ID!) {
    options: sessionAdminMenu(sessionId: $sessionId) {
      ...MenuOption
    }
  }
  ${MenuOptionFragmentDoc}
`;

/**
 * __useSessionPageAdminMenuQuery__
 *
 * To run a query within a React component, call `useSessionPageAdminMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionPageAdminMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionPageAdminMenuQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useSessionPageAdminMenuQuery(
  baseOptions: Apollo.QueryHookOptions<
    SessionPageAdminMenuQuery,
    SessionPageAdminMenuQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SessionPageAdminMenuQuery,
    SessionPageAdminMenuQueryVariables
  >(SessionPageAdminMenuDocument, options);
}
export function useSessionPageAdminMenuLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SessionPageAdminMenuQuery,
    SessionPageAdminMenuQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SessionPageAdminMenuQuery,
    SessionPageAdminMenuQueryVariables
  >(SessionPageAdminMenuDocument, options);
}
export type SessionPageAdminMenuQueryHookResult = ReturnType<
  typeof useSessionPageAdminMenuQuery
>;
export type SessionPageAdminMenuLazyQueryHookResult = ReturnType<
  typeof useSessionPageAdminMenuLazyQuery
>;
export type SessionPageAdminMenuQueryResult = Apollo.QueryResult<
  SessionPageAdminMenuQuery,
  SessionPageAdminMenuQueryVariables
>;
export const SessionPageDocument = gql`
  query SessionPage($slug: String!) {
    session(slug: $slug) {
      ...Session
      ...Commentable
      imageUrls(size: xlarge)
      cancelledAt
      registerDeadline
      description
      signedUp
      onWaitingList
      walkable
      minDistance
      maxDistance
      report {
        ...Report
      }
      organisation {
        ...Organisation
        description
        logoUrl
      }
      risks {
        ...SessionRisk
      }
      address {
        ...Address
        accessNotes
        otherInformation
      }
      signups {
        ...Runner
        ...RunnerStatus
      }
      ledBy {
        ...Runner
        ...RunnerStatus
        bio
      }
      area {
        ...Area
        email
        areaActivator {
          ...Runner
        }
      }
      groupFitnessActivities {
        ...GroupFitnessActivity
        ledBy {
          ...Runner
          ...RunnerStatus
        }
        signups {
          ...Runner
        }
      }
      taskforceRoles {
        ...TaskforceRole
      }
      taskRequest {
        ...TaskRequest
        risky
        beneficiary {
          ...Beneficiary
        }
      }
    }
  }
  ${SessionFragmentDoc}
  ${CommentableFragmentDoc}
  ${ReportFragmentDoc}
  ${OrganisationFragmentDoc}
  ${SessionRiskFragmentDoc}
  ${AddressFragmentDoc}
  ${RunnerFragmentDoc}
  ${RunnerStatusFragmentDoc}
  ${AreaFragmentDoc}
  ${GroupFitnessActivityFragmentDoc}
  ${TaskforceRoleFragmentDoc}
  ${TaskRequestFragmentDoc}
  ${BeneficiaryFragmentDoc}
`;

/**
 * __useSessionPageQuery__
 *
 * To run a query within a React component, call `useSessionPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useSessionPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    SessionPageQuery,
    SessionPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SessionPageQuery, SessionPageQueryVariables>(
    SessionPageDocument,
    options
  );
}
export function useSessionPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SessionPageQuery,
    SessionPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SessionPageQuery, SessionPageQueryVariables>(
    SessionPageDocument,
    options
  );
}
export type SessionPageQueryHookResult = ReturnType<typeof useSessionPageQuery>;
export type SessionPageLazyQueryHookResult = ReturnType<
  typeof useSessionPageLazyQuery
>;
export type SessionPageQueryResult = Apollo.QueryResult<
  SessionPageQuery,
  SessionPageQueryVariables
>;
export const AvailableCoachPageDocument = gql`
  query AvailableCoachPage($id: ID!) {
    availableCoach(id: $id) {
      ...CoachReferral
    }
  }
  ${CoachReferralFragmentDoc}
`;

/**
 * __useAvailableCoachPageQuery__
 *
 * To run a query within a React component, call `useAvailableCoachPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableCoachPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableCoachPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAvailableCoachPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvailableCoachPageQuery,
    AvailableCoachPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableCoachPageQuery,
    AvailableCoachPageQueryVariables
  >(AvailableCoachPageDocument, options);
}
export function useAvailableCoachPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableCoachPageQuery,
    AvailableCoachPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableCoachPageQuery,
    AvailableCoachPageQueryVariables
  >(AvailableCoachPageDocument, options);
}
export type AvailableCoachPageQueryHookResult = ReturnType<
  typeof useAvailableCoachPageQuery
>;
export type AvailableCoachPageLazyQueryHookResult = ReturnType<
  typeof useAvailableCoachPageLazyQuery
>;
export type AvailableCoachPageQueryResult = Apollo.QueryResult<
  AvailableCoachPageQuery,
  AvailableCoachPageQueryVariables
>;
export const TestPairingsDocument = gql`
  query TestPairings($page: Int, $limit: Int) {
    testPairings(page: $page, limit: $limit) {
      more
      results {
        ...TestPairing
      }
    }
  }
  ${TestPairingFragmentDoc}
`;

/**
 * __useTestPairingsQuery__
 *
 * To run a query within a React component, call `useTestPairingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestPairingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestPairingsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTestPairingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TestPairingsQuery,
    TestPairingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TestPairingsQuery, TestPairingsQueryVariables>(
    TestPairingsDocument,
    options
  );
}
export function useTestPairingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TestPairingsQuery,
    TestPairingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TestPairingsQuery, TestPairingsQueryVariables>(
    TestPairingsDocument,
    options
  );
}
export type TestPairingsQueryHookResult = ReturnType<
  typeof useTestPairingsQuery
>;
export type TestPairingsLazyQueryHookResult = ReturnType<
  typeof useTestPairingsLazyQuery
>;
export type TestPairingsQueryResult = Apollo.QueryResult<
  TestPairingsQuery,
  TestPairingsQueryVariables
>;
export const TestPairingDocument = gql`
  query TestPairing($id: ID!) {
    testPairing(id: $id) {
      ...TestPairing
    }
  }
  ${TestPairingFragmentDoc}
`;

/**
 * __useTestPairingQuery__
 *
 * To run a query within a React component, call `useTestPairingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestPairingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestPairingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTestPairingQuery(
  baseOptions: Apollo.QueryHookOptions<
    TestPairingQuery,
    TestPairingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TestPairingQuery, TestPairingQueryVariables>(
    TestPairingDocument,
    options
  );
}
export function useTestPairingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TestPairingQuery,
    TestPairingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TestPairingQuery, TestPairingQueryVariables>(
    TestPairingDocument,
    options
  );
}
export type TestPairingQueryHookResult = ReturnType<typeof useTestPairingQuery>;
export type TestPairingLazyQueryHookResult = ReturnType<
  typeof useTestPairingLazyQuery
>;
export type TestPairingQueryResult = Apollo.QueryResult<
  TestPairingQuery,
  TestPairingQueryVariables
>;
export const AvailableCoachesDocument = gql`
  query AvailableCoaches(
    $areaIds: [ID!]
    $postcode: String
    $maxDistance: Int
    $page: Int
    $limit: Int
  ) {
    availableCoaches(
      areaIds: $areaIds
      postcode: $postcode
      maxDistance: $maxDistance
      page: $page
      limit: $limit
    ) {
      more
      results {
        ...CoachReferral
      }
    }
  }
  ${CoachReferralFragmentDoc}
`;

/**
 * __useAvailableCoachesQuery__
 *
 * To run a query within a React component, call `useAvailableCoachesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableCoachesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableCoachesQuery({
 *   variables: {
 *      areaIds: // value for 'areaIds'
 *      postcode: // value for 'postcode'
 *      maxDistance: // value for 'maxDistance'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAvailableCoachesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailableCoachesQuery,
    AvailableCoachesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AvailableCoachesQuery, AvailableCoachesQueryVariables>(
    AvailableCoachesDocument,
    options
  );
}
export function useAvailableCoachesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableCoachesQuery,
    AvailableCoachesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableCoachesQuery,
    AvailableCoachesQueryVariables
  >(AvailableCoachesDocument, options);
}
export type AvailableCoachesQueryHookResult = ReturnType<
  typeof useAvailableCoachesQuery
>;
export type AvailableCoachesLazyQueryHookResult = ReturnType<
  typeof useAvailableCoachesLazyQuery
>;
export type AvailableCoachesQueryResult = Apollo.QueryResult<
  AvailableCoachesQuery,
  AvailableCoachesQueryVariables
>;
export const AvailableCoachDocument = gql`
  query AvailableCoach($id: ID!) {
    availableCoach(id: $id) {
      ...CoachReferral
    }
  }
  ${CoachReferralFragmentDoc}
`;

/**
 * __useAvailableCoachQuery__
 *
 * To run a query within a React component, call `useAvailableCoachQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableCoachQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableCoachQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAvailableCoachQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvailableCoachQuery,
    AvailableCoachQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AvailableCoachQuery, AvailableCoachQueryVariables>(
    AvailableCoachDocument,
    options
  );
}
export function useAvailableCoachLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableCoachQuery,
    AvailableCoachQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AvailableCoachQuery, AvailableCoachQueryVariables>(
    AvailableCoachDocument,
    options
  );
}
export type AvailableCoachQueryHookResult = ReturnType<
  typeof useAvailableCoachQuery
>;
export type AvailableCoachLazyQueryHookResult = ReturnType<
  typeof useAvailableCoachLazyQuery
>;
export type AvailableCoachQueryResult = Apollo.QueryResult<
  AvailableCoachQuery,
  AvailableCoachQueryVariables
>;
export const StoriesPageDocument = gql`
  query StoriesPage($before: ISO8601DateTime) {
    stories(before: $before) {
      stories {
        ...Story
        discardedAt
        createdAt
      }
      more
    }
  }
  ${StoryFragmentDoc}
`;

/**
 * __useStoriesPageQuery__
 *
 * To run a query within a React component, call `useStoriesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoriesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoriesPageQuery({
 *   variables: {
 *      before: // value for 'before'
 *   },
 * });
 */
export function useStoriesPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    StoriesPageQuery,
    StoriesPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StoriesPageQuery, StoriesPageQueryVariables>(
    StoriesPageDocument,
    options
  );
}
export function useStoriesPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StoriesPageQuery,
    StoriesPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StoriesPageQuery, StoriesPageQueryVariables>(
    StoriesPageDocument,
    options
  );
}
export type StoriesPageQueryHookResult = ReturnType<typeof useStoriesPageQuery>;
export type StoriesPageLazyQueryHookResult = ReturnType<
  typeof useStoriesPageLazyQuery
>;
export type StoriesPageQueryResult = Apollo.QueryResult<
  StoriesPageQuery,
  StoriesPageQueryVariables
>;
export const DeleteStoryDocument = gql`
  mutation DeleteStory($storyId: ID!) {
    response: deleteStory(storyId: $storyId) {
      story {
        ...Story
      }
    }
  }
  ${StoryFragmentDoc}
`;
export type DeleteStoryMutationFn = Apollo.MutationFunction<
  DeleteStoryMutation,
  DeleteStoryMutationVariables
>;

/**
 * __useDeleteStoryMutation__
 *
 * To run a mutation, you first call `useDeleteStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStoryMutation, { data, loading, error }] = useDeleteStoryMutation({
 *   variables: {
 *      storyId: // value for 'storyId'
 *   },
 * });
 */
export function useDeleteStoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteStoryMutation,
    DeleteStoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteStoryMutation, DeleteStoryMutationVariables>(
    DeleteStoryDocument,
    options
  );
}
export type DeleteStoryMutationHookResult = ReturnType<
  typeof useDeleteStoryMutation
>;
export type DeleteStoryMutationResult =
  Apollo.MutationResult<DeleteStoryMutation>;
export type DeleteStoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteStoryMutation,
  DeleteStoryMutationVariables
>;
export const PublishStoryDocument = gql`
  mutation PublishStory($storyId: ID!) {
    response: publishStory(storyId: $storyId) {
      story {
        ...Story
      }
    }
  }
  ${StoryFragmentDoc}
`;
export type PublishStoryMutationFn = Apollo.MutationFunction<
  PublishStoryMutation,
  PublishStoryMutationVariables
>;

/**
 * __usePublishStoryMutation__
 *
 * To run a mutation, you first call `usePublishStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishStoryMutation, { data, loading, error }] = usePublishStoryMutation({
 *   variables: {
 *      storyId: // value for 'storyId'
 *   },
 * });
 */
export function usePublishStoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishStoryMutation,
    PublishStoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishStoryMutation,
    PublishStoryMutationVariables
  >(PublishStoryDocument, options);
}
export type PublishStoryMutationHookResult = ReturnType<
  typeof usePublishStoryMutation
>;
export type PublishStoryMutationResult =
  Apollo.MutationResult<PublishStoryMutation>;
export type PublishStoryMutationOptions = Apollo.BaseMutationOptions<
  PublishStoryMutation,
  PublishStoryMutationVariables
>;
export const StoryPageDocument = gql`
  query StoryPage($slug: String!) {
    story(slug: $slug) {
      ...Story
      discardedAt
    }
  }
  ${StoryFragmentDoc}
`;

/**
 * __useStoryPageQuery__
 *
 * To run a query within a React component, call `useStoryPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useStoryPageQuery(
  baseOptions: Apollo.QueryHookOptions<StoryPageQuery, StoryPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StoryPageQuery, StoryPageQueryVariables>(
    StoryPageDocument,
    options
  );
}
export function useStoryPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StoryPageQuery,
    StoryPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StoryPageQuery, StoryPageQueryVariables>(
    StoryPageDocument,
    options
  );
}
export type StoryPageQueryHookResult = ReturnType<typeof useStoryPageQuery>;
export type StoryPageLazyQueryHookResult = ReturnType<
  typeof useStoryPageLazyQuery
>;
export type StoryPageQueryResult = Apollo.QueryResult<
  StoryPageQuery,
  StoryPageQueryVariables
>;
export const CancelTestPairingDocument = gql`
  mutation CancelTestPairing($id: ID!, $cancellationNotes: String!) {
    response: cancelTestPairing(
      id: $id
      cancellationNotes: $cancellationNotes
    ) {
      testPairing {
        ...TestPairing
      }
    }
  }
  ${TestPairingFragmentDoc}
`;
export type CancelTestPairingMutationFn = Apollo.MutationFunction<
  CancelTestPairingMutation,
  CancelTestPairingMutationVariables
>;

/**
 * __useCancelTestPairingMutation__
 *
 * To run a mutation, you first call `useCancelTestPairingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTestPairingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTestPairingMutation, { data, loading, error }] = useCancelTestPairingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      cancellationNotes: // value for 'cancellationNotes'
 *   },
 * });
 */
export function useCancelTestPairingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelTestPairingMutation,
    CancelTestPairingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelTestPairingMutation,
    CancelTestPairingMutationVariables
  >(CancelTestPairingDocument, options);
}
export type CancelTestPairingMutationHookResult = ReturnType<
  typeof useCancelTestPairingMutation
>;
export type CancelTestPairingMutationResult =
  Apollo.MutationResult<CancelTestPairingMutation>;
export type CancelTestPairingMutationOptions = Apollo.BaseMutationOptions<
  CancelTestPairingMutation,
  CancelTestPairingMutationVariables
>;
export const RescheduleTestPairingDocument = gql`
  mutation RescheduleTestPairing($id: ID!) {
    response: cancelTestPairing(
      id: $id
      reschedule: true
      cancellationNotes: "Reschedule requested by Goodgymer"
    ) {
      testPairing {
        ...TestPairing
      }
    }
  }
  ${TestPairingFragmentDoc}
`;
export type RescheduleTestPairingMutationFn = Apollo.MutationFunction<
  RescheduleTestPairingMutation,
  RescheduleTestPairingMutationVariables
>;

/**
 * __useRescheduleTestPairingMutation__
 *
 * To run a mutation, you first call `useRescheduleTestPairingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleTestPairingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleTestPairingMutation, { data, loading, error }] = useRescheduleTestPairingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRescheduleTestPairingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RescheduleTestPairingMutation,
    RescheduleTestPairingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RescheduleTestPairingMutation,
    RescheduleTestPairingMutationVariables
  >(RescheduleTestPairingDocument, options);
}
export type RescheduleTestPairingMutationHookResult = ReturnType<
  typeof useRescheduleTestPairingMutation
>;
export type RescheduleTestPairingMutationResult =
  Apollo.MutationResult<RescheduleTestPairingMutation>;
export type RescheduleTestPairingMutationOptions = Apollo.BaseMutationOptions<
  RescheduleTestPairingMutation,
  RescheduleTestPairingMutationVariables
>;
export const CompleteTestPairingDocument = gql`
  mutation CompleteTestPairing(
    $id: ID!
    $runnerVisitFeedback: String!
    $runnerVisitNotes: String!
    $runnerWantsPairing: Boolean!
  ) {
    response: completeTestPairing(
      id: $id
      runnerVisitFeedback: $runnerVisitFeedback
      runnerVisitNotes: $runnerVisitNotes
      runnerWantsPairing: $runnerWantsPairing
    ) {
      testPairing {
        ...TestPairing
      }
    }
  }
  ${TestPairingFragmentDoc}
`;
export type CompleteTestPairingMutationFn = Apollo.MutationFunction<
  CompleteTestPairingMutation,
  CompleteTestPairingMutationVariables
>;

/**
 * __useCompleteTestPairingMutation__
 *
 * To run a mutation, you first call `useCompleteTestPairingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteTestPairingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeTestPairingMutation, { data, loading, error }] = useCompleteTestPairingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      runnerVisitFeedback: // value for 'runnerVisitFeedback'
 *      runnerVisitNotes: // value for 'runnerVisitNotes'
 *      runnerWantsPairing: // value for 'runnerWantsPairing'
 *   },
 * });
 */
export function useCompleteTestPairingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteTestPairingMutation,
    CompleteTestPairingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteTestPairingMutation,
    CompleteTestPairingMutationVariables
  >(CompleteTestPairingDocument, options);
}
export type CompleteTestPairingMutationHookResult = ReturnType<
  typeof useCompleteTestPairingMutation
>;
export type CompleteTestPairingMutationResult =
  Apollo.MutationResult<CompleteTestPairingMutation>;
export type CompleteTestPairingMutationOptions = Apollo.BaseMutationOptions<
  CompleteTestPairingMutation,
  CompleteTestPairingMutationVariables
>;
export const TestPairingPageDocument = gql`
  query TestPairingPage($id: ID!) {
    testPairing(id: $id) {
      id
      coach {
        ...CoachReferral
      }
      acceptedAt
      rejectedAt
      cancelledAt
      scheduledAt
      completedAt
      notesForRunner
      runnerVisitFeedback
      runnerVisitNotes
      runnerWantsPairing
      rejectionNotes
      cancellationNotes
    }
  }
  ${CoachReferralFragmentDoc}
`;

/**
 * __useTestPairingPageQuery__
 *
 * To run a query within a React component, call `useTestPairingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestPairingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestPairingPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTestPairingPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    TestPairingPageQuery,
    TestPairingPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TestPairingPageQuery, TestPairingPageQueryVariables>(
    TestPairingPageDocument,
    options
  );
}
export function useTestPairingPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TestPairingPageQuery,
    TestPairingPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TestPairingPageQuery,
    TestPairingPageQueryVariables
  >(TestPairingPageDocument, options);
}
export type TestPairingPageQueryHookResult = ReturnType<
  typeof useTestPairingPageQuery
>;
export type TestPairingPageLazyQueryHookResult = ReturnType<
  typeof useTestPairingPageLazyQuery
>;
export type TestPairingPageQueryResult = Apollo.QueryResult<
  TestPairingPageQuery,
  TestPairingPageQueryVariables
>;
export type CheerableItem =
  | NewsfeedAttendanceFragment
  | NewsfeedAwardFragment
  | NewsfeedCoachVisitFragment
  | NewsfeedRegistrationFragment
  | NewsfeedWorkoutFragment;

export type CommentableItem = (
  | ReportFragment
  | SessionFragment
  | NewsfeedAwardFragment
  | NewsfeedCoachVisitFragment
  | NewsfeedWorkoutFragment
  | NewsfeedAttendanceFragment
  | NewsfeedRegistrationFragment
) & {
  comments: CommentFragment[];
};
