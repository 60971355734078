/** @jsxImportSource @emotion/react */
import { useAuth } from "@goodgym/auth";
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";
import React from "react";
import * as C from "./components";

export type NavbarDesktopProps = {};

const NavbarDesktop: React.FC<NavbarDesktopProps> = () => {
  const { runner } = useAuth();
  return (
    <>
      <UI.AppBar position="fixed" color="white">
        <UI.Container>
          <UI.Toolbar disableGutters>
            <UI.Logo />
            <UI.Box flexGrow={1} />
            <C.Home />
            <C.Sessions />
            <C.Reports sx={{ display: { xs: "none", md: "inline-flex" } }} />
            {u.roles.hasRoleOrAdmin(runner, "TRAINER") && (
              <C.AaDashboard
                sx={{ display: { xs: "none", lg: "inline-flex" } }}
              />
            )}
            {u.roles.hasRoleOrAdmin(runner, "TASK_FORCE") && (
              <C.TfDashboard
                sx={{ display: { xs: "none", lg: "inline-flex" } }}
              />
            )}
            <C.Notifications />
            <C.More />
            <UI.Box sx={{ width: "55px", textAlign: "right" }}>
              <C.Account />
            </UI.Box>
          </UI.Toolbar>
        </UI.Container>
      </UI.AppBar>

      <UI.Toolbar />
    </>
  );
};

export default NavbarDesktop;
