/** @jsxImportSource @emotion/react */
import { useAuth } from "@goodgym/auth";
import * as UI from "@goodgym/components";
import { useReportPageQuery } from "@goodgym/graphql/hooks";
import * as Icons from "@goodgym/icons";
import * as u from "@goodgym/util";
import React from "react";
import { useParams } from "react-router-dom";
import UnpublishReportButton from "./components/UnpublishReportButton";
import ImageCarousel from "./components/ImageCarousel";

export type ReportPageProps = UI.BoxProps & { panel?: boolean; slug?: string };

const ReportPage: React.FC<ReportPageProps> = (props) => {
  const { slug } = useParams();
  const { error, data } = useReportPageQuery({
    variables: { slug: props.slug || slug! },
  });
  const { runner } = useAuth();
  const theme = UI.useTheme();
  const md = UI.useMediaQuery(theme.breakpoints.up("sm"));

  if (error) return <UI.Error />;
  if (!data) return <UI.Loading />;

  const { report } = data;

  const session = report.session;
  const numberOfAttendees = session.attendees.length;
  const upcomingSession = (session.area?.upcomingSessions || [])[0];


  //Need to make a query to grab more images associated with the report and add those images into this array
  // const reportImages = [{src: report.coverImageUrl}]

  const canEditReport =
    u.roles.hasAnyRole(runner, ["CENTRAL_ADMIN", "RUN_LEADER", "TRAINER"]) ||
    runner?.id === report.writtenBy?.id;

  return (
    <>
      <UI.Seo
        title={`${report.title} - GoodGym ${session.area?.name}`}
        description={report.description}
        url={u.links.report(report)}
        image={report.imageUrl}
      />

      <UI.Box
        sx={{
          pb: { xs: "120px", sm: 3 },
          minHeight: "100vh",
          overflowX: "hidden",
        }}
      >
        <UI.Box sx={{ backgroundColor: "neutral.light" }}>
          <UI.Container sx={{ py: 3 }}>
            <UI.Box
              sx={{
                flexDirection: "column",
                pt: 1,
                mt: { xs: 3, sm: 2, md: 0 },
              }}
            >
              <UI.Breadcrumbs
                crumbs={[
                  ["Home", u.links.home()],
                  ["Reports", u.links.reports()],
                  [report.title, u.links.report(report)],
                ]}
              />
              <UI.Typography variant="h4" sx={{ mt: 3 }}>
                {report.title}
              </UI.Typography>
              <UI.Typography
                variant="subtitle1"
                color="neutral"
                sx={{ color: "neutral.main", mt: 1, mb: 2 }}
              >
                {numberOfAttendees} {u.inflect("Goodgymer", numberOfAttendees)}{" "}
                helped{" "}
                {report.session.type === "mission"
                  ? "an isolated person"
                  : "their local community"}{" "}
                {report.session.area ? `in ${report.session.area?.name}` : ""}
              </UI.Typography>
              <UI.Box
                display="flex"
                flexWrap={"wrap"}
                rowGap="8px"
                sx={{ mb: 2 }}
              >
                {session.attendees.map((runner) => (
                  <UI.Box sx={{ mr: 1 }} key={runner.id}>
                    <UI.RunnerAvatar size="medium" runner={runner} noStatus />
                  </UI.Box>
                ))}
              </UI.Box>
              <UI.Box
                sx={{
                  display: { sm: "flex" },
                  justifyContent: { sm: "center" },
                  alignItems: { sm: "center" },
                }}
              >
                <ImageCarousel
                  sx={{ width: { sm: "70%" }, height: { sm: "auto" } }}
                  reportImages={report.pictures}
                />
              </UI.Box>
            </UI.Box>
            <UI.Box sx={{ backgroundColor: "white.main" }}></UI.Box>
          </UI.Container>
        </UI.Box>
        <UI.Box sx={{ backgroundColor: "white.main", pt: 3, mb: 4 }}>
          <UI.Container sx={{ backgroundColor: "white.main", width: "100hw" }}>
            <UI.Grid container columnSpacing={5} sx={{ pt: 2 }}>
              <UI.Grid item sm={12} md={7}>
                {!md && (
                  <>
                    <UI.AreaChip
                      area={report.session.area}
                      sx={{ mt: 2, mb: 1 }}
                    />
                    <UI.Box display="flex" sx={{ my: 2 }}>
                      <Icons.Event
                        sx={{
                          mr: 1,
                          verticalAlign: "middle",
                          fontSize: "22px",
                          color: "neutral.dark",
                        }}
                      ></Icons.Event>
                      <UI.Typography
                        variant="body1"
                        sx={{ color: "neutral.dark" }}
                      >
                        {u.time.formatDate(session.startedAt)}
                      </UI.Typography>
                    </UI.Box>

                    <UI.Box display="flex" sx={{ my: 2 }}>
                      <UI.Box>
                        <Icons.Assignment
                          sx={{
                            mr: 1,
                            verticalAlign: "middle",
                            fontSize: "22px",
                            color: "neutral.dark",
                          }}
                        />
                      </UI.Box>
                      {report.writtenBy && (
                        <UI.Box>
                          <UI.Typography
                            variant="body1"
                            sx={{ color: "neutral.dark" }}
                          >
                            Report written by{" "}
                            <UI.RunnerLink
                              runner={report.writtenBy}
                            ></UI.RunnerLink>
                          </UI.Typography>
                        </UI.Box>
                      )}
                    </UI.Box>

                    {canEditReport && (
                      <UI.Box>
                        <UI.Box display="flex" sx={{ my: 2 }}>
                          <Icons.Edit
                            sx={{
                              mr: 1,
                              verticalAlign: "middle",
                              fontSize: "22px",
                              color: "neutral.dark",
                            }}
                          />
                          <UI.Link
                            href={`/admin/happenings/${report.session.slug}/edit`}
                          >
                            <UI.Typography
                              variant="body1"
                              sx={{ color: "neutral.dark" }}
                            >
                              Edit session
                            </UI.Typography>
                          </UI.Link>
                        </UI.Box>

                        <UI.Box display="flex" sx={{ my: 2 }}>
                          {" "}
                          <Icons.NoteAlt
                            sx={{
                              mr: 1,
                              verticalAlign: "middle",
                              fontSize: "22px",
                              color: "neutral.dark",
                            }}
                          />
                          <UI.Link
                            href={`/admin/happenings/${report.session.slug}/reports/${report.slug}/edit`}
                          >
                            <UI.Typography
                              variant="body1"
                              sx={{ color: "neutral.dark" }}
                            >
                              Edit this report
                            </UI.Typography>
                          </UI.Link>
                        </UI.Box>

                        <UnpublishReportButton
                          report={report}
                          runner={runner}
                        />
                      </UI.Box>
                    )}
                    <UI.Box sx={{ mt: 2, mb: 4 }}>
                      <UI.Typography variant="h5" sx={{ mb: 2 }}>
                        Share the love
                      </UI.Typography>
                      <UI.SocialShareButtons
                        id={report.id}
                        type="Report"
                        title={`${report.title}`}
                        url={u.links.report(report)}
                        imageUrl={report.imageUrl}
                        cta="Have you seen this?"
                        sx={{ mx: 1 }}
                      />
                    </UI.Box>
                  </>
                )}
                <UI.Box
                  className="gg-markdown"
                  dangerouslySetInnerHTML={{ __html: report.description }}
                />
                {md && (
                  <>
                    <UI.Divider sx={{ my: 3 }} />

                    <UI.Box>
                      <UI.Typography variant="h5" sx={{ mt: 3, mb: 1 }}>
                        Discuss this report
                      </UI.Typography>
                      <UI.Comments commentable={report} />
                    </UI.Box>
                  </>
                )}
                <UI.Divider sx={{ my: 3 }} />
              </UI.Grid>
              <UI.Grid item sm={12} md={5} sx={{ px: 1 }}>
                {md && (
                  <>
                    <UI.AreaChip
                      area={report.session.area}
                      sx={{ mt: 2, mb: 1 }}
                    />
                    <UI.Box display="flex" sx={{ my: 2 }}>
                      <Icons.Event
                        sx={{
                          mr: 1,
                          verticalAlign: "middle",
                          fontSize: "22px",
                          color: "neutral.dark",
                        }}
                      ></Icons.Event>
                      <UI.Typography
                        variant="body1"
                        sx={{ color: "neutral.dark" }}
                      >
                        {u.time.formatDate(session.startedAt)}
                      </UI.Typography>
                    </UI.Box>

                    <UI.Box display="flex" sx={{ my: 2 }}>
                      <UI.Box>
                        <Icons.Assignment
                          sx={{
                            mr: 1,
                            verticalAlign: "middle",
                            fontSize: "22px",
                            color: "neutral.dark",
                          }}
                        />
                      </UI.Box>
                      {report.writtenBy && (
                        <UI.Box>
                          <UI.Typography
                            variant="body1"
                            sx={{ color: "neutral.dark" }}
                          >
                            Report written by{" "}
                            <UI.RunnerLink
                              runner={report.writtenBy}
                            ></UI.RunnerLink>
                          </UI.Typography>
                        </UI.Box>
                      )}
                    </UI.Box>

                    {canEditReport && (
                      <UI.Box>
                        <UI.Box display="flex" sx={{ my: 2 }}>
                          <Icons.Edit
                            sx={{
                              mr: 1,
                              verticalAlign: "middle",
                              fontSize: "22px",
                              color: "neutral.dark",
                            }}
                          />
                          <UI.Link
                            href={`/admin/happenings/${report.session.slug}/edit`}
                          >
                            <UI.Typography
                              variant="body1"
                              sx={{ color: "neutral.dark" }}
                            >
                              Edit session
                            </UI.Typography>
                          </UI.Link>
                        </UI.Box>

                        <UI.Box display="flex" sx={{ my: 2 }}>
                          {" "}
                          <Icons.NoteAlt
                            sx={{
                              mr: 1,
                              verticalAlign: "middle",
                              fontSize: "22px",
                              color: "neutral.dark",
                            }}
                          />
                          <UI.Link
                            href={`/admin/happenings/${report.session.slug}/reports/${report.slug}/edit`}
                          >
                            <UI.Typography
                              variant="body1"
                              sx={{ color: "neutral.dark" }}
                            >
                              Edit this report
                            </UI.Typography>
                          </UI.Link>
                        </UI.Box>

                        <UnpublishReportButton
                          report={report}
                          runner={runner}
                        />
                      </UI.Box>
                    )}
                    <UI.Box sx={{ mt: 6 }}>
                      <UI.Typography variant="h5" sx={{ mb: 2 }}>
                        Share the love
                      </UI.Typography>
                      <UI.SocialShareButtons
                        id={report.id}
                        type="Report"
                        title={`${report.title}`}
                        url={u.links.report(report)}
                        imageUrl={report.imageUrl}
                        cta="Have you seen this?"
                        sx={{ mx: 1 }}
                      />
                    </UI.Box>
                  </>
                )}

                {session.ledBy && (
                  <UI.Box sx={{ mt: 6 }}>
                    <UI.Typography variant="h5" sx={{ mb: 2 }}>
                      Session Leader
                    </UI.Typography>
                    <UI.Card
                      sx={{
                        p: 1,
                        borderRadius: "4px",
                        boxShadow:
                          "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      <UI.RunnerCard runner={session.ledBy} />
                    </UI.Card>
                  </UI.Box>
                )}
                {session.organisation && (
                  <UI.Box sx={{ mt: 6 }}>
                    <UI.Typography variant="h5">
                      This task supported
                    </UI.Typography>
                    <UI.OrganisationCard
                      organisation={session.organisation}
                      sx={{ mt: 2 }}
                    />
                  </UI.Box>
                )}
                <UI.Typography variant="h5" sx={{ mt: 6, mb: 2 }}>
                  Attendees
                </UI.Typography>
                <UI.Box
                  display="flex"
                  flexWrap={"wrap"}
                  rowGap="8px"
                  sx={{ mb: 1 }}
                >
                  {session.attendees.map((runner) => (
                    <UI.Box sx={{ mr: 1 }} key={runner.id}>
                      <UI.RunnerAvatar size="medium" runner={runner} noStatus />
                    </UI.Box>
                  ))}
                </UI.Box>
                {upcomingSession && (
                  <UI.Box sx={{ mt: 6, mb: 4 }}>
                    <UI.Typography variant="h5" sx={{ mb: 2 }}>
                      Join us on our next session
                    </UI.Typography>
                    <UI.SessionCard session={upcomingSession} />
                  </UI.Box>
                )}
              </UI.Grid>
            </UI.Grid>
            {!md && (
              <>
                <UI.Divider sx={{ my: 3 }} />

                <UI.Box sx={{ pb: 3 }}>
                  <UI.Typography variant="h5" sx={{ mt: 3, mb: 1 }}>
                    Discuss this report
                  </UI.Typography>
                  <UI.Comments commentable={report} />
                </UI.Box>
              </>
            )}
          </UI.Container>
        </UI.Box>
      </UI.Box>
    </>
  );
};

export default ReportPage;
