/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useLocation } from "react-router-dom";

export type SessionsProps = {};

const Sessions: React.FC<SessionsProps> = ({}) => {
  const { pathname } = useLocation();

  return (
    <UI.NavbarButton
      icon={Icons.Event}
      label="Sessions"
      active={pathname === "sessions"}
      href="/v3/sessions"
    />
  );
};

export default Sessions;
